.dxbl-text-edit.dxbl-memo-edit {
    --dxbl-memo-edit-scroll-color: #{$dx-gray-400};

    min-width: calc(var(--dxbl-text-edit-padding-x) * 2 + #{$dx-scroll-bar-width});
    position: relative;

    &.dxbl-memo-edit-has-buttons {
        display: flex;
        flex-direction: column;

        .dxbl-memo-edit-buttons-area {
            padding: var(--dxbl-text-edit-padding-y) var(--dxbl-text-edit-padding-y);
            display: flex;

            .dxbl-btn-group {
                &.dxbl-btn-group-right {
                    margin-left: auto;
                }

                &.dxbl-btn-group-left {
                    margin-right: auto;
                }

                & > .dxbl-btn {
                    border-radius: var(--dxbl-btn-border-radius);
                }
            }
        }
    }

    & > textarea {
        min-height: calc(var(--dxbl-text-edit-padding-y) * 2 + var(--dxbl-text-edit-line-height) * var(--dxbl-text-edit-font-size));
        scrollbar-width: thin;
        scrollbar-color: var(--dxbl-memo-edit-scroll-color) transparent;
    }

    & > .dxbl-btn.dxbl-edit-btn-clear + textarea {
        padding-right: $dx-text-edit-padding-x + $dx-image-size + $dx-scroll-bar-width;
    }

    & > .dxbl-btn.dxbl-edit-btn-clear {
        height: 100%;
        position: absolute;
        right: $dx-scroll-bar-width;
    }

    & > .dxbl-resize {
        resize: both;
    }

    & > .dxbl-v-resize {
        resize: vertical;
    }

    & > .dxbl-h-resize {
        resize: horizontal;
    }

    & > .dxbl-no-resize {
        resize: none;
    }
}
