@import "mixins/common";
@import "mixins/button";

.dxbl-btn-split {
    display: inline-flex;
    flex-wrap: nowrap;

    & > .dxbl-btn {
        text-decoration: none;
    }

    & > .dxbl-btn:not(:last-child):not(.dxbl-btn-last) {
        margin-right: -$dx-btn-border-width;
        border-bottom-right-radius: 0 !important; // stylelint-disable-line declaration-no-important
        border-top-right-radius: 0 !important; // stylelint-disable-line declaration-no-important
        border-right-color: transparent !important; // stylelint-disable-line declaration-no-important

        @include active-state() {
            border-right-color: transparent;
        }

        @include hover-state() {
            border-right-color: transparent;
        }
    }

    & > .dxbl-btn:not(:first-child):not(.dxbl-btn-first) {
        border-bottom-left-radius: 0 !important; // stylelint-disable-line declaration-no-important
        border-top-left-radius: 0 !important; // stylelint-disable-line declaration-no-important
    }

    & > .dxbl-btn-split-dropdown {
        padding-left: calc(var(--dxbl-btn-padding-x) / #{$dx-split-btn-padding-x-ratio}) !important; // stylelint-disable-line declaration-no-important
        padding-right: calc(var(--dxbl-btn-padding-x) / #{$dx-split-btn-padding-x-ratio}) !important; // stylelint-disable-line declaration-no-important
    }

    @include hover-state() {
        & > .dxbl-btn {
            border-color: var(--dxbl-btn-hover-border-color);

            @include hover-state() {
                background: var(--dxbl-btn-hover-background);
                background-color: var(--dxbl-btn-hover-bg);
                color: var(--dxbl-btn-hover-color);
            }
        }
    }
}
