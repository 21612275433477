.dxbl-grid[data-dx-focus-hidden] *:focus,
.dxbl-grid tr[data-dx-focus-hidden] > td:focus,
.dxbl-grid tbody[data-dx-focus-hidden] *:focus,
.dxbl-list-box[data-dx-focus-hidden] *:focus,
.dxbl-calendar[data-dx-focus-hidden] *:focus-within,
.dxbl-dropdown[data-dx-focus-hidden] *:focus-within,
.dxbl-modal[data-dx-focus-hidden] *:focus-within,
.dxbl-btn-group[data-dx-focus-hidden] *:focus,
.dxbl-btn-group-vertical[data-dx-focus-hidden] *:focus,
.dxbl-btn-dropdown[data-dx-focus-hidden] *:focus,
.dxbl-btn-split[data-dx-focus-hidden] *:focus,
[data-dx-focus-hidden] .dxbs-clear-btn:focus, [data-toggle="gridview-column-sort"]:focus,
.dxbl-scheduler[data-dx-focus-hidden] *:focus-visible {
    outline: 0 !important;
}

[data-dx-focus-hidden] .dxbs-clear-btn {
    background: none;
}
