@import "popup.shared";
@import "popup.flyout";
@import "popup.modal";
@import "popup.dropdown";
@import "popup.sidepanel";
@import "popup.window";

.dxbl-popup-portal {
    position: absolute;
}

// TODO: MOVE IT TO SEPARATE FILE!!!
.dxbl-dialog-captor {
    display: grid;
    align-items: center;
    justify-items: center;
    position: fixed;
    pointer-events: none;
    z-index: 1055;
}
