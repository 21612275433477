@import "mixins/common";

.dxbl-pivot-table {
    --dxbl-pivot-table-color: #{$dx-pivot-table-color};
    --dxbl-pivot-table-bg: #{$dx-pivot-table-bg};
    --dxbl-pivot-table-border-color: #{$dx-pivot-table-border-color};
    --dxbl-pivot-table-border-style: #{$dx-pivot-table-border-style};
    --dxbl-pivot-table-border-width: #{$dx-pivot-table-border-width};
    --dxbl-pivot-table-border-radius: #{$dx-pivot-table-border-radius};
    --dxbl-pivot-table-cell-padding-x: #{$dx-pivot-table-cell-padding-x};
    --dxbl-pivot-table-cell-padding-y: #{$dx-pivot-table-cell-padding-y};
    --dxbl-pivot-table-fixed-column-border-color: #{$dx-pivot-table-fixed-column-border-color};
    --dxbl-pivot-table-font-family: #{$dx-pivot-table-font-family};
    --dxbl-pivot-table-font-size: #{$dx-pivot-table-font-size};
    --dxbl-pivot-table-line-height: #{$dx-pivot-table-line-height};
    --dxbl-pivot-table-min-width: #{$dx-pivot-table-min-width};
    --dxbl-pivot-table-header-field-bg: #{$dx-pivot-table-header-field-bg};
    --dxbl-pivot-table-header-field-color: #{$dx-pivot-table-header-field-color};
    --dxbl-pivot-table-header-field-font-size: #{$dx-pivot-table-header-field-font-size};
    --dxbl-pivot-table-header-field-font-weight: #{$dx-pivot-table-header-field-font-weight};
    --dxbl-pivot-table-header-field-line-height: #{$dx-pivot-table-header-field-line-height};
    --dxbl-pivot-table-header-field-icon-min-width: #{$dx-pivot-table-header-field-icon-min-width};
    --dxbl-pivot-table-row-field-font-size: #{$dx-pivot-table-row-field-font-size};
    --dxbl-pivot-table-row-field-font-weight: #{$dx-pivot-table-row-field-font-weight};
    --dxbl-pivot-table-total-bg: #{$dx-pivot-table-total-bg};
    --dxbl-pivot-table-total-color: #{$dx-pivot-table-total-color};
    --dxbl-pivot-table-total-font-size: #{$dx-pivot-table-total-font-size};
    --dxbl-pivot-table-total-font-weight: #{$dx-pivot-table-total-font-weight};
    --dxbl-pivot-table-total-line-height: #{$dx-pivot-table-total-line-height};

    background-color: var(--dxbl-pivot-table-bg);
    border-radius: var(--dxbl-pivot-table-border-radius);
    border: var(--dxbl-pivot-table-border-width) var(--dxbl-pivot-table-border-style) var(--dxbl-pivot-table-border-color);
    color: var(--dxbl-pivot-table-color);
    display: flex;
    flex-direction: column;
    font-family: var(--dxbl-pivot-table-font-family);
    font-size: var(--dxbl-pivot-table-font-size);
    line-height: var(--dxbl-pivot-table-line-height);
    min-width: var(--dxbl-pivot-table-min-width);
    overflow: auto;
    text-align: left;
    width: 100%;
    height: $dx-pivot-table-default-height;
    overflow-y: hidden;

    &.dxbl-sm {
        --dxbl-pivot-table-border-radius: #{$dx-pivot-table-border-radius-sm};
        --dxbl-pivot-table-font-size: #{$dx-pivot-table-font-size-sm};
        --dxbl-pivot-table-line-height: #{$dx-pivot-table-line-height-sm};
        --dxbl-pivot-table-header-field-font-size: #{$dx-pivot-table-header-field-font-size-sm};
        --dxbl-pivot-table-header-field-line-height: #{$dx-pivot-table-header-field-line-height-sm};
        --dxbl-pivot-table-header-field-icon-min-width: #{$dx-pivot-table-header-field-icon-min-width-sm};
        --dxbl-pivot-table-row-field-font-size: #{$dx-pivot-table-row-field-font-size-sm};
        --dxbl-pivot-table-row-field-line-height: #{$dx-pivot-table-row-field-line-height-sm};
        --dxbl-pivot-table-total-font-size: #{$dx-pivot-table-total-font-size-sm};
        --dxbl-pivot-table-total-line-height: #{$dx-pivot-table-total-line-height-sm};
        --dxbl-pivot-table-cell-padding-x: #{$dx-pivot-table-cell-padding-x-sm};
        --dxbl-pivot-table-cell-padding-y: #{$dx-pivot-table-cell-padding-y-sm};
    }

    &.dxbl-lg {
        --dxbl-pivot-table-border-radius: #{$dx-pivot-table-border-radius-lg};
        --dxbl-pivot-table-font-size: #{$dx-pivot-table-font-size-lg};
        --dxbl-pivot-table-line-height: #{$dx-pivot-table-line-height-lg};
        --dxbl-pivot-table-header-field-font-size: #{$dx-pivot-table-header-field-font-size-lg};
        --dxbl-pivot-table-header-field-line-height: #{$dx-pivot-table-header-field-line-height-lg};
        --dxbl-pivot-table-header-field-icon-min-width: #{$dx-pivot-table-header-field-icon-min-width-lg};
        --dxbl-pivot-table-row-field-font-size: #{$dx-pivot-table-row-field-font-size-lg};
        --dxbl-pivot-table-row-field-line-height: #{$dx-pivot-table-row-field-line-height-lg};
        --dxbl-pivot-table-total-font-size: #{$dx-pivot-table-total-font-size-lg};
        --dxbl-pivot-table-total-line-height: #{$dx-pivot-table-total-line-height-lg};
        --dxbl-pivot-table-cell-padding-x: #{$dx-pivot-table-cell-padding-x-lg};
        --dxbl-pivot-table-cell-padding-y: #{$dx-pivot-table-cell-padding-y-lg};
    }

    & > .dxbl-scroll-viewer {
        background-color: var(--dxbl-pivot-table-bg);
        flex-grow: 1;

        > .dxbl-scroll-viewer-content {
            background-color: inherit;
            overflow-anchor: none;

            & > table {
                background-color: inherit;
                border-collapse: separate;
                border-color: var(--dxbl-pivot-table-border-color);
                border-style: var(--dxbl-pivot-table-border-style);
                border-width: 0;
                border-spacing: 0;
                height: fit-content;
                table-layout: fixed;
                width: 100%;

                & > thead {
                    top: 0;
                    position: sticky;
                    z-index: 2;
                    background-color: inherit;
                }

                & > thead,
                & > tbody {
                    border-color: inherit;
                    border-style: inherit;
                    border-width: 0;
                    background-color: inherit;

                    & > tr {
                        border-color: inherit;
                        border-style: inherit;
                        border-width: 0;
                        background-color: inherit;

                        & > th {
                            &.dxbl-pivot-table-header-cell,
                            &.dxbl-pivot-table-row-field-item {
                                position: sticky;
                                background-color: inherit;
                                z-index: 3;
                                left: 0;

                                .dxbl-btn {
                                    display: inline-block;
                                    color: inherit;

                                    & > span {
                                        vertical-align: middle;
                                    }
                                }
                            }

                            &.dxbl-pivot-table-header-cell {
                                & > .dxbl-pivot-table-area-column-inner-container {
                                    width: fit-content;
                                    position: sticky;
                                }

                                .dxbl-btn {
                                    background-color: var(--dxbl-pivot-table-header-field-bg);
                                    border-style: var(--dxbl-pivot-table-border-style);
                                    border-color: var(--dxbl-pivot-table-border-color);
                                    border-width: var(--dxbl-pivot-table-border-width);
                                    border-radius: 0;
                                    color: var(--dxbl-pivot-table-header-field-color);
                                    font-size: var(--dxbl-pivot-table-header-field-font-size);
                                    font-weight: var(--dxbl-pivot-table-header-field-font-weight);
                                    line-height: var(--dxbl-pivot-table-header-field-line-height);

                                    span:not(.dxbl-image) {
                                        text-decoration: none;
                                    }

                                    &:not(.dxbl-disabled):not(:disabled):hover {
                                        border-style: var(--dxbl-pivot-table-border-style);
                                        border-color: var(--dxbl-pivot-table-border-color);
                                        border-width: var(--dxbl-pivot-table-border-width);
                                        border-radius: 0;
                                    }

                                    &.dxbl-pivot-table-header-field-not-actionable {
                                        cursor: default;
                                        color: inherit;
                                        text-decoration: none;
                                    }

                                    &.dxbl-pivot-table-header-field > .dxbl-image {
                                        margin-left: 0;
                                    }
                                }
                            }

                            &.dxbl-pivot-table-row-field-item {
                                background-color: var(--dxbl-pivot-table-header-bg);
                                color: var(--dxbl-pivot-table-header-color);
                                font-size: var(--dxbl-pivot-table-row-field-font-size);
                                font-weight: var(--dxbl-pivot-table-row-field-font-weight);
                                line-height: var(--dxbl-pivot-table-row-field-line-height);

                                .dxbl-btn {
                                    height: 100%;
                                    pointer-events: auto;
                                    text-align: left;
                                    text-decoration: none;
                                    width: 100%;
                                }
                            }
                        }

                        & > td.dxbl-pivot-table-row-field-item {
                            position: sticky !important; // stylelint-disable-line declaration-no-important
                            background-color: var(--dxbl-pivot-table-bg) !important; // stylelint-disable-line declaration-no-important
                            z-index: 1 !important; // stylelint-disable-line declaration-no-important
                            left: 0;
                        }

                        & > td,
                        & > th {
                            font-family: var(--dxbl-pivot-table-font-family);
                            font-size: var(--dxbl-pivot-table-font-size);
                            line-height: var(--dxbl-pivot-table-line-height);
                            border-color: inherit;
                            border-style: inherit;
                            border-width: 0;
                            border-bottom-width: var(--dxbl-pivot-table-border-width);
                            border-right-width: var(--dxbl-pivot-table-border-width);
                            font-weight: inherit;
                            height: 100%;
                            padding: var(--dxbl-pivot-table-cell-padding-x) var(--dxbl-pivot-table-cell-padding-y);
                            position: relative;
                            vertical-align: top;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            &.dxbl-pivot-table-total,
                            &.dxbl-pivot-table-grand-total {
                                @include panel-header-style("--dxbl-pivot-table-total-bg", "--dxbl-pivot-table-total-color");

                                font-size: var(--dxbl-pivot-table-total-font-size);
                                font-weight: var(--dxbl-pivot-table-total-font-weight);
                                line-height: var(--dxbl-pivot-table-total-line-height);
                                z-index: 0;
                            }

                            &.dxbl-pivot-table-expandable-container {
                                padding: 0;
                            }
                        }
                    }

                    & > tr[dxbl-bottom-virtual-spacer-element],
                    & > tr[dxbl-top-virtual-spacer-element] {
                        height: auto;

                        > td {
                            overflow: visible;
                            vertical-align: top;
                            border-color: inherit;
                            border-style: inherit;
                            border-width: 0;
                            padding: 0;

                            > div {
                                position: sticky;
                                top: 0;
                                border-color: inherit;
                                border-style: inherit;
                                border-width: 0;

                                > div {
                                    border-color: inherit;
                                    border-style: inherit;
                                    border-width: 0;
                                    border-right-width: var(--dxbl-pivot-table-border-width);
                                    padding: var(--dxbl-pivot-table-cell-padding-x) var(--dxbl-pivot-table-cell-padding-y);

                                    &:last-child {
                                        border-bottom-width: var(--dxbl-pivot-table-border-width);
                                    }

                                    &:not(:first-child) {
                                        border-top-width: var(--dxbl-pivot-table-border-width);
                                    }
                                }
                            }

                            &:last-child > div[dxbl-skeleton-items-container] > .dxbl-skeleton-item {
                                border-right-width: 0;
                            }
                        }
                    }

                    & > tr[dxbl-top-virtual-spacer-element] + tr:not([dxbl-top-virtual-spacer-element]),
                    & > tr[dxbl-bottom-virtual-spacer-element] {
                        > td {
                            border-top-width: 0;
                        }
                    }
                }

                & > tbody {
                    & > tr {
                        &.dxbl-pivot-table-empty-row {
                            height: 100%;

                            & > td {
                                padding: 0;
                            }
                        }

                        &:has(+.dxbl-pivot-table-empty-row) > td,
                        &:has(+[dxbl-bottom-virtual-spacer-element]) > td {
                            border-bottom-width: 0;
                        }

                        & > td {
                            &.dxbl-pivot-table-cell:last-child {
                                border-right-width: 0;
                            }

                            &.dxbl-pivot-table-last-vertical-cell {
                                border-bottom-width: 0;
                            }
                        }
                    }
                }

                & > thead > tr > th {
                    &.dxbl-pivot-table-area-data {
                        border-width: 0;
                        z-index: 4;
                    }

                    &.dxbl-pivot-table-area-column {
                        padding-left: 0;
                    }

                    &.dxbl-pivot-table-area-column,
                    &.dxbl-pivot-table-last-horizontal-cell {
                        border-right-width: 0;
                    }
                }

                & > thead > tr {
                    &.dxbl-pivot-table-area-row {
                        & > th {
                            &.dxbl-pivot-table-area-row-field {
                                vertical-align: bottom;
                                border-right-width: 0;

                                & > .dxbl-pivot-table-header-field {
                                    display: flex;
                                    width: 100%;

                                    > span {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    > svg {
                                        min-width: var(--dxbl-pivot-table-header-field-icon-min-width);
                                    }
                                }

                                &:has(+.dxbl-pivot-table-area-column-field) {
                                    border-right-width: var(--dxbl-pivot-table-border-width);
                                }
                            }
                        }
                    }
                }

                &:not(.dxbl-pivot-table-no-scroll) > tbody > tr.dxbl-pivot-table-empty-row {
                    display: none;
                }
            }
        }
    }

    &.dxbl-virtual-items-container-pattern {
        &.dxbl-pivot-table-virtual-items-container-table-pattern > div {
            border-color: var(--dxbl-pivot-table-border-color);
            border-style: var(--dxbl-pivot-table-border-style);
            border-spacing: 0;
            padding: var(--dxbl-pivot-table-cell-padding-x) var(--dxbl-pivot-table-cell-padding-y);
            border-width: 0 var(--dxbl-pivot-table-border-width) var(--dxbl-pivot-table-border-width) 0;
        }
    }
}
