@import "chart.legend";

$tooltip-border: 1px solid #d1d1d1;

@keyframes dxbl-rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.dxbl-chart-root {
    --dxbl-chart-focus-outline-width: #{$dx-chart-focus-outline-width};
    --dxbl-chart-focus-outline-color: #{$dx-chart-focus-outline-color};
    --dxbl-chart-focus-outline-style: #{$dx-chart-focus-outline-style};
    --dxbl-chart-loading-indicator-transition: opacity 200ms 300ms;
    --dxbl-chart-axis-title-font-color: #{$dx-chart-axis-title-font-color};
    --dxbl-chart-axis-line-color: #{$dx-chart-axis-line-color};
    --dxbl-chart-constant-line-color: #{$dx-chart-constant-line-color};
    --dxbl-chart-point-hover-color: #{$dx-chart-point-hover-color};
    --dxbl-chart-error-bar-color: #{$dx-chart-error-bar-color};
    --dxbl-chart-annotation-color: #{$dx-chart-annotation-color};
    --dxbl-chart-annotation-text-color: #{$dx-chart-annotation-text-color};

    background-color: unset;
    border-width: 0;
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: center;

    &:focus-visible {
        outline: var(--dxbl-chart-focus-outline-width) var(--dxbl-chart-focus-outline-style) var(--dxbl-chart-focus-outline-color);
    }

    .dxbl-widget-container {
        overflow: hidden;
        flex: 1 1 auto;
        padding: 0 0.75rem;
        height: 100%;
        width: 100%;

        > svg {
            opacity: 1;
            transition: opacity 0ms 0ms;
        }

        &::after {
            opacity: 0;
            content: " ";
            display: block;
        }
    }

    &.dxbl-chart-with-legend-container {
        height: 100%;
        width: 100%;
    }

    &.dxbl-chart-first-loading {
        .dxbl-chart-legend,
        .dxbl-chart-title {
            visibility: hidden;
        }
    }
}

.dxbl-chart-tooltip {
    visibility: hidden;
    display: block;
    z-index: 1;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -100%);
    position: absolute;
    will-change: filter;
    filter: drop-shadow(0 2px 5px rgb(12 122 231 / 53%));

    > .dxbl-chart-tooltip-content-container {
        width: initial;
        height: initial;
        padding: 0;
        white-space: nowrap;
        border: $tooltip-border;
        border-radius: 3px;
        background-color: $dx-white;
        color: var(--bs-gray-900, #212529);

        + .dxbl-chart-tooltip-pointer::after {
            width: 0.6275rem;
            height: 0.6275rem;
            transform-origin: center;
            transform: translate(-50%, -50%) rotate(45deg);
            content: " ";
            background-color: inherit;
            position: absolute;
            border-bottom: $tooltip-border;
            border-right: $tooltip-border;
            border-top: 1px solid transparent;
            border-left: 1px solid transparent;
        }

        + .dxbl-chart-tooltip-pointer {
            top: calc(100% - 0.1px);
            position: absolute;
            background-color: $dx-white;
        }

        .dxbl-chart-tooltip-content {
            &.dxbl-chart-tooltip-user-template-not-defined {
                padding: 0.75rem;
            }
        }
    }
}

.dxbl-chart-title {
    font-size: 1.5em;
    width: 100%;
}

.dxbl-chart-subtitle {
    font-size: 0.8em;
    font-weight: 300;
}
