.dxbl-splitter {
    --dxbl-splitter-bg: #{$dx-splitter-bg};
    --dxbl-splitter-color: #{$dx-splitter-color};
    --dxbl-splitter-font-family: #{$dx-splitter-font-family};
    --dxbl-splitter-font-size: #{$dx-splitter-font-size};
    --dxbl-splitter-line-height: #{$dx-splitter-line-height};
    --dxbl-splitter-button-color: #{$dx-splitter-button-color};
    --dxbl-splitter-button-hover-color: #{$dx-splitter-button-hover-color};
    --dxbl-splitter-button-focused-color: #{$dx-splitter-button-focused-color};
    --dxbl-splitter-button-active-color: #{$dx-splitter-button-active-color};
    --dxbl-splitter-button-padding-cross: #{$dx-splitter-button-padding-cross};
    --dxbl-splitter-button-padding-main: #{$dx-splitter-button-padding-main};
    --dxbl-splitter-button-image-size: #{$dx-splitter-button-image-size};
    --dxbl-splitter-pane-padding-x: #{$dx-splitter-pane-padding-x};
    --dxbl-splitter-pane-padding-y: #{$dx-splitter-pane-padding-y};
    --dxbl-splitter-resize-handle-border-radius: #{$dx-splitter-resize-handle-border-radius};
    --dxbl-splitter-resize-handle-color: #{$dx-splitter-resize-handle-color};
    --dxbl-splitter-resize-handle-hover-color: #{$dx-splitter-resize-handle-hover-color};
    --dxbl-splitter-resize-handle-focused-color: #{$dx-splitter-resize-handle-focused-color};
    --dxbl-splitter-resize-handle-active-color: #{$dx-splitter-resize-handle-active-color};
    --dxbl-splitter-resize-handle-padding-cross: #{$dx-splitter-resize-handle-padding-cross};
    --dxbl-splitter-resize-handle-padding-main: #{$dx-splitter-resize-handle-padding-main};
    --dxbl-splitter-resize-handle-size-cross: #{$dx-splitter-resize-handle-size-cross};
    --dxbl-splitter-resize-handle-size-main: #{$dx-splitter-resize-handle-size-main};
    --dxbl-splitter-separator-bg: #{$dx-splitter-separator-bg};
    --dxbl-splitter-separator-hover-bg: #{$dx-splitter-separator-hover-bg};
    --dxbl-splitter-separator-focused-bg: #{$dx-splitter-separator-focused-bg};
    --dxbl-splitter-separator-active-bg: #{$dx-splitter-separator-active-bg};
    --dxbl-splitter-separator-border-radius: #{$dx-splitter-separator-border-radius};
    --dxbl-splitter-separator-hover-border-radius: #{$dx-splitter-separator-hover-border-radius};
    --dxbl-splitter-separator-focused-border-radius: #{$dx-splitter-separator-focused-border-radius};
    --dxbl-splitter-separator-active-border-radius: #{$dx-splitter-separator-active-border-radius};
    --dxbl-splitter-separator-gap: #{$dx-splitter-separator-gap};
    --dxbl-splitter-separator-size: #{$dx-splitter-separator-size};
    --dxbl-splitter-separator-static-size: #{$dx-splitter-separator-static-size};

    &.dxbl-sm {
        --dxbl-splitter-font-size: #{$dx-splitter-font-size-sm};
        --dxbl-splitter-line-height: #{$dx-splitter-line-height-sm};
        --dxbl-splitter-button-padding-cross: #{$dx-splitter-button-padding-cross-sm};
        --dxbl-splitter-button-padding-main: #{$dx-splitter-button-padding-main-sm};
        --dxbl-splitter-button-image-size: #{$dx-splitter-button-image-size-sm};
        --dxbl-splitter-pane-padding-x: #{$dx-splitter-pane-padding-x-sm};
        --dxbl-splitter-pane-padding-y: #{$dx-splitter-pane-padding-y-sm};
        --dxbl-splitter-resize-handle-size-cross: #{$dx-splitter-resize-handle-size-cross-sm};
        --dxbl-splitter-resize-handle-size-main: #{$dx-splitter-resize-handle-size-main-sm};
        --dxbl-splitter-separator-gap: #{$dx-splitter-separator-gap-sm};
        --dxbl-splitter-separator-size: #{$dx-splitter-separator-size-sm};
        --dxbl-splitter-separator-static-size: #{$dx-splitter-separator-static-size-sm};
    }

    &.dxbl-lg {
        --dxbl-splitter-font-size: #{$dx-splitter-font-size-lg};
        --dxbl-splitter-line-height: #{$dx-splitter-line-height-lg};
        --dxbl-splitter-button-padding-cross: #{$dx-splitter-button-padding-cross-lg};
        --dxbl-splitter-button-padding-main: #{$dx-splitter-button-padding-main-lg};
        --dxbl-splitter-button-image-size: #{$dx-splitter-button-image-size-lg};
        --dxbl-splitter-pane-padding-x: #{$dx-splitter-pane-padding-x-lg};
        --dxbl-splitter-pane-padding-y: #{$dx-splitter-pane-padding-y-lg};
        --dxbl-splitter-resize-handle-size-cross: #{$dx-splitter-resize-handle-size-cross-lg};
        --dxbl-splitter-resize-handle-size-main: #{$dx-splitter-resize-handle-size-main-lg};
        --dxbl-splitter-separator-gap: #{$dx-splitter-separator-gap-lg};
        --dxbl-splitter-separator-size: #{$dx-splitter-separator-size-lg};
        --dxbl-splitter-separator-static-size: #{$dx-splitter-separator-static-size-lg};
    }

    background-color: var(--dxbl-splitter-bg);
    color: var(--dxbl-splitter-color);
    display: flex;
    font-family: var(--dxbl-splitter-font-family);
    font-size: var(--dxbl-splitter-font-size);
    height: 100%;
    line-height: var(--dxbl-splitter-line-height);
    position: relative;
    width: 100%;

    &.dxbl-splitter-horizontal {
        flex-direction: row;
    }

    &.dxbl-splitter-vertical {
        flex-direction: column;
    }

    & > .dxbl-splitter-pane {
        flex: 1 0 0%;
        max-height: 100%;
        max-width: 100%;
        min-height: 0;
        min-width: 0;
        overflow: hidden;
        position: relative;

        &.dxbl-splitter-pane-collapsed {
            flex: 0 1 0;
            overflow: hidden;
            visibility: hidden;
        }

        & > .dxbl-splitter-pane-content {
            height: 100%;
            padding: var(--dxbl-splitter-pane-padding-y) var(--dxbl-splitter-pane-padding-x);
            width: 100%;

            &:has(> .dxbl-splitter) {
                padding: 0;
            }
        }
    }

    & > .dxbl-splitter-separator {
        align-items: center;
        background-color: var(--dxbl-splitter-separator-bg);
        border-radius: var(--dxbl-splitter-separator-border-radius);
        display: flex;
        flex: 0 0 auto;
        gap: var(--dxbl-splitter-separator-gap);
        justify-content: center;
        overflow: hidden;
        outline: none;

        &:not(.dxbl-splitter-separator-static) {
            &:hover {
                --dxbl-splitter-separator-bg: var(--dxbl-splitter-separator-hover-bg);
                --dxbl-splitter-separator-border-radius: var(--dxbl-splitter-separator-hover-border-radius);
            }

            &:focus {
                --dxbl-splitter-button-color: var(--dxbl-splitter-button-focused-color);
                --dxbl-splitter-resize-handle-color: var(--dxbl-splitter-resize-handle-focused-color);
                --dxbl-splitter-separator-bg: var(--dxbl-splitter-separator-focused-bg);
                --dxbl-splitter-separator-border-radius: var(--dxbl-splitter-separator-focused-border-radius);
            }

            &:active {
                --dxbl-splitter-button-color: var(--dxbl-splitter-button-active-color);
                --dxbl-splitter-resize-handle-color: var(--dxbl-splitter-resize-handle-active-color);
                --dxbl-splitter-separator-bg: var(--dxbl-splitter-separator-active-bg);
                --dxbl-splitter-separator-border-radius: var(--dxbl-splitter-separator-active-border-radius);
            }

            &:not(:focus):not(:active) {
                & > .dxbl-splitter-resize-handle {
                    &:hover {
                        --dxbl-splitter-resize-handle-color: var(--dxbl-splitter-resize-handle-hover-color);
                    }
                }

                & > .dxbl-splitter-button {
                    &:hover {
                        --dxbl-splitter-button-color: var(--dxbl-splitter-button-hover-color);
                    }
                }
            }
        }

        & > .dxbl-splitter-resize-handle {
            & > .dxbl-splitter-resize-handle-image {
                background-color: var(--dxbl-splitter-resize-handle-color);
                border-radius: var(--dxbl-splitter-resize-handle-border-radius);

                &::before {
                    content: "";
                }
            }
        }

        & > .dxbl-splitter-button {
            color: var(--dxbl-splitter-button-color);
            cursor: pointer;

            & > .dxbl-image {
                display: block;
                margin: 0;
                height: var(--dxbl-splitter-button-image-size);
                width: var(--dxbl-splitter-button-image-size);
            }
        }

        @at-root #{selector-append(".dxbl-splitter-horizontal", &)} {
            flex-direction: column;
            width: var(--dxbl-splitter-separator-size);

            &.dxbl-splitter-separator-static {
                width: var(--dxbl-splitter-separator-static-size);
            }

            &.dxbl-splitter-separator-draggable {
                cursor: col-resize;
            }

            & > .dxbl-splitter-resize-handle {
                padding: var(--dxbl-splitter-resize-handle-padding-main) var(--dxbl-splitter-resize-handle-padding-cross);

                & > .dxbl-splitter-resize-handle-image {
                    height: var(--dxbl-splitter-resize-handle-size-main);
                    width: var(--dxbl-splitter-resize-handle-size-cross);
                }
            }

            & > .dxbl-splitter-button {
                padding: var(--dxbl-splitter-button-padding-main) var(--dxbl-splitter-button-padding-cross);
            }
        }

        @at-root #{selector-append(".dxbl-splitter-vertical", &)} {
            flex-direction: row;
            height: var(--dxbl-splitter-separator-size);

            &.dxbl-splitter-separator-static {
                height: var(--dxbl-splitter-separator-static-size);
            }

            &.dxbl-splitter-separator-draggable {
                cursor: row-resize;
            }

            & > .dxbl-splitter-resize-handle {
                padding: var(--dxbl-splitter-resize-handle-padding-cross) var(--dxbl-splitter-resize-handle-padding-main);

                & > .dxbl-splitter-resize-handle-image {
                    height: var(--dxbl-splitter-resize-handle-size-cross);
                    width: var(--dxbl-splitter-resize-handle-size-main);
                }
            }

            & > .dxbl-splitter-button {
                padding: var(--dxbl-splitter-button-padding-cross) var(--dxbl-splitter-button-padding-main);
            }
        }
    }
}
