@use "sass:math";

@import "size-box";
@import "colors";

// Common
$dx-font-family: inherit !default;
$dx-border-style: solid !default;
$dx-border-width: 1px !default;
$dx-border-radius: 0.1875rem !default;
$dx-border-radius-sm: 0.1875rem !default;
$dx-border-radius-lg: 0.1875rem !default;
$dx-panel-border-radius: 0.375rem !default;
$dx-panel-border-radius-sm: 0.375rem !default;
$dx-panel-border-radius-lg: 0.375rem !default;

$dx-focus-shadow-size: 0.125rem !default;

$dx-loading-indicator-size-sm: 1.5rem !default;
$dx-loading-indicator-size: 2rem !default;
$dx-loading-indicator-size-lg: 2.5rem !default;

// Button
$dx-btn-font-family: $dx-font-family !default;
$dx-btn-font-size: $dx-font-size !default;
$dx-btn-font-size-sm: $dx-font-size-sm !default;
$dx-btn-font-size-lg: $dx-font-size-lg !default;
$dx-btn-line-height: $dx-line-height !default;
$dx-btn-line-height-sm: $dx-line-height-sm !default;
$dx-btn-line-height-lg: $dx-line-height-lg !default;

$dx-btn-bg: transparent !default;
$dx-btn-color: $dx-color !default;
$dx-btn-border-color: transparent !default;
$dx-btn-border-style: $dx-border-style !default;
$dx-btn-border-width: $dx-border-width !default;
$dx-btn-border-radius: $dx-border-radius !default;
$dx-btn-border-radius-sm: $dx-border-radius-sm !default;
$dx-btn-border-radius-lg: $dx-border-radius-lg !default;
$dx-btn-box-shadow: none !default;
$dx-btn-focus-shadow-size: $dx-focus-shadow-size !default;

$dx-btn-size-box-correction: 0rem !default; // stylelint-disable-line length-zero-no-unit
$dx-btn-padding-x: $dx-size-box-padding-x - $dx-btn-size-box-correction !default;
$dx-btn-padding-y: $dx-size-box-padding-y - $dx-btn-size-box-correction !default;
$dx-btn-padding-x-sm: $dx-size-box-padding-x-sm - $dx-btn-size-box-correction !default;
$dx-btn-padding-y-sm: $dx-size-box-padding-y-sm - $dx-btn-size-box-correction !default;
$dx-btn-padding-x-lg: $dx-size-box-padding-x-lg - $dx-btn-size-box-correction !default;
$dx-btn-padding-y-lg: $dx-size-box-padding-y-lg - $dx-btn-size-box-correction !default;
$dx-btn-image-spacing: $dx-image-spacing !default;
$dx-btn-image-spacing-sm: $dx-image-spacing-sm !default;
$dx-btn-image-spacing-lg: $dx-image-spacing-lg !default;

$dx-btn-active-bg-shade-amount: 15% !default;
$dx-btn-active-bg-tint-amount: 15% !default;
$dx-btn-hover-bg-shade-amount: 10% !default;
$dx-btn-hover-bg-tint-amount: 10% !default;
$dx-btn-disabled-bg-shade-amount: 60% !default;
$dx-btn-disabled-bg-tint-amount: 40% !default;
$dx-btn-focus-shadow-shade-amount: 50% !default;
$dx-btn-focus-shadow-tint-amount: 50% !default;

$dx-btn-container-padding-x: 0.5rem !default;
$dx-btn-container-padding-y: 0.5rem !default;
$dx-btn-container-padding-x-sm: 0.375rem !default;
$dx-btn-container-padding-y-sm: 0.375rem !default;
$dx-btn-container-padding-x-lg: 0.625rem !default;
$dx-btn-container-padding-y-lg: 0.625rem !default;

$dx-btn-toolbar-spacing: 0.5rem !default;
$dx-btn-toolbar-spacing-sm: 0.5rem !default;
$dx-btn-toolbar-spacing-lg: 0.5rem !default;
$dx-btn-toolbar-item-spacing: 0.25rem !default;
$dx-btn-toolbar-item-spacing-sm: 0.25rem !default;
$dx-btn-toolbar-item-spacing-lg: 0.25rem !default;
$dx-btn-toolbar-focus-outline-color: $dx-focus !default;
$dx-btn-toolbar-focus-outline-width: $dx-focus-outline-width !default;
$dx-btn-toolbar-focus-outline-offset: 1px !default;

$dx-btn-dropdown-min-width: $dx-font-size * $dx-dropdown-min-width-ratio !default;
$dx-btn-dropdown-min-width-sm: $dx-font-size-sm * $dx-dropdown-min-width-ratio !default;
$dx-btn-dropdown-min-width-lg: $dx-font-size-lg * $dx-dropdown-min-width-ratio !default;
$dx-btn-dropdown-padding-x: 0 !default;
$dx-btn-dropdown-padding-y: 0.25rem !default;
$dx-btn-dropdown-padding-x-sm: 0 !default;
$dx-btn-dropdown-padding-y-sm: 0.125rem !default;
$dx-btn-dropdown-padding-x-lg: 0 !default;
$dx-btn-dropdown-padding-y-lg: 0.375rem !default;
$dx-btn-dropdown-modal-padding-x: $dx-panel-content-padding-x !default;
$dx-btn-dropdown-modal-padding-y: 0.375rem !default;
$dx-btn-dropdown-btn-bg: transparent !default;
$dx-btn-dropdown-btn-color: $dx-color !default;
$dx-btn-dropdown-btn-border-color: transparent !default;
$dx-btn-dropdown-btn-border-style: $dx-border-style !default;
$dx-btn-dropdown-btn-border-width: $dx-border-width !default;
$dx-btn-dropdown-btn-hover-bg: unset !default;
$dx-btn-dropdown-btn-hover-color: inherit !default;
$dx-btn-dropdown-btn-active-bg: unset !default;
$dx-btn-dropdown-btn-active-color: inherit !default;
$dx-btn-dropdown-btn-active-hover-bg: unset !default;
$dx-btn-dropdown-btn-active-hover-color: inherit !default;
$dx-btn-dropdown-btn-disabled-bg: unset !default;
$dx-btn-dropdown-btn-disabled-color: inherit !default;
$dx-btn-dropdown-btn-padding-x: $dx-btn-padding-x !default;
$dx-btn-dropdown-btn-padding-y: $dx-btn-padding-y !default;
$dx-btn-dropdown-btn-padding-x-sm: $dx-btn-padding-x-sm !default;
$dx-btn-dropdown-btn-padding-y-sm: $dx-btn-padding-y-sm !default;
$dx-btn-dropdown-btn-padding-x-lg: $dx-btn-padding-x-lg !default;
$dx-btn-dropdown-btn-padding-y-lg: $dx-btn-padding-y-lg !default;
$dx-btn-dropdown-btn-focus-outline-color: $dx-focus !default;
$dx-btn-dropdown-btn-focus-outline-width: $dx-focus-outline-width !default;

// Checkbox
$dx-checkbox-font-family: $dx-font-family !default;
$dx-checkbox-font-size: $dx-font-size !default;
$dx-checkbox-font-size-sm: $dx-font-size-sm !default;
$dx-checkbox-font-size-lg: $dx-font-size-lg !default;
$dx-checkbox-line-height: $dx-line-height !default;
$dx-checkbox-line-height-sm: $dx-line-height-sm !default;
$dx-checkbox-line-height-lg: $dx-line-height-lg !default;

$dx-checkbox-border-color: transparent !default;
$dx-checkbox-border-style: $dx-border-style !default;
$dx-checkbox-border-width: $dx-border-width !default;

$dx-checkbox-focus-shadow-size: $dx-focus-shadow-size !default;
$dx-checkbox-focus-shadow-shade-amount: 50% !default;
$dx-checkbox-focus-shadow-tint-amount: 50% !default;
$dx-checkbox-focus-on-primary-shadow-shade-amount: 50% !default;
$dx-checkbox-focus-on-primary-shadow-tint-amount: 50% !default;
$dx-checkbox-hover-bg-tint-amount: 20% !default;
$dx-checkbox-hover-bg-shade-amount: 20% !default;
$dx-checkbox-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-checkbox-spacing: $dx-image-spacing + $dx-checkbox-focus-shadow-size !default;
$dx-checkbox-spacing-sm: $dx-image-spacing-sm + $dx-checkbox-focus-shadow-size !default;
$dx-checkbox-spacing-lg: $dx-image-spacing-lg + $dx-checkbox-focus-shadow-size !default;

$dx-checkbox-base-color: #000 !default;
$dx-checkbox-alpha: 50% !default;
$dx-checkbox-hover-alpha: 70% !default;
$dx-checkbox-disabled-alpha: 70% !default;

// TODO: remove after releasing new palette without opacity
$dx-checkbox-checked-readonly-alpha: 42% !default;

// TODO: remove after releasing new palette without opacity
$dx-checkbox-switch-checked-readonly-alpha: 60% !default;

// TODO: remove after releasing new palette without opacity
$dx-checkbox-unchecked-readonly-alpha: 30% !default;

// TODO: remove after releasing new palette without opacity
$dx-checkbox-unchecked-validation-readonly-alpha: 48% !default;
$dx-checkbox-validation-alpha: 80% !default;
$dx-checkbox-validation-hover-alpha: 100% !default;

$dx-checkbox-check-element-readonly-bg: $dx-bg !default;
$dx-checkbox-check-element-readonly-border-color: unset !default;
$dx-checkbox-check-element-readonly-border-width: 2px !default;

$dx-checkbox-check-element-border-radius: 0.1875rem !default;
$dx-checkbox-check-element-unchecked-border-color: unset !default;
$dx-checkbox-check-element-unchecked-hover-border-color: unset !default;
$dx-checkbox-check-element-unchecked-border-width: 2px !default;
$dx-checkbox-check-element-unchecked-bg: $dx-bg !default;
$dx-checkbox-check-element-unchecked-hover-bg: $dx-bg !default;
$dx-checkbox-check-element-size: 1.25rem !default;
$dx-checkbox-check-element-size-sm: 1rem !default;
$dx-checkbox-check-element-size-lg: 1.5rem !default;
$dx-checkbox-check-element-margin-y: math.div($dx-borderless-size - $dx-checkbox-check-element-size, 2) !default;
$dx-checkbox-check-element-margin-y-sm: math.div($dx-borderless-size-sm - $dx-checkbox-check-element-size-sm, 2) !default;
$dx-checkbox-check-element-margin-y-lg: math.div($dx-borderless-size-lg - $dx-checkbox-check-element-size-lg, 2) !default;
$dx-checkbox-check-element-checked-color: $dx-white !default;
$dx-checkbox-check-element-checked-bg: $dx-primary !default;
$dx-checkbox-check-element-disabled-color: $dx-bg !default;
$dx-checkbox-check-element-disabled-bg: unset !default;

$dx-checkbox-indeterminate-element-height: 0.125rem !default;
$dx-checkbox-indeterminate-element-width: 0.625rem !default;
$dx-checkbox-indeterminate-element-width-sm: 0.5rem !default;
$dx-checkbox-indeterminate-element-width-lg: 0.875rem !default;
$dx-checkbox-indeterminate-element-bg: $dx-white !default;
$dx-checkbox-indeterminate-element-disabled-bg: $dx-bg !default;

$dx-checkbox-checked-image-width: 0.875rem !default;
$dx-checkbox-checked-image-height: 0.875rem !default;
$dx-checkbox-checked-image-width-sm: 0.75rem !default;
$dx-checkbox-checked-image-height-sm: 0.75rem !default;
$dx-checkbox-checked-image-width-lg: 1rem !default;
$dx-checkbox-checked-image-height-lg: 1rem !default;

$dx-checkbox-switch-checked-bg: $dx-primary !default;
$dx-checkbox-switch-unchecked-bg: unset !default;
$dx-checkbox-switch-unchecked-hover-bg: unset !default;
$dx-checkbox-switch-border-radius: 1.875rem !default;
$dx-checkbox-switch-width: 2.125rem !default;
$dx-checkbox-switch-height: 1rem !default;
$dx-checkbox-switch-width-sm: 1.75rem !default;
$dx-checkbox-switch-height-sm: 0.75rem !default;
$dx-checkbox-switch-width-lg: 2.5rem !default;
$dx-checkbox-switch-height-lg: 1.125rem !default;
$dx-checkbox-switch-element-size: 0.75rem !default;
$dx-checkbox-switch-element-size-sm: 0.5rem !default;
$dx-checkbox-switch-element-size-lg: 0.875rem !default;
$dx-checkbox-switch-element-bg: $dx-white !default;
$dx-checkbox-switch-element-unchecked-bg: $dx-bg !default;
$dx-checkbox-switch-element-disabled-bg: $dx-bg !default;
$dx-checkbox-switch-element-margin: 0.125rem !default;
$dx-checkbox-switch-margin-y: math.div($dx-borderless-size - $dx-checkbox-switch-height, 2) !default;
$dx-checkbox-switch-margin-y-sm: math.div($dx-borderless-size-sm - $dx-checkbox-switch-height-sm, 2) !default;
$dx-checkbox-switch-margin-y-lg: math.div($dx-borderless-size-lg - $dx-checkbox-switch-height-lg, 2) !default;

$dx-checkbox-radio-bg: $dx-bg !default;
$dx-checkbox-radio-border-width: 2px !default;
$dx-checkbox-radio-checked-border-color: $dx-primary !default;
$dx-checkbox-radio-unchecked-border-color: unset !default;
$dx-checkbox-radio-unchecked-hover-border-color: unset !default;
$dx-checkbox-radio-unchecked-check-bg: $dx-bg !default;
$dx-checkbox-radio-size: 1.25rem !default;
$dx-checkbox-radio-size-sm: 1rem !default;
$dx-checkbox-radio-size-lg: 1.5rem !default;
$dx-checkbox-radio-check-bg: $dx-primary !default;
$dx-checkbox-radio-check-size: 0.625rem !default;
$dx-checkbox-radio-check-size-sm: 0.5rem !default;
$dx-checkbox-radio-check-size-lg: 0.75rem !default;
$dx-checkbox-radio-check-readonly-bg: $dx-bg !default;

// TODO: remove after releasing new palette without opacity
$dx-checkbox-radio-readonly-alpha: 60% !default;

// TODO: remove after releasing new palette without opacity
$dx-checkbox-radio-unchecked-validation-readonly-alpha: 48% !default;

// RadioGroup
$dx-radio-group-radio-padding-top-sm: 0.25rem !default;
$dx-radio-group-radio-padding-top: 0.375rem !default;
$dx-radio-group-radio-padding-top-lg: 0.5rem !default;
$dx-radio-group-radio-padding-left-sm: 0.75rem !default;
$dx-radio-group-radio-padding-left: 1rem !default;
$dx-radio-group-radio-padding-left-lg: 1.25rem !default;
$dx-radio-group-font-size: $dx-font-size !default;
$dx-radio-group-font-size-sm: $dx-font-size-sm !default;
$dx-radio-group-font-size-lg: $dx-font-size-lg !default;
$dx-radio-group-no-data-text-opacity: 0.75 !default;

// TextEdit
$dx-text-edit-font-family: $dx-font-family !default;
$dx-text-edit-font-size: $dx-font-size !default;
$dx-text-edit-font-size-sm: $dx-font-size-sm !default;
$dx-text-edit-font-size-lg: $dx-font-size-lg !default;
$dx-text-edit-line-height: $dx-line-height !default;
$dx-text-edit-line-height-sm: $dx-line-height-sm !default;
$dx-text-edit-line-height-lg: $dx-line-height-lg !default;

$dx-text-edit-bg: $dx-bg !default;
$dx-text-edit-color: $dx-color !default;
$dx-text-edit-border-color: $dx-border-color !default;
$dx-text-edit-border-style: $dx-border-style !default;
$dx-text-edit-border-width: $dx-border-width !default;
$dx-text-edit-border-radius: $dx-border-radius !default;
$dx-text-edit-border-radius-sm: $dx-border-radius-sm !default;
$dx-text-edit-border-radius-lg: $dx-border-radius-lg !default;
$dx-text-edit-box-shadow: inset 0 0 0 rgb(0 0 0 / 0%) !default;
$dx-text-edit-state-base-color: #000 !default;
$dx-text-edit-disabled-bg: unset !default;
$dx-text-edit-disabled-color: $dx-color !default;
$dx-text-edit-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-text-edit-focus-shadow-size: $dx-focus-shadow-size !default;

$dx-text-edit-btn-bg: $dx-bg !default;
$dx-text-edit-btn-color: $dx-color !default;
$dx-text-edit-btn-hover-bg: unset !default;
$dx-text-edit-btn-hover-color: $dx-color !default;
$dx-text-edit-btn-disabled-bg: unset !default;
$dx-text-edit-btn-disabled-color: $dx-color !default;
$dx-text-edit-btn-padding-x: #{$dx-btn-padding-x};
$dx-text-edit-btn-padding-y: #{$dx-btn-padding-y};
$dx-text-edit-btn-padding-x-sm: #{$dx-btn-padding-x-sm};
$dx-text-edit-btn-padding-y-sm: #{$dx-btn-padding-y-sm};
$dx-text-edit-btn-padding-x-lg: #{$dx-btn-padding-x-lg};
$dx-text-edit-btn-padding-y-lg: #{$dx-btn-padding-y-lg};

$dx-text-edit-clear-btn-bg: unset !default;
$dx-text-edit-clear-btn-color: unset !default;
$dx-text-edit-clear-btn-hover-bg: unset !default;
$dx-text-edit-clear-btn-hover-color: unset !default;

$dx-text-edit-padding-x: $dx-size-box-padding-x !default;
$dx-text-edit-padding-y: $dx-size-box-padding-y !default;
$dx-text-edit-padding-x-sm: $dx-size-box-padding-x-sm !default;
$dx-text-edit-padding-y-sm: $dx-size-box-padding-y-sm !default;
$dx-text-edit-padding-x-lg: $dx-size-box-padding-x-lg !default;
$dx-text-edit-padding-y-lg: $dx-size-box-padding-y-lg !default;

$dx-text-edit-focus-shadow-shade-amount: 25% !default;
$dx-text-edit-focus-shadow-tint-amount: 25% !default;
$dx-text-edit-focus-border-shade-amount: 50% !default;
$dx-text-edit-focus-border-tint-amount: 50% !default;

$dx-text-edit-validation-status-padding-x: $dx-btn-padding-x !default;
$dx-text-edit-validation-status-padding-y: $dx-btn-padding-y !default;
$dx-text-edit-validation-status-padding-x-sm: $dx-btn-padding-x-sm !default;
$dx-text-edit-validation-status-padding-y-sm: $dx-btn-padding-y-sm !default;
$dx-text-edit-validation-status-padding-x-lg: $dx-btn-padding-x-lg !default;
$dx-text-edit-validation-status-padding-y-lg: $dx-btn-padding-y-lg !default;

// TagBox
$dx-tag-box-tag-base-color: #000 !default;
$dx-tag-box-tag-bg-alpha: 10% !default;
$dx-tag-box-tag-disabled-bg-alpha: 5% !default;

$dx-tag-box-tag-bg: unset !default;
$dx-tag-box-tag-color: inherit !default;
$dx-tag-box-tag-readonly-bg: unset !default;
$dx-tag-box-tag-readonly-color: inherit !default;
$dx-tag-box-tag-disabled-bg: unset !default;
$dx-tag-box-tag-disabled-color: inherit !default;
$dx-tag-box-tag-border-color: transparent !default;
$dx-tag-box-tag-border-style: solid !default;
$dx-tag-box-tag-border-width: 0rem !default; // stylelint-disable-line length-zero-no-unit
$dx-tag-box-tag-border-radius: $dx-round-border-radius !default;

$dx-tag-box-input-min-width: 4em !default;

$dx-tag-box-tag-spacing-x: 0.25rem !default;
$dx-tag-box-tag-spacing-y: 0.125rem !default;
$dx-tag-box-tag-spacing-x-sm: 0.1875rem !default;
$dx-tag-box-tag-spacing-y-sm: 0.0625rem !default;
$dx-tag-box-tag-spacing-x-lg: 0.375rem !default;
$dx-tag-box-tag-spacing-y-lg: 0.1875rem !default;

$dx-tag-box-tag-padding-x: 0.25rem !default;
$dx-tag-box-tag-padding-y: $dx-size-box-padding-y - $dx-tag-box-tag-spacing-y - $dx-tag-box-tag-border-width !default;
$dx-tag-box-tag-padding-x-sm: 0.25rem !default;
$dx-tag-box-tag-padding-y-sm: $dx-size-box-padding-y-sm - $dx-tag-box-tag-spacing-y-sm - $dx-tag-box-tag-border-width !default;
$dx-tag-box-tag-padding-x-lg: 0.375rem !default;
$dx-tag-box-tag-padding-y-lg: $dx-size-box-padding-y-lg - $dx-tag-box-tag-spacing-y-lg - $dx-tag-box-tag-border-width !default;
$dx-tag-box-tag-text-padding-left: 0.375rem !default;
$dx-tag-box-tag-text-padding-left-sm: 0.25rem !default;
$dx-tag-box-tag-text-padding-left-lg: 0.5rem !default;
$dx-tag-box-tag-text-padding-right: 0.75rem !default;
$dx-tag-box-tag-text-padding-right-sm: 0.5rem !default;
$dx-tag-box-tag-text-padding-right-lg: 1rem !default;

$dx-tag-box-tag-close-btn-bg: unset !default;
$dx-tag-box-tag-close-btn-color: white !default;
$dx-tag-box-tag-close-btn-hover-bg: unset !default;
$dx-tag-box-tag-close-btn-hover-color: white !default;

// Calendar
$dx-calendar-font-family: $dx-font-family !default;
$dx-calendar-font-size: $dx-font-size !default;
$dx-calendar-font-size-sm: $dx-font-size-sm !default;
$dx-calendar-font-size-lg: $dx-font-size-lg !default;
$dx-calendar-line-height: $dx-line-height !default;
$dx-calendar-line-height-sm: $dx-line-height-sm !default;
$dx-calendar-line-height-lg: $dx-line-height-lg !default;

$dx-calendar-bg: $dx-bg !default;
$dx-calendar-color: $dx-color !default;
$dx-calendar-border-color: $dx-border-color !default;
$dx-calendar-border-style: $dx-border-style !default;
$dx-calendar-border-width: $dx-border-width !default;
$dx-calendar-border-radius: $dx-border-radius !default;
$dx-calendar-border-radius-sm: $dx-border-radius-sm !default;
$dx-calendar-border-radius-lg: $dx-border-radius-lg !default;
$dx-calendar-disabled-bg: unset !default;
$dx-calendar-disabled-color: $dx-color !default;
$dx-calendar-disabled-opacity: $dx-state-disabled-opacity !default;

$dx-calendar-header-bg: unset !default;
$dx-calendar-header-color: inherit !default;
$dx-calendar-header-btn-color: unset !default;
$dx-calendar-header-btn-hover-color: unset !default;
$dx-calendar-header-btn-disabled-color: unset !default;
$dx-calendar-header-title-font-weight: 600 !default;
$dx-calendar-multi-view-month-view-header-title-button-padding: $dx-btn-padding-x + 2 * $dx-btn-padding-x + $dx-image-size !default;
$dx-calendar-multi-view-month-view-header-title-button-padding-sm: $dx-btn-padding-x-sm + 2 * $dx-btn-padding-x-sm + $dx-image-size-sm !default;
$dx-calendar-multi-view-month-view-header-title-button-padding-lg: $dx-btn-padding-x-lg + 2 * $dx-btn-padding-x-lg + $dx-image-size-lg !default;
$dx-calendar-multi-view-header-title-button-padding: $dx-calendar-multi-view-month-view-header-title-button-padding + 2 * $dx-btn-padding-x + $dx-image-size !default;
$dx-calendar-multi-view-header-title-button-padding-sm: $dx-calendar-multi-view-month-view-header-title-button-padding-sm + 2 * $dx-btn-padding-x-sm + $dx-image-size-sm !default;
$dx-calendar-multi-view-header-title-button-padding-lg: $dx-calendar-multi-view-month-view-header-title-button-padding-lg + 2 * $dx-btn-padding-x-lg + $dx-image-size-lg !default;
$dx-calendar-header-padding-x: 0.25rem !default;
$dx-calendar-header-padding-y: 0.25rem !default;
$dx-calendar-header-padding-x-sm: 0.125rem !default;
$dx-calendar-header-padding-y-sm: 0.125rem !default;
$dx-calendar-header-padding-x-lg: 0.375rem !default;
$dx-calendar-header-padding-y-lg: 0.375rem !default;

$dx-calendar-content-padding-x: 1rem !default;
$dx-calendar-content-padding-y: 1rem !default;
$dx-calendar-content-padding-x-sm: 0.75rem !default;
$dx-calendar-content-padding-y-sm: 0.75rem !default;
$dx-calendar-content-padding-x-lg: 1.25rem !default;
$dx-calendar-content-padding-y-lg: 1.25rem !default;
$dx-calendar-content-cell-padding-y: $dx-size-box-padding-y !default;
$dx-calendar-content-cell-padding-x: $dx-size-box-padding-x !default;
$dx-calendar-content-cell-padding-y-sm: $dx-size-box-padding-y-sm !default;
$dx-calendar-content-cell-padding-x-sm: $dx-size-box-padding-x-sm !default;
$dx-calendar-content-cell-padding-y-lg: $dx-size-box-padding-y-lg !default;
$dx-calendar-content-cell-padding-x-lg: $dx-size-box-padding-x-lg !default;
$dx-calendar-content-cell-min-width: $dx-size !default;
$dx-calendar-content-cell-min-width-sm: $dx-size-sm !default;
$dx-calendar-content-cell-min-width-lg: $dx-size-lg !default;

$dx-calendar-footer-bg: unset !default;
$dx-calendar-footer-color: inherit !default;
$dx-calendar-footer-btn-spacing: 0.5rem !default;
$dx-calendar-footer-btn-spacing-sm: 0.375rem !default;
$dx-calendar-footer-btn-spacing-lg: 0.625rem !default;
$dx-calendar-footer-btn-bg: $dx-gray-300 !default;
$dx-calendar-footer-btn-hover-color: $dx-gray-400 !default;
$dx-calendar-footer-btn-hover-border-color: transparent !default;
$dx-calendar-footer-btn-outline-offset: 0.0625rem !default;
$dx-calendar-footer-btn-button-color: transparent !default;
$dx-calendar-footer-padding-x: $dx-btn-container-padding-x !default;
$dx-calendar-footer-padding-y: $dx-btn-container-padding-y !default;
$dx-calendar-footer-padding-x-sm: $dx-btn-container-padding-x-sm !default;
$dx-calendar-footer-padding-y-sm: $dx-btn-container-padding-y-sm !default;
$dx-calendar-footer-padding-x-lg: $dx-btn-container-padding-x-lg !default;
$dx-calendar-footer-padding-y-lg: $dx-btn-container-padding-y-lg !default;

$dx-calendar-item-selected-bg: unset !default;
$dx-calendar-item-selected-base-bg: $dx-primary !default;
$dx-calendar-item-selected-range-base-bg: $dx-primary !default;
$dx-calendar-item-selected-range-hover-base-bg: hsl(from #{$dx-calendar-item-selected-range-base-bg} h s calc(l - 10)) !default;
$dx-calendar-item-selected-color: inherit !default;
$dx-calendar-item-hover-bg: unset !default;
$dx-calendar-item-hover-color: inherit !default;
$dx-calendar-item-selected-hover-bg: unset !default;
$dx-calendar-item-selected-hover-color: inherit !default;
$dx-calendar-item-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-calendar-item-disabled-cursor: default !default;
$dx-calendar-item-selected-range-bg: unset !default;
$dx-calendar-item-selected-range-color: $dx-white !default;
$dx-calendar-item-selected-range-bg-alpha: 1 !default;
$dx-calendar-item-selected-bg-alpha: 20% !default;
$dx-calendar-item-selected-hover-bg-alpha: 30% !default;
$dx-calendar-item-focus-outline-color: $dx-primary !default;
$dx-calendar-item-focus-outline-width: $dx-focus-outline-width !default;

$dx-calendar-days-of-week-opacity: 0.5 !default;
$dx-calendar-week-number-opacity: 0.6 !default;
$dx-calendar-mounth-year-selection-border-radius: 0.1875rem !default;
$dx-calendar-today-border-color: $dx-primary !default;
$dx-calendar-weekend-color: rgb(220 53 69) !default;
$dx-calendar-not-current-view-color: rgb(97 95 99) !default;
$dx-calendar-days-of-week-font-size: 0.7857em !default;
$dx-calendar-week-number-font-size: 0.8571em !default;

// ListBox legacy
$dx-listbox-font-family: $dx-font-family !default;
$dx-listbox-font-size: $dx-font-size !default;
$dx-listbox-font-size-sm: $dx-font-size-sm !default;
$dx-listbox-font-size-lg: $dx-font-size-lg !default;
$dx-listbox-line-height: $dx-line-height !default;
$dx-listbox-line-height-sm: $dx-line-height-sm !default;
$dx-listbox-line-height-lg: $dx-line-height-lg !default;

$dx-listbox-bg: $dx-bg !default;
$dx-listbox-color: $dx-color !default;
$dx-listbox-border-color: $dx-border-color !default;
$dx-listbox-border-style: $dx-border-style !default;
$dx-listbox-border-width: $dx-border-width !default;
$dx-listbox-border-radius: $dx-border-radius !default;
$dx-listbox-border-radius-sm: $dx-border-radius-sm !default;
$dx-listbox-border-radius-lg: $dx-border-radius-lg !default;
$dx-listbox-disabled-bg: unset !default;
$dx-listbox-disabled-color: $dx-color !default;
$dx-listbox-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-listbox-default-height: 200px !default;

$dx-listbox-item-selected-bg: unset !default;
$dx-listbox-item-selected-color: $dx-state-primary-color !default;
$dx-listbox-item-multi-selected-bg: unset !default;
$dx-listbox-item-multi-selected-color: inherit !default;
$dx-listbox-item-active-bg: unset !default;
$dx-listbox-item-active-color: inherit !default;
$dx-listbox-item-hover-bg: unset !default;
$dx-listbox-item-hover-color: inherit !default;
$dx-listbox-item-selected-hover-bg: unset !default;
$dx-listbox-item-selected-hover-color: $dx-state-primary-color !default;
$dx-listbox-item-multi-selected-hover-bg: unset !default;
$dx-listbox-item-multi-selected-hover-color: inherit !default;
$dx-listbox-item-disabled-bg: unset !default;
$dx-listbox-item-disabled-color: inherit !default;
$dx-listbox-item-border-color: transparent !default;
$dx-listbox-item-border-style: $dx-border-style !default;
$dx-listbox-item-border-width: $dx-border-width !default;

$dx-listbox-item-padding-x: $dx-size-box-padding-x !default;
$dx-listbox-item-padding-y: $dx-size-box-padding-y !default;
$dx-listbox-item-padding-x-sm: $dx-size-box-padding-x-sm !default;
$dx-listbox-item-padding-y-sm: $dx-size-box-padding-y-sm !default;
$dx-listbox-item-padding-x-lg: $dx-size-box-padding-x-lg !default;
$dx-listbox-item-padding-y-lg: $dx-size-box-padding-y-lg !default;

$dx-listbox-empty-item-padding-x: 1rem !default;
$dx-listbox-empty-item-padding-y: 1rem !default;
$dx-listbox-empty-item-padding-x-sm: 0.75rem !default;
$dx-listbox-empty-item-padding-y-sm: 0.75rem !default;
$dx-listbox-empty-item-padding-x-lg: 1.5rem !default;
$dx-listbox-empty-item-padding-y-lg: 1.5rem !default;

$dx-listbox-column-header-bg: unset !default;
$dx-listbox-column-header-color: inherit !default;
$dx-listbox-column-header-font-weight: 600 !default;

$dx-listbox-check-element-padding-right: $dx-checkbox-spacing !default;
$dx-listbox-check-element-padding-right-sm: $dx-checkbox-spacing-sm !default;
$dx-listbox-check-element-padding-right-lg: $dx-checkbox-spacing-lg !default;

// ListBox
$dx-list-box-font-family: $dx-font-family !default;
$dx-list-box-font-size: $dx-font-size !default;
$dx-list-box-font-size-sm: $dx-font-size-sm !default;
$dx-list-box-font-size-lg: $dx-font-size-lg !default;
$dx-list-box-line-height: $dx-line-height !default;
$dx-list-box-line-height-sm: $dx-line-height-sm !default;
$dx-list-box-line-height-lg: $dx-line-height-lg !default;
$dx-list-box-focus-frame-color: $dx-primary !default;
$dx-list-box-focus-frame-size: 0.15rem;
$dx-list-box-focus-bg: unset !default;
$dx-list-box-focus-color: $dx-state-primary-color !default;

$dx-list-box-bg: $dx-bg !default;
$dx-list-box-color: $dx-color !default;
$dx-list-box-border-color: $dx-border-color !default;
$dx-list-box-border-style: $dx-border-style !default;
$dx-list-box-border-width: $dx-border-width !default;
$dx-list-box-border-radius: $dx-border-radius !default;
$dx-list-box-border-radius-sm: $dx-border-radius-sm !default;
$dx-list-box-border-radius-lg: $dx-border-radius-lg !default;
$dx-list-box-disabled-bg: unset !default;
$dx-list-box-disabled-color: $dx-color !default;
$dx-list-box-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-list-box-default-height: 200px !default;

$dx-list-box-item-selected-bg: unset !default;
$dx-list-box-item-selected-color: $dx-state-primary-color !default;
$dx-list-box-item-multi-selected-bg: unset !default;
$dx-list-box-item-multi-selected-color: inherit !default;
$dx-list-box-item-active-bg: unset !default;
$dx-list-box-item-active-color: inherit !default;
$dx-list-box-item-hover-bg: unset !default;
$dx-list-box-item-hover-color: inherit !default;
$dx-list-box-item-selected-hover-bg: unset !default;
$dx-list-box-item-selected-hover-color: $dx-state-primary-color !default;
$dx-list-box-item-multi-selected-hover-bg: unset !default;
$dx-list-box-item-multi-selected-hover-color: inherit !default;
$dx-list-box-item-selected-focused-bg-alpha: 20% !default;
$dx-list-box-item-selected-focused-color: unset !default;
$dx-list-box-item-disabled-bg: unset !default;
$dx-list-box-item-disabled-color: $dx-gray-600 !default;
$dx-list-box-item-selected-disabled-color: $dx-white !default;
$dx-list-box-item-selected-disabled-bg: unset !default;
$dx-list-box-item-multi-selected-disabled-bg: unset !default;
$dx-list-box-item-border-color: transparent !default;
$dx-list-box-item-border-style: $dx-border-style !default;
$dx-list-box-item-border-width: $dx-border-width !default;

$dx-list-box-empty-data-icon-width: 48px !default;
$dx-list-box-empty-data-icon-height: 48px !default;
$dx-list-box-empty-data-icon-color: $dx-gray-600 !default;
$dx-list-box-empty-data-font-weight: 400 !default;
$dx-list-box-empty-data-text-color: $dx-gray-600 !default;

$dx-list-box-item-padding-x: $dx-size-box-padding-x !default;
$dx-list-box-item-padding-y: $dx-size-box-padding-y !default;
$dx-list-box-item-padding-x-sm: $dx-size-box-padding-x-sm !default;
$dx-list-box-item-padding-y-sm: $dx-size-box-padding-y-sm !default;
$dx-list-box-item-padding-x-lg: $dx-size-box-padding-x-lg !default;
$dx-list-box-item-padding-y-lg: $dx-size-box-padding-y-lg !default;

$dx-list-box-empty-item-padding-x: 1rem !default;
$dx-list-box-empty-item-padding-y: 1rem !default;
$dx-list-box-empty-item-padding-x-sm: 0.75rem !default;
$dx-list-box-empty-item-padding-y-sm: 0.75rem !default;
$dx-list-box-empty-item-padding-x-lg: 1.5rem !default;
$dx-list-box-empty-item-padding-y-lg: 1.5rem !default;

$dx-list-box-column-header-bg: unset !default;
$dx-list-box-column-header-color: inherit !default;
$dx-list-box-column-header-font-weight: 600 !default;

$dx-list-box-check-element-padding-right: $dx-checkbox-spacing !default;
$dx-list-box-check-element-padding-right-sm: $dx-checkbox-spacing-sm !default;
$dx-list-box-check-element-padding-right-lg: $dx-checkbox-spacing-lg !default;

$dx-list-box-group-item-padding-x: 2 * $dx-list-box-item-padding-x !default;
$dx-list-box-group-item-padding-y: $dx-list-box-item-padding-y !default;
$dx-list-box-group-item-padding-x-sm: 2 * $dx-list-box-item-padding-x-sm !default;
$dx-list-box-group-item-padding-y-sm: $dx-list-box-item-padding-y-sm !default;
$dx-list-box-group-item-padding-x-lg: 2 * $dx-list-box-item-padding-x-lg !default;
$dx-list-box-group-item-padding-y-lg: $dx-list-box-item-padding-y-lg !default;

// Editor DropDown
$dx-edit-dropdown-font-family: $dx-font-family !default;
$dx-edit-dropdown-font-size: $dx-font-size !default;
$dx-edit-dropdown-font-size-sm: $dx-font-size-sm !default;
$dx-edit-dropdown-font-size-lg: $dx-font-size-lg !default;
$dx-edit-dropdown-line-height: $dx-line-height !default;
$dx-edit-dropdown-line-height-sm: $dx-line-height-sm !default;
$dx-edit-dropdown-line-height-lg: $dx-line-height-lg !default;

$dx-edit-dropdown-bg: $dx-bg !default;
$dx-edit-dropdown-color: $dx-color !default;
$dx-edit-dropdown-max-height: 200px !default;
$dx-edit-dropdown-margin-x: 0 !default;
$dx-edit-dropdown-margin-y: 0.0625rem !default;

$dx-edit-dropdown-header-bg: unset !default;
$dx-edit-dropdown-header-color: inherit !default;
$dx-edit-dropdown-header-title-font-weight: 600 !default;
$dx-edit-dropdown-header-btn-color: unset !default;
$dx-edit-dropdown-header-btn-hover-color: unset !default;
$dx-edit-dropdown-header-btn-disabled-color: unset !default;
$dx-edit-dropdown-header-padding-x: 0.25rem !default;
$dx-edit-dropdown-header-padding-y: 0.25rem !default;
$dx-edit-dropdown-header-padding-x-sm: 0.125rem !default;
$dx-edit-dropdown-header-padding-y-sm: 0.125rem !default;
$dx-edit-dropdown-header-padding-x-lg: 0.375rem !default;
$dx-edit-dropdown-header-padding-y-lg: 0.375rem !default;

$dx-edit-dropdown-btn-focus-outline-color: $dx-primary !default;
$dx-edit-dropdown-btn-focus-outline-width: $dx-focus-outline-width !default;
$dx-edit-dropdown-btn-focus-outline-offset: -1px !default;

// ColorPalette
$dx-color-palette-text-color: $dx-color !default;
$dx-color-palette-font-family: $dx-font-family !default;
$dx-color-palette-font-size: $dx-font-size !default;
$dx-color-palette-font-size-sm: $dx-font-size-sm !default;
$dx-color-palette-font-size-lg: $dx-font-size-lg !default;
$dx-color-palette-line-height: $dx-line-height !default;
$dx-color-palette-line-height-sm: $dx-line-height-sm !default;
$dx-color-palette-line-height-lg: $dx-line-height-lg !default;
$dx-color-palette-bg: $dx-bg !default;
$dx-color-palette-outline-focused: 2px solid $dx-primary !default;
$dx-color-palette-padding: 4px !default;
$dx-color-palette-padding-sm: 2px !default;
$dx-color-palette-padding-lg: 6px !default;
$dx-color-palette-border: 1px solid $dx-border-color !default;
$dx-color-palette-border-radius: 4px !default;
$dx-color-palette-header-height: 32px !default;
$dx-color-palette-header-height-sm: 24px !default;
$dx-color-palette-header-height-lg: 38px !default;
$dx-color-palette-header-padding: 6px !default;
$dx-color-palette-header-padding-sm: 4px !default;
$dx-color-palette-header-padding-lg: 8px !default;
$dx-color-palette-separator: 1px solid $dx-border-color !default;
$dx-color-palette-table-margin: 6px !default;
$dx-color-palette-table-margin-sm: 4px !default;
$dx-color-palette-table-margin-lg: 8px !default;
$dx-color-palette-table-spacing: 6px !default;
$dx-color-palette-table-spacing-sm: 4px !default;
$dx-color-palette-table-spacing-lg: 8px !default;
$dx-color-palette-no-color-padding: 8px 6px !default;
$dx-color-palette-no-color-padding-sm: 6px 4px !default;
$dx-color-palette-no-color-padding-lg: 10px 8px !default;
$dx-color-palette-no-color-hover-bg: unset !default;
$dx-color-palette-no-color-hover-color: $dx-color !default;
$dx-color-palette-no-color-spacing: 10px !default;
$dx-color-palette-no-color-spacing-sm: 8px !default;
$dx-color-palette-no-color-spacing-lg: 12px !default;
$dx-color-palette-no-color-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-color-palette-no-color-outline-offset-focused: -1px;
$dx-color-palette-no-color-separator-height: 2px;
$dx-color-palette-color-tile-size: 20px !default;
$dx-color-palette-color-tile-size-sm: 16px !default;
$dx-color-palette-color-tile-size-lg: 24px !default;
$dx-color-palette-color-tile-outline: 1px solid $dx-border-color !default;
$dx-color-palette-color-tile-outline-size-state: 2px;
$dx-color-palette-color-tile-outline-offset-focused: 2px !default;
$dx-color-palette-color-tile-border-radius: 4px !default;
$dx-color-palette-color-tile-hover-border: 2px solid $dx-bg !default;
$dx-color-palette-color-tile-outline-selected-color: $dx-primary !default;
$dx-color-palette-color-tile-outline-disabled-color: rgba($dx-primary, $dx-state-disabled-opacity) !default;

// Pager
$dx-pager-font-family: $dx-font-family !default;
$dx-pager-font-size: $dx-font-size !default;
$dx-pager-font-size-sm: $dx-font-size-sm !default;
$dx-pager-font-size-lg: $dx-font-size-lg !default;
$dx-pager-line-height: $dx-line-height !default;
$dx-pager-line-height-sm: $dx-line-height-sm !default;
$dx-pager-line-height-lg: $dx-line-height-lg !default;

$dx-pager-bg: transparent !default;
$dx-pager-color: $dx-color !default;
$dx-pager-border-color: transparent !default;
$dx-pager-border-style: solid !default;
$dx-pager-border-width: 0 !default;
$dx-pager-border-radius: $dx-border-radius !default;
$dx-pager-border-radius-sm: $dx-border-radius-sm !default;
$dx-pager-border-radius-lg: $dx-border-radius-lg !default;
$dx-pager-padding-x: 0 !default;
$dx-pager-padding-y: 0 !default;
$dx-pager-padding-x-sm: 0 !default;
$dx-pager-padding-y-sm: 0 !default;
$dx-pager-padding-x-lg: 0 !default;
$dx-pager-padding-y-lg: 0 !default;

$dx-pager-btn-bg: $dx-bg !default;
$dx-pager-btn-color: unset !default;
$dx-pager-btn-border-color: $dx-border-color !default;
$dx-pager-btn-hover-bg: unset !default;
$dx-pager-btn-hover-color: inherit !default;
$dx-pager-btn-disabled-bg: $dx-bg !default;
$dx-pager-btn-disabled-color: unset !default;
$dx-pager-page-btn-bg: $dx-pager-btn-bg !default;
$dx-pager-page-btn-color: $dx-color !default;
$dx-pager-page-btn-hover-bg: unset !default;
$dx-pager-page-btn-hover-color: inherit !default;
$dx-pager-active-page-btn-bg: $dx-primary !default;
$dx-pager-active-page-btn-color: $dx-white !default;

$dx-pager-btn-padding-x: max($dx-btn-padding-x - $dx-pager-padding-y, 0rem) !default;
$dx-pager-btn-padding-y: max($dx-btn-padding-y - $dx-pager-padding-y, 0rem) !default;
$dx-pager-btn-padding-x-sm: max($dx-btn-padding-x-sm - $dx-pager-padding-y-sm, 0rem) !default;
$dx-pager-btn-padding-y-sm: max($dx-btn-padding-y-sm - $dx-pager-padding-y-sm, 0rem) !default;
$dx-pager-btn-padding-x-lg: max($dx-btn-padding-x-lg - $dx-pager-padding-y-lg, 0rem) !default;
$dx-pager-btn-padding-y-lg: max($dx-btn-padding-y-lg - $dx-pager-padding-y-lg, 0rem) !default;

$dx-pager-container-padding-x: $dx-btn-container-padding-x !default;
$dx-pager-container-padding-y: $dx-btn-container-padding-y !default;
$dx-pager-container-padding-x-sm: $dx-btn-container-padding-x-sm !default;
$dx-pager-container-padding-y-sm: $dx-btn-container-padding-y-sm !default;
$dx-pager-container-padding-x-lg: $dx-btn-container-padding-x-lg !default;
$dx-pager-container-padding-y-lg: $dx-btn-container-padding-y-lg !default;
$dx-pager-container-spacing: 0.5rem !default;
$dx-pager-container-spacing-sm: 0.25rem !default;
$dx-pager-container-spacing-lg: 0.75rem !default;

$dx-pager-page-edit-bg: $dx-text-edit-bg !default;
$dx-pager-page-edit-color: $dx-text-edit-color !default;
$dx-pager-page-edit-border-color: $dx-text-edit-border-color !default;
$dx-pager-page-edit-padding-x: $dx-text-edit-padding-x !default;
$dx-pager-page-edit-padding-y: max($dx-text-edit-padding-y - $dx-pager-padding-y, 0rem) !default;
$dx-pager-page-edit-padding-x-sm: $dx-text-edit-padding-x-sm !default;
$dx-pager-page-edit-padding-y-sm: max($dx-text-edit-padding-y-sm - $dx-pager-padding-y-sm, 0rem) !default;
$dx-pager-page-edit-padding-x-lg: $dx-text-edit-padding-x-lg !default;
$dx-pager-page-edit-padding-y-lg: max($dx-text-edit-padding-y-lg - $dx-pager-padding-y-lg, 0rem) !default;


// Validation
$dx-validation-valid-color: $dx-success !default;
$dx-validation-invalid-color: $dx-danger !default;

// ScrollViewer
$dx-scroll-viewer-scroll-bar-z-index: 1 !default;
$dx-scroll-viewer-scroll-bar-size: 0.2rem !default;
$dx-scroll-viewer-scroll-bar-border-radius: 0.75rem !default;
$dx-scroll-viewer-scroll-bar-opacity: 0.75 !default;
$dx-scroll-viewer-scroll-bar-hover-size: 0.5rem !default;

// Grid
$dx-grid-font-family: $dx-font-family !default;
$dx-grid-font-size: $dx-font-size !default;
$dx-grid-font-size-sm: $dx-font-size-sm !default;
$dx-grid-font-size-lg: $dx-font-size-lg !default;
$dx-grid-line-height: $dx-line-height !default;
$dx-grid-line-height-sm: $dx-line-height-sm !default;
$dx-grid-line-height-lg: $dx-line-height-lg !default;

$dx-grid-bg: $dx-bg !default;
$dx-grid-color: $dx-color !default;
$dx-grid-border-color: $dx-border-color !default;
$dx-grid-fixed-column-border-color: $dx-gray-600 !default;
$dx-grid-border-style: $dx-border-style !default;
$dx-grid-border-width: $dx-border-width !default;
$dx-grid-border-radius: $dx-border-radius !default;
$dx-grid-border-radius-sm: $dx-border-radius-sm !default;
$dx-grid-border-radius-lg: $dx-border-radius-lg !default;

$dx-grid-hover-bg: inherit !default;
$dx-grid-hover-color: inherit !default;

$dx-grid-select-all-split-btn-container-btn-width: $dx-image-size + 2 * $dx-image-margin-y !default;
$dx-grid-select-all-split-btn-container-btn-width-sm: $dx-image-size-sm + 2 * $dx-image-margin-y-sm !default;
$dx-grid-select-all-split-btn-container-btn-width-lg: $dx-image-size-lg + 2 * $dx-image-margin-y-lg !default;
$dx-grid-selection-checkbox-wave-animation-color: linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 50%) 50%, rgb(255 255 255 / 0%) 100%) !default;

$dx-grid-empty-data-area-padding-x: 1.5rem !default;
$dx-grid-empty-data-area-padding-y: 3rem !default;
$dx-grid-empty-data-area-padding-x-sm: 1rem !default;
$dx-grid-empty-data-area-padding-y-sm: 2rem !default;
$dx-grid-empty-data-area-padding-x-lg: 2rem !default;
$dx-grid-empty-data-area-padding-y-lg: 4rem !default;

$dx-grid-text-cell-padding-x: $dx-size-box-padding-x !default;
$dx-grid-text-cell-padding-y: $dx-size-box-padding-y !default;
$dx-grid-text-cell-padding-x-sm: $dx-size-box-padding-x-sm !default;
$dx-grid-text-cell-padding-y-sm: $dx-size-box-padding-y-sm !default;
$dx-grid-text-cell-padding-x-lg: $dx-size-box-padding-x-lg !default;
$dx-grid-text-cell-padding-y-lg: $dx-size-box-padding-y-lg !default;
$dx-grid-text-cell-spacing: $dx-image-spacing !default;
$dx-grid-text-cell-spacing-sm: $dx-image-spacing-sm !default;
$dx-grid-text-cell-spacing-lg: $dx-image-spacing-lg !default;

$dx-grid-group-panel-container-padding-x: 1rem !default;
$dx-grid-group-panel-container-padding-y: 0.75rem !default;
$dx-grid-group-panel-container-padding-x-sm: 0.75rem !default;
$dx-grid-group-panel-container-padding-y-sm: 0.5rem !default;
$dx-grid-group-panel-container-padding-x-lg: 1.25rem !default;
$dx-grid-group-panel-container-padding-y-lg: 1rem !default;
$dx-grid-group-panel-container-spacing: 0.5rem !default;
$dx-grid-group-panel-container-spacing-sm: 0.25rem !default;
$dx-grid-group-panel-container-spacing-lg: 0.75rem !default;
$dx-grid-group-panel-header-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%) !default;
$dx-grid-toolbar-container-padding-x: 1rem !default;
$dx-grid-toolbar-container-padding-y: 0.75rem !default;
$dx-grid-toolbar-container-padding-x-sm: 0.75rem !default;
$dx-grid-toolbar-container-padding-y-sm: 0.5rem !default;
$dx-grid-toolbar-container-padding-x-lg: 1.25rem !default;
$dx-grid-toolbar-container-padding-y-lg: 1rem !default;
$dx-grid-toolbar-container-min-height: 3.375rem;
$dx-grid-toolbar-container-min-height-sm: 2.625rem;
$dx-grid-toolbar-container-min-height-lg: 4.125rem;

$dx-grid-group-indent-cell-width: $dx-size !default;
$dx-grid-group-indent-cell-width-sm: $dx-size-sm !default;
$dx-grid-group-indent-cell-width-lg: $dx-size-lg !default;

$dx-grid-empty-header-panel-padding-x: 1rem !default;
$dx-grid-empty-header-panel-padding-y: 0.5rem !default;
$dx-grid-empty-header-panel-padding-x-sm: 0.75rem !default;
$dx-grid-empty-header-panel-padding-y-sm: 0.375rem !default;
$dx-grid-empty-header-panel-padding-x-lg: 1.25rem !default;
$dx-grid-empty-header-panel-padding-y-lg: 0.625rem !default;

$dx-grid-edit-form-padding-x: 1rem !default;
$dx-grid-edit-form-padding-y: 1rem !default;
$dx-grid-edit-form-padding-x-sm: 0.5rem !default;
$dx-grid-edit-form-padding-y-sm: 0.5rem !default;
$dx-grid-edit-form-padding-x-lg: 1.5rem !default;
$dx-grid-edit-form-padding-y-lg: 1.5rem !default;
$dx-grid-edit-form-buttons-padding-x: 0 !default;
$dx-grid-edit-form-buttons-padding-y: 0.75rem !default;
$dx-grid-edit-form-buttons-padding-x-sm: 0 !default;
$dx-grid-edit-form-buttons-padding-y-sm: 0.5rem !default;
$dx-grid-edit-form-buttons-padding-x-lg: 0 !default;
$dx-grid-edit-form-buttons-padding-y-lg: 1rem !default;
$dx-grid-edit-form-buttons-spacing: 0.5rem !default;
$dx-grid-edit-form-buttons-spacing-sm: 0.375rem !default;
$dx-grid-edit-form-buttons-spacing-lg: 0.625rem !default;
$dx-grid-inplace-edit-border-width: 2px !default;
$dx-grid-top-fixed-body-height: 1.75rem !default;
$dx-grid-top-fixed-body-height-sm: 1.5rem !default;
$dx-grid-top-fixed-body-height-lg: 2rem !default;
$dx-grid-edit-new-item-row-content-gap: 0.5rem !default;
$dx-grid-edit-new-item-row-content-padding-left: 0.375rem !default;
$dx-grid-edit-new-item-row-content-padding-right: 0.375rem !default;
$dx-grid-edit-new-item-row-icon-transform-scale: 0.625 !default;

$dx-grid-confirm-dialog-buttons-spacing-x: 0.5rem !default;
$dx-grid-confirm-dialog-buttons-spacing-y: 1rem !default;
$dx-grid-confirm-dialog-buttons-spacing-x-sm: 0.375rem !default;
$dx-grid-confirm-dialog-buttons-spacing-y-sm: 0.5rem !default;
$dx-grid-confirm-dialog-buttons-spacing-x-lg: 0.625rem !default;
$dx-grid-confirm-dialog-buttons-spacing-y-lg: 1.5rem !default;

$dx-grid-detail-cell-padding-x: 1.75rem !default;
$dx-grid-detail-cell-padding-y: 1.75rem !default;
$dx-grid-detail-cell-padding-x-sm: 1.5rem !default;
$dx-grid-detail-cell-padding-y-sm: 1.5rem !default;
$dx-grid-detail-cell-padding-x-lg: 2rem !default;
$dx-grid-detail-cell-padding-y-lg: 2rem !default;

$dx-grid-expand-btn-bg: transparent;
$dx-grid-expand-btn-color: unset;
$dx-grid-expand-btn-hover-bg: unset;
$dx-grid-expand-btn-hover-color: unset;
$dx-grid-expand-btn-padding-x: $dx-btn-padding-y;
$dx-grid-expand-btn-padding-y: $dx-btn-padding-y;
$dx-grid-expand-btn-padding-x-sm: $dx-btn-padding-y-sm;
$dx-grid-expand-btn-padding-y-sm: $dx-btn-padding-y-sm;
$dx-grid-expand-btn-padding-x-lg: $dx-btn-padding-y-lg;
$dx-grid-expand-btn-padding-y-lg: $dx-btn-padding-y-lg;

$dx-grid-edit-row-bg: unset !default;
$dx-grid-edit-row-color: inherit !default;
$dx-grid-edit-form-btn-min-width: 4.5rem !default;
$dx-grid-confirm-dialog-btn-min-width: 3.25rem !default;

$dx-grid-header-font-weight: 600 !default;
$dx-grid-header-bg: unset !default;
$dx-grid-header-color: inherit !default;

$dx-grid-footer-font-weight: 700 !default;
$dx-grid-footer-bg: unset !default;
$dx-grid-footer-color: inherit !default;

$dx-grid-group-footer-font-weight: 600 !default;
$dx-grid-group-footer-bg: unset !default;
$dx-grid-group-footer-color: inherit !default;

$dx-grid-inline-edit-focus-border-opacity: 1 !default;
$dx-grid-fixed-cell-z-index: 3 !default;

$dx-grid-touch-bg: unset !default;
$dx-grid-touch-color: inherit !default;
$dx-grid-selection-bg: unset !default;
$dx-grid-selection-color: inherit !default;
$dx-grid-focus-bg: unset !default;
$dx-grid-focus-color: $dx-state-primary-color !default;
$dx-grid-focus-frame-color: $dx-primary !default;
$dx-grid-focus-frame-size: 0.15rem;
$dx-grid-selection-focus-bg: unset !default;
$dx-grid-selection-focus-color: $dx-state-primary-color !default;

$dx-grid-row-drag-hint-shadow: 0 0.1rem 0.5rem 0 rgb(0 0 0 / 60%) !default;
$dx-grid-row-drop-indicator-color: color-mix(in srgb, $dx-primary 95%, white 5%) !default;

$dx-grid-column-chooser-padding-x: 0.75rem !default;
$dx-grid-column-chooser-padding-y: 0.375rem !default;
$dx-grid-column-chooser-padding-x-sm: 0.5rem !default;
$dx-grid-column-chooser-padding-y-sm: 0.25rem !default;
$dx-grid-column-chooser-padding-x-lg: 1rem !default;
$dx-grid-column-chooser-padding-y-lg: 0.5rem !default;

$dx-grid-search-box-container-width: 17rem;
$dx-grid-search-box-container-width-sm: 15rem;
$dx-grid-search-box-container-width-lg: 19rem;
$dx-grid-search-box-container-padding-x: 1rem !default;
$dx-grid-search-box-container-padding-y: 0.75rem !default;
$dx-grid-search-box-container-padding-x-sm: 0.75rem !default;
$dx-grid-search-box-container-padding-y-sm: 0.5rem !default;
$dx-grid-search-box-container-padding-x-lg: 1.25rem !default;
$dx-grid-search-box-container-padding-y-lg: 1rem !default;

$dx-grid-highlighted-text-bg: yellow !default;
$dx-grid-highlighted-text-color: #212529 !default;
$dx-grid-min-width: 240px !default;
$dx-grid-max-height: 10000px !default;

$dx-grid-filter-menu-funnel-btn-padding-subtraction: 0.15rem !default;
$dx-grid-filter-menu-dropdown-width: 320px !default;
$dx-grid-filter-menu-dropdown-width-sm: 280px !default;
$dx-grid-filter-menu-dropdown-width-lg: 360px !default;
$dx-grid-filter-menu-dropdown-width-mobile: 270px !default;
$dx-grid-filter-menu-dropdown-width-mobile-sm: 250px !default;
$dx-grid-filter-menu-dropdown-width-mobile-lg: 290px !default;
$dx-grid-filter-menu-dropdown-min-width-mobile: 270px !default;
$dx-grid-filter-menu-dropdown-min-width-mobile-sm: 250px !default;
$dx-grid-filter-menu-dropdown-min-width-mobile-lg: 290px !default;
$dx-grid-filter-menu-dropdown-min-width: 320px !default;
$dx-grid-filter-menu-dropdown-min-width-sm: 280px !default;
$dx-grid-filter-menu-dropdown-min-width-lg: 360px !default;
$dx-grid-filter-menu-dropdown-max-width: none !default;
$dx-grid-filter-menu-dropdown-height: auto !default;
$dx-grid-filter-menu-dropdown-height-sm: auto !default;
$dx-grid-filter-menu-dropdown-height-lg: auto !default;
$dx-grid-filter-menu-dropdown-min-height: 165px !default;
$dx-grid-filter-menu-dropdown-min-height-sm: 130px !default;
$dx-grid-filter-menu-dropdown-min-height-lg: 200px !default;
$dx-grid-filter-menu-dropdown-max-height: none !default;
$dx-grid-filter-menu-dropdown-max-height-mobile: 550px !default;
$dx-grid-filter-menu-dropdown-max-height-mobile-sm: 250px !default;
$dx-grid-filter-menu-dropdown-list-box-height: 264px !default;
$dx-grid-filter-menu-dropdown-list-box-height-sm: 238px !default;
$dx-grid-filter-menu-dropdown-list-box-height-lg: 310px !default;
$dx-grid-filter-menu-dropdown-treeview-height: 327px !default;
$dx-grid-filter-menu-dropdown-treeview-height-sm: 292px !default;
$dx-grid-filter-menu-dropdown-treeview-height-lg: 382px !default;
$dx-grid-filter-menu-dropdown-loading-panel-height: 327px !default;
$dx-grid-filter-menu-dropdown-loading-panel-height-sm: 293px !default;
$dx-grid-filter-menu-dropdown-loading-panel-height-lg: 384px !default;
$dx-grid-filter-menu-dropdown-footer-btn-width: 143px !default;
$dx-grid-filter-menu-dropdown-footer-btn-width-sm: 128px !default;
$dx-grid-filter-menu-dropdown-footer-btn-width-lg: 158px !default;
$dx-grid-filter-menu-dropdown-footer-btn-space: 0.5rem !default;
$dx-grid-filter-menu-dropdown-footer-btn-space-sm: 0.375rem !default;
$dx-grid-filter-menu-dropdown-footer-btn-space-lg: 0.625rem !default;
$dx-grid-filter-menu-dropdown-padding-y: 0.75rem !default;
$dx-grid-filter-menu-dropdown-padding-y-sm: 0.5rem !default;
$dx-grid-filter-menu-dropdown-padding-y-lg: 1rem !default;

$dx-grid-empty-data-not-found-color: $dx-gray-600 !default;
$dx-grid-empty-data-not-found-image-color: $dx-gray-600 !default;
$dx-grid-empty-data-font-weight: 400 !default;
$dx-grid-empty-data-not-found-image-size-sm: 2rem !default;
$dx-grid-empty-data-not-found-image-size: 3rem !default;
$dx-grid-empty-data-not-found-image-size-lg: 4rem !default;
$dx-grid-empty-data-loading-text-padding-sm: 0.4rem !default;
$dx-grid-empty-data-loading-text-padding: 0.5rem !default;
$dx-grid-empty-data-loading-text-padding-lg: 0.6rem !default;

// Popup
$dx-popup-bg: $dx-bg !default;
$dx-popup-color: $dx-color !default;
$dx-popup-font-family: $dx-font-family !default;
$dx-popup-font-size: $dx-font-size !default;
$dx-popup-font-size-sm: $dx-font-size-sm !default;
$dx-popup-font-size-lg: $dx-font-size-lg !default;
$dx-popup-line-height: $dx-line-height !default;
$dx-popup-line-height-sm: $dx-line-height-sm !default;
$dx-popup-line-height-lg: $dx-line-height-lg !default;
$dx-popup-shadow: 0 2px 12px 0 rgb(0 0 0 / 25%) !default;

$dx-popup-border-width: $dx-border-width !default;
$dx-popup-border-style: $dx-border-style !default;
$dx-popup-border-color: $dx-border-color !default;
$dx-popup-border-radius: $dx-panel-border-radius !default;
$dx-popup-border-radius-sm: $dx-panel-border-radius-sm !default;
$dx-popup-border-radius-lg: $dx-panel-border-radius-lg !default;
$dx-popup-max-width: 500px !default;
$dx-popup-width: 100% !default;
$dx-popup-margin: 1.75rem !default;

$dx-popup-back-bg: #000 !default;
$dx-popup-back-opacity: 0.5 !default;
$dx-popup-scrollable-border-width: $dx-border-width !default;

$dx-popup-header-font-size: $dx-panel-header-font-size !default;
$dx-popup-header-font-size-sm: $dx-panel-header-font-size-sm !default;
$dx-popup-header-font-size-lg: $dx-panel-header-font-size-lg !default;
$dx-popup-header-line-height: $dx-panel-header-line-height !default;
$dx-popup-header-line-height-sm: $dx-panel-header-line-height-sm !default;
$dx-popup-header-line-height-lg: $dx-panel-header-line-height-lg !default;

$dx-popup-header-bg: unset !default;
$dx-popup-header-color: inherit !default;
$dx-popup-header-padding-y: $dx-panel-header-padding-y !default;
$dx-popup-header-padding-x: $dx-panel-header-padding-x !default;
$dx-popup-header-padding-y-lg: $dx-panel-header-padding-y-lg !default;
$dx-popup-header-padding-x-lg: $dx-panel-header-padding-x-lg !default;
$dx-popup-header-padding-y-sm: $dx-panel-header-padding-y-sm !default;
$dx-popup-header-padding-x-sm: $dx-panel-header-padding-x-sm !default;
$dx-popup-header-font-weight: 600 !default;
$dx-popup-header-btn-bg: transparent !default;
$dx-popup-header-btn-color: unset !default;
$dx-popup-header-btn-hover-bg: unset !default;
$dx-popup-header-btn-hover-color: unset !default;

$dx-popup-body-padding-x: $dx-panel-content-padding-x !default;
$dx-popup-body-padding-y: $dx-panel-content-padding-y !default;
$dx-popup-body-padding-x-sm: $dx-panel-content-padding-x-sm !default;
$dx-popup-body-padding-y-sm: $dx-panel-content-padding-y-sm !default;
$dx-popup-body-padding-x-lg: $dx-panel-content-padding-x-lg !default;
$dx-popup-body-padding-y-lg: $dx-panel-content-padding-y-lg !default;

$dx-popup-footer-bg: unset !default;
$dx-popup-footer-color: inherit !default;
$dx-popup-footer-padding-x: $dx-panel-footer-padding-x !default;
$dx-popup-footer-padding-y: $dx-panel-footer-padding-y !default;
$dx-popup-footer-padding-x-sm: $dx-panel-footer-padding-x-sm !default;
$dx-popup-footer-padding-y-sm: $dx-panel-footer-padding-y-sm !default;
$dx-popup-footer-padding-x-lg: $dx-panel-footer-padding-x-lg !default;
$dx-popup-footer-padding-y-lg: $dx-panel-footer-padding-y-lg !default;

// Window
$dx-window-font-family: $dx-font-family !default;
$dx-window-font-size: $dx-font-size !default;
$dx-window-font-size-sm: $dx-font-size-sm !default;
$dx-window-font-size-lg: $dx-font-size-lg !default;
$dx-window-line-height: $dx-line-height !default;
$dx-window-line-height-sm: $dx-line-height-sm !default;
$dx-window-line-height-lg: $dx-line-height-lg !default;

$dx-window-bg: $dx-bg !default;
$dx-window-color: $dx-color !default;
$dx-window-min-width: 0 !default;
$dx-window-border-width: $dx-border-width !default;
$dx-window-border-style: $dx-border-style !default;
$dx-window-border-color: $dx-border-color !default;
$dx-window-border-radius: $dx-border-radius !default;
$dx-window-border-radius-sm: $dx-border-radius-sm !default;
$dx-window-border-radius-lg: $dx-border-radius-lg !default;
$dx-window-box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%) !default;

$dx-window-header-font-size: $dx-panel-header-font-size !default;
$dx-window-header-font-size-sm: $dx-panel-header-font-size-sm !default;
$dx-window-header-font-size-lg: $dx-panel-header-font-size-lg !default;
$dx-window-header-line-height: $dx-panel-header-line-height !default;
$dx-window-header-line-height-sm: $dx-panel-header-line-height-sm !default;
$dx-window-header-line-height-lg: $dx-panel-header-line-height-lg !default;

$dx-window-header-bg: unset !default;
$dx-window-header-color: inherit !default;
$dx-window-header-font-weight: 600 !default;
$dx-window-header-padding-y: $dx-panel-header-padding-y !default;
$dx-window-header-padding-x: $dx-panel-header-padding-x !default;
$dx-window-header-padding-y-lg: $dx-panel-header-padding-y-lg !default;
$dx-window-header-padding-x-lg: $dx-panel-header-padding-x-lg !default;
$dx-window-header-padding-y-sm: $dx-panel-header-padding-y-sm !default;
$dx-window-header-padding-x-sm: $dx-panel-header-padding-x-sm !default;
$dx-window-header-btn-bg: transparent !default;
$dx-window-header-btn-color: unset !default;
$dx-window-header-btn-hover-bg: unset !default;
$dx-window-header-btn-hover-color: unset !default;

$dx-window-body-padding-x: $dx-panel-content-padding-x !default;
$dx-window-body-padding-y: $dx-panel-content-padding-y !default;
$dx-window-body-padding-x-sm: $dx-panel-content-padding-x-sm !default;
$dx-window-body-padding-y-sm: $dx-panel-content-padding-y-sm !default;
$dx-window-body-padding-x-lg: $dx-panel-content-padding-x-lg !default;
$dx-window-body-padding-y-lg: $dx-panel-content-padding-y-lg !default;

$dx-window-footer-bg: unset !default;
$dx-window-footer-color: inherit !default;
$dx-window-footer-padding-x: $dx-panel-footer-padding-x !default;
$dx-window-footer-padding-y: $dx-panel-footer-padding-y !default;
$dx-window-footer-padding-x-sm: $dx-panel-footer-padding-x-sm !default;
$dx-window-footer-padding-y-sm: $dx-panel-footer-padding-y-sm !default;
$dx-window-footer-padding-x-lg: $dx-panel-footer-padding-x-lg !default;
$dx-window-footer-padding-y-lg: $dx-panel-footer-padding-y-lg !default;

$dx-window-focus-outline-color: $dx-focus !default;
$dx-window-focus-outline-width: $dx-focus-outline-width !default;
$dx-window-focus-outline-offset: -2px !default;

// FlyOut
$dx-flyout-bg: $dx-bg !default;
$dx-flyout-color: $dx-color !default;
$dx-flyout-font-family: $dx-font-family !default;
$dx-flyout-font-size: $dx-font-size !default;
$dx-flyout-font-size-sm: $dx-font-size-sm !default;
$dx-flyout-font-size-lg: $dx-font-size-lg !default;
$dx-flyout-line-height: $dx-line-height !default;
$dx-flyout-line-height-sm: $dx-line-height-sm !default;
$dx-flyout-line-height-lg: $dx-line-height-lg !default;

$dx-flyout-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%) !default;
$dx-flyout-border-width: $dx-border-width !default;
$dx-flyout-border-style: $dx-border-style !default;
$dx-flyout-border-color: $dx-border-color !default;
$dx-flyout-border-radius: $dx-border-radius !default;
$dx-flyout-border-radius-sm: $dx-border-radius-sm !default;
$dx-flyout-border-radius-lg: $dx-border-radius-lg !default;

$dx-flyout-header-font-size: $dx-panel-header-font-size !default;
$dx-flyout-header-font-size-sm: $dx-panel-header-font-size-sm !default;
$dx-flyout-header-font-size-lg: $dx-panel-header-font-size-lg !default;
$dx-flyout-header-line-height: $dx-panel-header-line-height !default;
$dx-flyout-header-line-height-sm: $dx-panel-header-line-height-sm !default;
$dx-flyout-header-line-height-lg: $dx-panel-header-line-height-lg !default;

$dx-flyout-header-bg: unset !default;
$dx-flyout-header-color: inherit !default;
$dx-flyout-header-font-weight: 600 !default;
$dx-flyout-header-padding-y: $dx-panel-header-padding-y !default;
$dx-flyout-header-padding-x: $dx-panel-header-padding-x !default;
$dx-flyout-header-padding-y-lg: $dx-panel-header-padding-y-lg !default;
$dx-flyout-header-padding-x-lg: $dx-panel-header-padding-x-lg !default;
$dx-flyout-header-padding-y-sm: $dx-panel-header-padding-y-sm !default;
$dx-flyout-header-padding-x-sm: $dx-panel-header-padding-x-sm !default;

$dx-flyout-body-padding-x: $dx-panel-content-padding-x !default;
$dx-flyout-body-padding-y: $dx-panel-content-padding-y !default;
$dx-flyout-body-padding-x-sm: $dx-panel-content-padding-x-sm !default;
$dx-flyout-body-padding-y-sm: $dx-panel-content-padding-y-sm !default;
$dx-flyout-body-padding-x-lg: $dx-panel-content-padding-x-lg !default;
$dx-flyout-body-padding-y-lg: $dx-panel-content-padding-y-lg !default;

$dx-flyout-footer-bg: unset !default;
$dx-flyout-footer-color: inherit !default;
$dx-flyout-footer-padding-x: $dx-panel-footer-padding-x !default;
$dx-flyout-footer-padding-y: $dx-panel-footer-padding-y !default;
$dx-flyout-footer-padding-x-sm: $dx-panel-footer-padding-x-sm !default;
$dx-flyout-footer-padding-y-sm: $dx-panel-footer-padding-y-sm !default;
$dx-flyout-footer-padding-x-lg: $dx-panel-footer-padding-x-lg !default;
$dx-flyout-footer-padding-y-lg: $dx-panel-footer-padding-y-lg !default;

$dx-flyout-dialog-fade-transition-duration: 0.15s !default;
$dx-flyout-dialog-fade-transition-timing-function: linear !default;

$dx-flyout-arrow-width: 1rem !default;
$dx-flyout-arrow-height: 0.5rem !default;
$dx-flyout-arrow-color: rgb(34 34 34 / 25%) !default;
$dx-flyout-arrow-base-border-color: white !default;

// Dropdown
$dx-dropdown-font-family: $dx-font-family !default;
$dx-dropdown-font-size: $dx-font-size !default;
$dx-dropdown-font-size-sm: $dx-font-size-sm !default;
$dx-dropdown-font-size-lg: $dx-font-size-lg !default;
$dx-dropdown-line-height: $dx-line-height !default;
$dx-dropdown-line-height-sm: $dx-line-height-sm !default;
$dx-dropdown-line-height-lg: $dx-line-height-lg !default;

$dx-dropdown-bg: $dx-bg !default;
$dx-dropdown-color: $dx-color !default;
$dx-dropdown-min-width: 0 !default;
$dx-dropdown-border-width: $dx-border-width !default;
$dx-dropdown-border-style: $dx-border-style !default;
$dx-dropdown-border-color: $dx-border-color !default;
$dx-dropdown-border-radius: $dx-border-radius !default;
$dx-dropdown-border-radius-sm: $dx-border-radius-sm !default;
$dx-dropdown-border-radius-lg: $dx-border-radius-lg !default;
$dx-dropdown-box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%) !default;

$dx-dropdown-header-font-size: $dx-panel-header-font-size !default;
$dx-dropdown-header-font-size-sm: $dx-panel-header-font-size-sm !default;
$dx-dropdown-header-font-size-lg: $dx-panel-header-font-size-lg !default;
$dx-dropdown-header-line-height: $dx-panel-header-line-height !default;
$dx-dropdown-header-line-height-sm: $dx-panel-header-line-height-sm !default;
$dx-dropdown-header-line-height-lg: $dx-panel-header-line-height-lg !default;

$dx-dropdown-header-bg: unset !default;
$dx-dropdown-header-color: inherit !default;
$dx-dropdown-header-font-weight: 600 !default;
$dx-dropdown-header-padding-y: $dx-panel-header-padding-y !default;
$dx-dropdown-header-padding-x: $dx-panel-header-padding-x !default;
$dx-dropdown-header-padding-y-lg: $dx-panel-header-padding-y-lg !default;
$dx-dropdown-header-padding-x-lg: $dx-panel-header-padding-x-lg !default;
$dx-dropdown-header-padding-y-sm: $dx-panel-header-padding-y-sm !default;
$dx-dropdown-header-padding-x-sm: $dx-panel-header-padding-x-sm !default;

$dx-dropdown-body-padding-x: $dx-panel-content-padding-x !default;
$dx-dropdown-body-padding-y: $dx-panel-content-padding-y !default;
$dx-dropdown-body-padding-x-sm: $dx-panel-content-padding-x-sm !default;
$dx-dropdown-body-padding-y-sm: $dx-panel-content-padding-y-sm !default;
$dx-dropdown-body-padding-x-lg: $dx-panel-content-padding-x-lg !default;
$dx-dropdown-body-padding-y-lg: $dx-panel-content-padding-y-lg !default;
$dx-dropdown-body-align-items: flex-start !default;
$dx-dropdown-body-overflow-y: auto !default;

$dx-dropdown-footer-flex-wrap: wrap !default;
$dx-dropdown-footer-bg: unset !default;
$dx-dropdown-footer-color: inherit !default;
$dx-dropdown-footer-padding-x: $dx-panel-footer-padding-x !default;
$dx-dropdown-footer-padding-y: $dx-panel-footer-padding-y !default;
$dx-dropdown-footer-padding-x-sm: $dx-panel-footer-padding-x-sm !default;
$dx-dropdown-footer-padding-y-sm: $dx-panel-footer-padding-y-sm !default;
$dx-dropdown-footer-padding-x-lg: $dx-panel-footer-padding-x-lg !default;
$dx-dropdown-footer-padding-y-lg: $dx-panel-footer-padding-y-lg !default;

// Rollers
$dx-roolers-container-padding-x: 1rem !default;
$dx-roolers-container-padding-y: 1rem !default;
$dx-roolers-container-padding-x-sm: 0.75rem !default;
$dx-roolers-container-padding-y-sm: 0.75rem !default;
$dx-roolers-container-padding-x-lg: 1.25rem !default;
$dx-roolers-container-padding-y-lg: 1.25rem !default;

$dx-rooler-item-focus-opacity: 0.3 !default;
$dx-rooler-item-selection-opacity: 0.15 !default;
$dx-rooler-item-selection-font-weight: 600 !default;
$dx-rooler-item-invalid-data-opacity: 0.35 !default;
$dx-rooler-item-padding-x: 1rem !default;
$dx-rooler-item-padding-y: 0.5rem !default;
$dx-rooler-item-padding-x-sm: 0.75rem !default;
$dx-rooler-item-padding-y-sm: 0.375rem !default;
$dx-rooler-item-padding-x-lg: 1.25rem !default;
$dx-rooler-item-padding-y-lg: 0.625rem !default;

$dx-rooler-title-font-weight: 600 !default;
$dx-rooler-title-opacity: 0.5 !default;
$dx-rooler-title-padding-x: 1rem !default;
$dx-rooler-title-padding-y: 0.5rem !default;
$dx-rooler-title-padding-x-sm: 0.75rem !default;
$dx-rooler-title-padding-y-sm: 0.375rem !default;
$dx-rooler-title-padding-x-lg: 1.25rem !default;
$dx-rooler-title-padding-y-lg: 0.625rem !default;

$dx-roller-item-focus-outline-color: $dx-primary !default;
$dx-roller-item-focus-outline-width: $dx-focus-outline-width !default;

$dx-roolers-footer-bg: unset !default;
$dx-roolers-footer-color: inherit !default;
$dx-roolers-footer-btn-spacing: 0.5rem !default;
$dx-roolers-footer-btn-spacing-sm: 0.375rem !default;
$dx-roolers-footer-btn-spacing-lg: 0.625rem !default;
$dx-roolers-footer-padding-x: $dx-btn-container-padding-x !default;
$dx-roolers-footer-padding-y: $dx-btn-container-padding-y !default;
$dx-roolers-footer-padding-x-sm: $dx-btn-container-padding-x-sm !default;
$dx-roolers-footer-padding-y-sm: $dx-btn-container-padding-y-sm !default;
$dx-roolers-footer-padding-x-lg: $dx-btn-container-padding-x-lg !default;
$dx-roolers-footer-padding-y-lg: $dx-btn-container-padding-y-lg !default;

// Toolbar
$dx-toolbar-font-family: $dx-font-family !default;
$dx-toolbar-font-size: $dx-font-size !default;
$dx-toolbar-font-size-sm: $dx-font-size-sm !default;
$dx-toolbar-font-size-lg: $dx-font-size-lg !default;
$dx-toolbar-line-height: $dx-line-height !default;
$dx-toolbar-line-height-sm: $dx-line-height-sm !default;
$dx-toolbar-line-height-lg: $dx-line-height-lg !default;
$dx-toolbar-bg: transparent !default;
$dx-toolbar-color: $dx-color !default;

$dx-toolbar-title-font-size: $dx-panel-header-font-size !default;
$dx-toolbar-title-font-size-sm: $dx-panel-header-font-size-sm !default;
$dx-toolbar-title-font-size-lg: $dx-panel-header-font-size-lg !default;
$dx-toolbar-title-line-height: $dx-panel-header-line-height !default;
$dx-toolbar-title-line-height-sm: $dx-panel-header-line-height-sm !default;
$dx-toolbar-title-line-height-lg: $dx-panel-header-line-height-lg !default;
$dx-toolbar-title-color: inherit !default;
$dx-toolbar-title-font-weight: 600 !default;
$dx-toolbar-title-padding-x: $dx-size-box-padding-x !default;
$dx-toolbar-title-padding-y: 0 !default;
$dx-toolbar-title-padding-x-sm: $dx-size-box-padding-x-sm !default;
$dx-toolbar-title-padding-y-sm: 0 !default;
$dx-toolbar-title-padding-x-lg: $dx-size-box-padding-x-lg !default;
$dx-toolbar-title-padding-y-lg: 0 !default;

$dx-toolbar-btn-bg: $dx-bg !default;
$dx-toolbar-btn-color: $dx-color !default;
$dx-toolbar-btn-border-color: $dx-border-color !default;
$dx-toolbar-btn-border-style: $dx-border-style !default;
$dx-toolbar-btn-border-width: $dx-border-width !default;
$dx-toolbar-btn-border-radius: $dx-border-radius !default;
$dx-toolbar-btn-border-radius-sm: $dx-border-radius-sm !default;
$dx-toolbar-btn-border-radius-lg: $dx-border-radius-lg !default;
$dx-toolbar-btn-hover-bg: unset !default;
$dx-toolbar-btn-hover-color: inherit !default;
$dx-toolbar-btn-active-bg: unset !default;
$dx-toolbar-btn-active-color: inherit !default;
$dx-toolbar-btn-active-hover-bg: unset !default;
$dx-toolbar-btn-active-hover-color: inherit !default;
$dx-toolbar-btn-disabled-bg: $dx-bg !default;
$dx-toolbar-btn-disabled-color: inherit !default;
$dx-toolbar-btn-padding-x: #{$dx-btn-padding-x} !default;
$dx-toolbar-btn-padding-y: #{$dx-btn-padding-y} !default;
$dx-toolbar-btn-padding-x-sm: #{$dx-btn-padding-x-sm} !default;
$dx-toolbar-btn-padding-y-sm: #{$dx-btn-padding-y-sm} !default;
$dx-toolbar-btn-padding-x-lg: #{$dx-btn-padding-x-lg} !default;
$dx-toolbar-btn-padding-y-lg: #{$dx-btn-padding-y-lg} !default;

$dx-toolbar-dropdown-dialog-width: 500px !default;
$dx-toolbar-dropdown-bottom-sheet-width: 100% !default;
$dx-toolbar-dropdown-modal-max-width: 580px !default;
$dx-toolbar-dropdown-min-width: 10rem !default;
$dx-toolbar-dropdown-padding-x: 0 !default;
$dx-toolbar-dropdown-padding-y: 0.25rem !default;
$dx-toolbar-dropdown-padding-x-sm: 0 !default;
$dx-toolbar-dropdown-padding-y-sm: 0.125rem !default;
$dx-toolbar-dropdown-padding-x-lg: 0 !default;
$dx-toolbar-dropdown-padding-y-lg: 0.375rem !default;
$dx-toolbar-dropdown-modal-padding-x: $dx-popup-body-padding-x !default;
$dx-toolbar-dropdown-modal-padding-y: 0.375rem !default;
$dx-toolbar-dropdown-modal-padding-x-sm: $dx-popup-body-padding-x-sm !default;
$dx-toolbar-dropdown-modal-padding-y-sm: 0.25rem !default;
$dx-toolbar-dropdown-modal-padding-x-lg: $dx-popup-body-padding-x-lg !default;
$dx-toolbar-dropdown-modal-padding-y-lg: 0.5rem !default;

$dx-toolbar-dropdown-btn-bg: transparent !default;
$dx-toolbar-dropdown-btn-color: $dx-color !default;
$dx-toolbar-dropdown-btn-border-color: transparent !default;
$dx-toolbar-dropdown-btn-border-style: $dx-border-style !default;
$dx-toolbar-dropdown-btn-border-width: $dx-border-width !default;
$dx-toolbar-dropdown-btn-hover-bg: unset !default;
$dx-toolbar-dropdown-btn-hover-color: inherit !default;
$dx-toolbar-dropdown-btn-active-bg: unset !default;
$dx-toolbar-dropdown-btn-active-color: inherit !default;
$dx-toolbar-dropdown-btn-active-hover-bg: unset !default;
$dx-toolbar-dropdown-btn-active-hover-color: inherit !default;
$dx-toolbar-dropdown-btn-disabled-bg: unset !default;
$dx-toolbar-dropdown-btn-disabled-color: inherit !default;
$dx-toolbar-dropdown-btn-padding-x: $dx-btn-padding-x !default;
$dx-toolbar-dropdown-btn-padding-y: $dx-btn-padding-y !default;
$dx-toolbar-dropdown-btn-padding-x-sm: $dx-btn-padding-x-sm !default;
$dx-toolbar-dropdown-btn-padding-y-sm: $dx-btn-padding-y-sm !default;
$dx-toolbar-dropdown-btn-padding-x-lg: $dx-btn-padding-x-lg !default;
$dx-toolbar-dropdown-btn-padding-y-lg: $dx-btn-padding-y-lg !default;
$dx-toolbar-dropdown-btn-focus-outline-color: $dx-focus !default;
$dx-toolbar-dropdown-btn-focus-outline-width: $dx-focus-outline-width !default;

$dx-toolbar-separator-width: $dx-border-width !default;
$dx-toolbar-separator-color: $dx-border-color !default;

$dx-toolbar-group-spacing: $dx-btn-toolbar-spacing !default;
$dx-toolbar-group-spacing-sm: $dx-btn-toolbar-spacing-sm !default;
$dx-toolbar-group-spacing-lg: $dx-btn-toolbar-spacing-lg !default;
$dx-toolbar-item-spacing: $dx-btn-focus-shadow-size + $dx-min-spacing !default;
$dx-toolbar-item-spacing-sm: $dx-btn-focus-shadow-size + $dx-min-spacing !default;
$dx-toolbar-item-spacing-lg: $dx-btn-focus-shadow-size + $dx-min-spacing !default;

$dx-toolbar-group-large-spacing: calc(#{$dx-toolbar-group-spacing} * 2) !default;
$dx-toolbar-group-large-spacing-sm: calc(#{$dx-toolbar-group-spacing-sm} * 2) !default;
$dx-toolbar-group-large-spacing-lg: calc(#{$dx-toolbar-group-spacing-lg} * 2) !default;
$dx-toolbar-item-large-spacing: calc(#{$dx-toolbar-item-spacing} * 2) !default;
$dx-toolbar-item-large-spacing-sm: calc(#{$dx-toolbar-item-spacing-sm} * 2) !default;
$dx-toolbar-item-large-spacing-lg: calc(#{$dx-toolbar-item-spacing-lg} * 2) !default;

$dx-dropdown-bottom-sheet-border-radius: 12px !default;

// Ribbon Toolbar
$dx-toolbar-coloredit-image-border-color: currentcolor !default;
$dx-toolbar-coloredit-image-border-style: $dx-border-style !default;
$dx-toolbar-coloredit-image-border-width: $dx-border-width !default;
$dx-toolbar-coloredit-image-placeholder-border-color: red !default;
$dx-toolbar-coloredit-image-placeholder-border-style: $dx-border-style !default;
$dx-toolbar-coloredit-image-placeholder-border-width: 2px !default;
$dx-toolbar-coloredit-image-container-size: $dx-image-size !default;
$dx-toolbar-coloredit-image-container-size-sm: $dx-image-size-sm !default;
$dx-toolbar-coloredit-image-container-size-lg: $dx-image-size-lg !default;
$dx-toolbar-edit-item-spacing: 0.4em !default;
$dx-ribbon-toolbar-item-spacing: 0.5rem !default;

// Office ribbon
$dx-office-ribbon-context-tab-element-bg-alpha: 0.1 !default;
$dx-office-ribbon-context-tab-element-bg: $dx-primary !default;
$dx-office-ribbon-context-tab-bg: unset !default;
$dx-office-ribbon-context-tab-color: inherit !default;
$dx-office-ribbon-border-bottom-width: 1px !default;
$dx-office-ribbon-border-bottom-color: $dx-black !default;

// Ribbon
$dx-ribbon-context-tab-bg: rgba($dx-primary, 0.1) !default;
$dx-ribbon-padding: 0.375rem;
$dx-ribbon-color-picker-width: 1rem !default;
$dx-ribbon-color-picker-height: 1rem !default;
$dx-ribbon-item-margin-left: 0.25rem !default;

// Tabs
$dx-tab-size: 2.5rem !default; // 40px;
$dx-tab-size-sm: 2.25rem !default; // 36px;
$dx-tab-size-lg: 2.75rem !default; // 44px;

$dx-tabs-font-family: $dx-font-family !default;
$dx-tabs-font-size: $dx-font-size !default;
$dx-tabs-font-size-sm: $dx-font-size-sm !default;
$dx-tabs-font-size-lg: $dx-font-size-lg !default;
$dx-tabs-line-height: $dx-line-height !default;
$dx-tabs-line-height-sm: $dx-line-height-sm !default;
$dx-tabs-line-height-lg: $dx-line-height-lg !default;
$dx-tabs-bg: $dx-bg !default;
$dx-tabs-color: $dx-color !default;
$dx-tabs-padding-x: 0 !default;
$dx-tabs-padding-y: 0 !default;
$dx-tabs-padding-top: $dx-tabs-padding-y !default;
$dx-tabs-padding-bottom: $dx-tabs-padding-y !default;
$dx-tabs-padding-left: $dx-tabs-padding-x !default;
$dx-tabs-padding-right: $dx-tabs-padding-x !default;
$dx-tabs-btn-spacing: 0.375rem !default;
$dx-tabs-btn-spacing-sm: 0.25rem !default;
$dx-tabs-btn-spacing-lg: 0.5rem !default;
$dx-tabs-separator-border-color: $dx-border-color !default;
$dx-tabs-separator-border-style: $dx-border-style !default;
$dx-tabs-separator-border-width: $dx-border-width !default;
$dx-tabs-border-radius: $dx-border-radius !default;
$dx-tabs-tab-drag-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%) !default;

$dx-tabs-scroll-btn-color: unset !default;
$dx-tabs-scroll-btn-hover-color: unset !default;
$dx-tabs-scroll-btn-disabled-color: unset !default;
$dx-tabs-close-btn-color: $dx-gray-600 !default;
$dx-tabs-close-btn-hover-bg: $dx-gray-300 !default;
$dx-tabs-close-btn-hover-color: $dx-black !default;
$dx-tabs-close-btn-height: 1.5rem !default;
$dx-tabs-close-btn-height-sm: 1.25rem !default;
$dx-tabs-close-btn-height-lg: 1.75rem !default;
$dx-tabs-close-btn-width: 1.5rem !default;
$dx-tabs-close-btn-width-sm: 1.25rem !default;
$dx-tabs-close-btn-width-lg: 1.75rem !default;

$dx-tabs-content-color: $dx-color !default;
$dx-tabs-content-font-family: $dx-font-family !default;
$dx-tabs-content-font-size: $dx-font-size !default;
$dx-tabs-content-font-size-sm: $dx-font-size-sm !default;
$dx-tabs-content-font-size-lg: $dx-font-size-lg !default;
$dx-tabs-content-line-height: $dx-line-height !default;
$dx-tabs-content-line-height-sm: $dx-line-height-sm !default;
$dx-tabs-content-line-height-lg: $dx-line-height-lg !default;

$dx-tabs-tab-border-color: transparent !default;
$dx-tabs-tab-border-style: $dx-border-style !default;
$dx-tabs-tab-border-width: $dx-border-width !default;
$dx-tabs-tab-inactive-border-color: transparent !default;
$dx-tabs-tab-border-radius: 0 !default;
$dx-tabs-horizontal-tab-width: unset !default;
$dx-tabs-vertical-tab-width: 120px !default;

$dx-tabs-tab-padding-x: 1rem !default;
$dx-tabs-tab-padding-x-sm: $dx-tabs-tab-padding-x !default;
$dx-tabs-tab-padding-x-lg: $dx-tabs-tab-padding-x !default;
$dx-tabs-tab-padding-y: math.div($dx-tab-size - $dx-size - $dx-borders-width, 2) !default;
$dx-tabs-tab-padding-y-sm: math.div($dx-tab-size-sm - $dx-size-sm - $dx-borders-width, 2) !default;
$dx-tabs-tab-padding-y-lg: math.div($dx-tab-size-lg - $dx-size-lg - $dx-borders-width, 2) !default;

$dx-tabs-tab-selected-bg: unset !default;
$dx-tabs-tab-selected-color: $dx-primary !default;
$dx-tabs-tab-hover-bg: unset !default;
$dx-tabs-tab-hover-color: inherit !default;

$dx-tabs-tab-btn-bg: transparent !default;
$dx-tabs-tab-btn-color: unset !default;

$dx-tabs-image-spacing: $dx-image-spacing !default;
$dx-tabs-image-spacing-sm: $dx-image-spacing-sm !default;
$dx-tabs-image-spacing-lg: $dx-image-spacing-lg !default;
$dx-tabs-image-margin-y: math.div($dx-tab-size - $dx-tabs-tab-padding-y * 2 - $dx-image-size - $dx-borders-width, 2) !default;
$dx-tabs-image-margin-y-sm: math.div($dx-tab-size-sm - $dx-tabs-tab-padding-y-sm * 2 - $dx-image-size-sm - $dx-borders-width, 2) !default;
$dx-tabs-image-margin-y-lg: math.div($dx-tab-size-lg - $dx-tabs-tab-padding-y-lg * 2 - $dx-image-size-lg - $dx-borders-width, 2) !default;

$dx-tabs-tab-selected-element-bg: $dx-primary !default;
$dx-tabs-tab-selected-element-height: $dx-border-width * 3 !default;

$dx-tabs-tab-focus-outline-color: $dx-primary !default;
$dx-tabs-tab-focus-outline-width: $dx-focus-outline-width !default;

$dx-tabs-tab-drag-bg-color: $dx-bg !default;
$dx-tabs-tab-drop-indicator-color: color-mix(in srgb, $dx-primary 95%, white 5%) !default;


// GroupControl
$dx-group-border-width: $dx-border-width !default;
$dx-group-border-color: $dx-border-color !default;
$dx-group-border-radius: 6px !default;
$dx-group-color: $dx-color !default;
$dx-group-bg: $dx-bg !default;
$dx-group-font-family: $dx-font-family !default;
$dx-group-font-size: $dx-font-size !default;
$dx-group-font-size-sm: $dx-font-size-sm !default;
$dx-group-font-size-lg: $dx-font-size-lg !default;
$dx-group-line-height: $dx-line-height !default;
$dx-group-line-height-sm: $dx-line-height-sm !default;
$dx-group-line-height-lg: $dx-line-height-lg !default;

$dx-group-header-bg: unset !default;
$dx-group-header-color: inherit !default;
$dx-group-header-hover-bg: unset !default;
$dx-group-header-hover-color: inherit !default;
$dx-group-header-selected-bg: unset !default;
$dx-group-header-selected-color: inherit !default;
$dx-group-header-padding-x: $dx-panel-header-padding-x !default; // 12px
$dx-group-header-padding-x-sm: $dx-panel-header-padding-x-sm !default; // 8px
$dx-group-header-padding-x-lg: $dx-panel-header-padding-x-lg !default; // 16px
$dx-group-header-padding-y: $dx-panel-header-padding-y !default;
$dx-group-header-padding-y-sm: $dx-panel-header-padding-y-sm !default;
$dx-group-header-padding-y-lg: $dx-panel-header-padding-y-lg !default;
$dx-group-header-spacing: $dx-panel-header-spacing !default;
$dx-group-header-spacing-sm: $dx-panel-header-spacing-sm !default;
$dx-group-header-spacing-lg: $dx-panel-header-spacing-lg !default;
$dx-group-header-border-radius: $dx-group-border-radius - $dx-group-border-width !default;
$dx-group-header-font-size: $dx-panel-header-font-size !default; // 16px
$dx-group-header-font-size-sm: $dx-panel-header-font-size-sm !default; // 14px
$dx-group-header-font-size-lg: $dx-panel-header-font-size-lg !default; // 18px
$dx-group-header-line-height: $dx-panel-header-line-height !default;
$dx-group-header-line-height-sm: $dx-panel-header-line-height-sm !default;
$dx-group-header-line-height-lg: $dx-panel-header-line-height-lg !default;
$dx-group-header-font-weight: 400 !default;
$dx-group-header-btn-bg: transparent !default;
$dx-group-header-btn-color: unset !default;
$dx-group-header-btn-hover-bg: unset !default;
$dx-group-header-btn-hover-color: unset !default;

// FormLayout
$dx-fl-font-family: $dx-font-family !default;
$dx-fl-font-size: $dx-font-size !default;
$dx-fl-font-size-sm: $dx-font-size-sm !default;
$dx-fl-font-size-lg: $dx-font-size-lg !default;
$dx-fl-line-height: $dx-line-height !default;
$dx-fl-line-height-sm: $dx-line-height-sm !default;
$dx-fl-line-height-lg: $dx-line-height-lg !default;
$dx-fl-padding-x: 0 !default;
$dx-fl-padding-x-sm: 0 !default;
$dx-fl-padding-x-lg: 0 !default;
$dx-fl-padding-y: 0 !default;
$dx-fl-padding-y-sm: 0 !default;
$dx-fl-padding-y-lg: 0 !default;
$dx-fl-group-border-radius: $dx-group-border-radius !default;
$dx-fl-group-color: $dx-group-color !default;
$dx-fl-group-bg: $dx-group-bg !default;
$dx-fl-group-spacing-y: 1.5rem !default;
$dx-fl-group-spacing-y-sm: 1rem !default;
$dx-fl-group-spacing-y-lg: 2rem !default;

$dx-fl-group-header-bg: $dx-group-header-bg !default;
$dx-fl-group-header-color: $dx-group-header-color !default;
$dx-fl-group-header-padding-x: $dx-group-header-padding-x !default;
$dx-fl-group-header-padding-x-sm: $dx-group-header-padding-x-sm !default;
$dx-fl-group-header-padding-x-lg: $dx-group-header-padding-x-lg !default;
$dx-fl-group-header-padding-y: $dx-group-header-padding-y !default;
$dx-fl-group-header-padding-y-sm: $dx-group-header-padding-y-sm !default;
$dx-fl-group-header-padding-y-lg: $dx-group-header-padding-y-lg !default;
$dx-fl-group-header-border-radius: $dx-group-header-border-radius !default;
$dx-fl-group-header-font-size: $dx-group-header-font-size !default;
$dx-fl-group-header-font-size-sm: $dx-group-header-font-size-sm !default;
$dx-fl-group-header-font-size-lg: $dx-group-header-font-size-lg !default;
$dx-fl-group-header-line-height: $dx-group-header-line-height !default;
$dx-fl-group-header-line-height-sm: $dx-group-header-line-height-sm !default;
$dx-fl-group-header-line-height-lg: $dx-group-header-line-height-lg !default;
$dx-fl-group-header-font-weight: $dx-group-header-font-weight !default;
$dx-fl-group-header-image-spacing: 0.5rem !default;
$dx-fl-group-header-btn-bg: $dx-group-header-btn-bg !default;
$dx-fl-group-header-btn-color: $dx-group-header-btn-color !default;
$dx-fl-group-header-btn-hover-bg: $dx-group-header-btn-hover-bg !default;
$dx-fl-group-header-btn-hover-color: $dx-group-header-btn-hover-color !default;
$dx-fl-group-header-spacing: $dx-group-header-spacing !default;
$dx-fl-group-header-spacing-sm: $dx-group-header-spacing-sm !default;
$dx-fl-group-header-spacing-lg: $dx-group-header-spacing-lg !default;

$dx-fl-group-content-padding-x: $dx-panel-content-padding-x !default; // 12px
$dx-fl-group-content-padding-x-sm: $dx-panel-content-padding-x-sm !default; // 8px
$dx-fl-group-content-padding-x-lg: $dx-panel-content-padding-x-lg !default; // 16px
$dx-fl-group-content-padding-y: $dx-panel-content-padding-y !default; // 12px
$dx-fl-group-content-padding-y-sm: $dx-panel-content-padding-y-sm !default; // 8px
$dx-fl-group-content-padding-y-lg: $dx-panel-content-padding-y-lg !default; // 16px
$dx-fl-group-font-family: $dx-group-font-family !default;
$dx-fl-group-font-size: $dx-group-font-size !default;
$dx-fl-group-font-size-sm: $dx-group-font-size-sm !default;
$dx-fl-group-font-size-lg: $dx-group-font-size-lg !default;
$dx-fl-group-line-height: $dx-group-line-height !default;
$dx-fl-group-line-height-sm: $dx-group-line-height-sm !default;
$dx-fl-group-line-height-lg: $dx-group-line-height-lg !default;

$dx-fl-tab-group-font-family: $dx-tabs-font-family !default;
$dx-fl-tab-group-font-size: $dx-tabs-font-size !default;
$dx-fl-tab-group-font-size-sm: $dx-tabs-font-size-sm !default;
$dx-fl-tab-group-font-size-lg: $dx-tabs-font-size-lg !default;
$dx-fl-tab-group-line-height: $dx-tabs-line-height !default;
$dx-fl-tab-group-line-height-sm: $dx-tabs-line-height-sm !default;
$dx-fl-tab-group-line-height-lg: $dx-tabs-line-height-lg !default;
$dx-fl-tab-group-bg: $dx-tabs-bg !default;
$dx-fl-tab-group-color: $dx-tabs-color !default;
$dx-fl-tab-group-padding-top: $dx-tabs-padding-top !default;
$dx-fl-tab-group-padding-x: $dx-tabs-padding-x !default;
$dx-fl-tab-group-bottom-border-color: $dx-tabs-separator-border-color !default;
$dx-fl-tab-group-bottom-border-style: $dx-tabs-separator-border-style !default;
$dx-fl-tab-group-bottom-border-width: $dx-tabs-separator-border-width !default;

$dx-fl-tab-group-content-padding-x: $dx-panel-content-padding-x !default;
$dx-fl-tab-group-content-padding-x-sm: $dx-panel-content-padding-x-sm !default;
$dx-fl-tab-group-content-padding-x-lg: $dx-panel-content-padding-x-lg !default;
$dx-fl-tab-group-content-padding-y: $dx-panel-content-padding-y !default;
$dx-fl-tab-group-content-padding-y-sm: $dx-panel-content-padding-y-sm !default;
$dx-fl-tab-group-content-padding-y-lg: $dx-panel-content-padding-y-lg !default;
$dx-fl-tab-group-content-color: $dx-tabs-content-color !default;
$dx-fl-tab-group-content-font-size: $dx-tabs-content-font-size !default;
$dx-fl-tab-group-content-font-size-sm: $dx-tabs-content-font-size-sm !default;
$dx-fl-tab-group-content-font-size-lg: $dx-tabs-content-font-size-lg !default;
$dx-fl-tab-group-content-line-height: $dx-tabs-content-line-height !default;
$dx-fl-tab-group-content-line-height-sm: $dx-tabs-content-line-height-sm !default;
$dx-fl-tab-group-content-line-height-lg: $dx-tabs-content-line-height-lg !default;

$dx-fl-tab-group-tab-tab-padding-x: $dx-tabs-tab-padding-x !default;
$dx-fl-tab-group-tab-tab-padding-x-sm: $dx-tabs-tab-padding-x-sm !default;
$dx-fl-tab-group-tab-tab-padding-x-lg: $dx-tabs-tab-padding-x-lg !default;
$dx-fl-tab-group-tab-tab-padding-y: $dx-tabs-tab-padding-y !default;
$dx-fl-tab-group-tab-tab-padding-y-sm: $dx-tabs-tab-padding-y-sm !default;
$dx-fl-tab-group-tab-tab-padding-y-lg: $dx-tabs-tab-padding-y-lg !default;
$dx-fl-tab-group-tab-border-style: $dx-tabs-tab-border-style !default;
$dx-fl-tab-group-tab-border-color: $dx-tabs-tab-border-color !default;
$dx-fl-tab-group-tab-selected-bg: $dx-tabs-tab-selected-bg !default;
$dx-fl-tab-group-tab-selected-color: $dx-tabs-tab-selected-color !default;
$dx-fl-tab-group-tab-hover-bg: $dx-tabs-tab-hover-bg !default;
$dx-fl-tab-group-tab-hover-color: $dx-tabs-tab-hover-color !default;

$dx-fl-tab-group-image-spacing: $dx-tabs-image-spacing !default;
$dx-fl-tab-group-image-spacing-sm: $dx-tabs-image-spacing-sm !default;
$dx-fl-tab-group-image-spacing-lg: $dx-tabs-image-spacing-lg !default;

$dx-fl-caption-opacity: 0.75 !default;
$dx-fl-caption-color: rgba($dx-color, $dx-fl-caption-opacity);
$dx-fl-caption-font-weight: 400 !default;

$dx-row-item-spacing-x: 1.5rem !default;
$dx-row-item-spacing-y: 0.75rem !default;
$dx-row-columns: 12 !default;

$dx-fl-item-spacing-x: 1.5rem !default;
$dx-fl-item-spacing-x-sm: 1rem !default;
$dx-fl-item-spacing-x-lg: 2rem !default;
$dx-fl-item-spacing-y: 0.75rem !default;
$dx-fl-item-spacing-y-sm: 0.5rem !default;
$dx-fl-item-spacing-y-lg: 1rem !default;

// Navigation
$dx-navigation-item-hover-bg: unset !default;
$dx-navigation-item-hover-base-bg: currentcolor !default;
$dx-navigation-item-hover-bg-alpha: $dx-state-hover-bg-alpha !default;
$dx-navigation-item-selected-bg: unset !default;

$dx-navigation-expand-btn-hover-bg: unset !default;
$dx-navigation-expand-btn-hover-base-bg: currentcolor !default;
$dx-navigation-expand-btn-hover-bg-alpha: $dx-state-hover-bg-alpha !default;

$dx-navigation-filter-content-bg-color: yellow !default;
$dx-navigation-filter-content-color: $dx-gray-900 !default;

// these variables are required in order to provide sizing works properly till we change values in size-box.scss
$dx-navigation-item-line-height: 1.4285 !default;
$dx-navigation-item-line-height-sm: 1.333 !default;
$dx-navigation-item-line-height-lg: 1.5 !default;
$dx-navigation-item-text-size: $dx-font-size * $dx-navigation-item-line-height !default; // 20px/1.25rem
$dx-navigation-item-text-size-sm: $dx-font-size-sm * $dx-navigation-item-line-height-sm !default; // 16px/1rem
$dx-navigation-item-text-size-lg: $dx-font-size-lg * $dx-navigation-item-line-height-lg !default; // 24px/1.5rem
$dx-navigation-item-text-padding-y: math.div($dx-borderless-size - $dx-navigation-item-text-size, 2) !default;
$dx-navigation-item-text-padding-y-sm: math.div($dx-borderless-size-sm - $dx-navigation-item-text-size-sm, 2) !default;
$dx-navigation-item-text-padding-y-lg: math.div($dx-borderless-size-lg - $dx-navigation-item-text-size-lg, 2) !default;
$dx-navigation-item-padding-y: math.div($dx-size - $dx-text-size, 2) !default;
$dx-navigation-item-padding-y-sm: math.div($dx-size-sm - $dx-text-size-sm, 2) !default;
$dx-navigation-item-padding-y-lg: math.div($dx-size-lg - $dx-text-size-lg, 2) !default;
$dx-navigation-item-spacing: 0.375rem !default;
$dx-navigation-item-spacing-sm: 0.25rem !default;
$dx-navigation-item-spacing-lg: 0.5rem !default;

$dx-navigation-image-spacing: 0.5rem !default;
$dx-navigation-image-spacing-sm: 0.375rem !default;
$dx-navigation-image-spacing-lg: 0.625rem !default;

// Accordion

// redo after new tokens
$dx-accordion-group-header-line-height: 1.25 !default;
$dx-accordion-group-header-line-height-sm: 1.1428 !default;
$dx-accordion-group-header-line-height-lg: 1.2 !default;
$dx-accordion-group-header-font-size: 1rem !default;
$dx-accordion-group-header-font-size-sm: 0.875rem !default;
$dx-accordion-group-header-font-size-lg: 1.25rem !default;
$dx-accordion-group-header-text-size: $dx-accordion-group-header-font-size * $dx-accordion-group-header-line-height !default;
$dx-accordion-group-header-text-size-sm: $dx-accordion-group-header-font-size-sm * $dx-accordion-group-header-line-height-sm !default;
$dx-accordion-group-header-text-size-lg: $dx-accordion-group-header-font-size-lg * $dx-accordion-group-header-line-height-lg !default;
$dx-accordion-group-header-text-padding-y: math.div($dx-borderless-size - $dx-accordion-group-header-text-size, 2) !default;
$dx-accordion-group-header-text-padding-y-sm: math.div($dx-borderless-size-sm - $dx-accordion-group-header-text-size-sm, 2) !default;
$dx-accordion-group-header-text-padding-y-lg: math.div($dx-borderless-size-lg - $dx-accordion-group-header-text-size-lg, 2) !default;

$dx-accordion-group-font-size: $dx-group-font-size !default;
$dx-accordion-group-font-size-sm: $dx-group-font-size-sm !default;
$dx-accordion-group-font-size-lg: $dx-group-font-size-lg !default;
$dx-accordion-group-line-height: $dx-navigation-item-line-height !default;
$dx-accordion-group-line-height-sm: $dx-navigation-item-line-height-sm !default;
$dx-accordion-group-line-height-lg: $dx-navigation-item-line-height-lg !default;
$dx-accordion-group-border-width: $dx-group-border-width !default;
$dx-accordion-group-border-color: $dx-group-border-color !default;
$dx-accordion-group-border-radius: 4px !default;

$dx-accordion-group-header-padding-y: $dx-group-header-padding-y !default;
$dx-accordion-group-header-padding-y-sm: $dx-group-header-padding-y-sm !default;
$dx-accordion-group-header-padding-y-lg: $dx-group-header-padding-y-lg !default;
$dx-accordion-group-header-padding-x: 0.75rem !default;
$dx-accordion-group-header-padding-x-sm: 0.5rem !default;
$dx-accordion-group-header-padding-x-lg: 1rem !default;

$dx-accordion-group-header-bg: $dx-group-header-bg !default;
$dx-accordion-group-header-color: $dx-group-header-color !default;
$dx-accordion-group-header-hover-bg: $dx-group-header-hover-bg !default;
$dx-accordion-group-header-hover-color: $dx-group-header-hover-color !default;
$dx-accordion-group-header-selected-bg: $dx-group-header-selected-bg !default;
$dx-accordion-group-header-selected-color: $dx-group-header-selected-color !default;
$dx-accordion-group-header-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-accordion-group-header-border-radius: $dx-accordion-group-border-radius - $dx-accordion-group-border-width !default;

$dx-accordion-group-item-font-size: $dx-accordion-group-font-size !default;
$dx-accordion-group-item-font-size-sm: $dx-accordion-group-font-size-sm !default;
$dx-accordion-group-item-font-size-lg: $dx-accordion-group-font-size-lg !default;
$dx-accordion-group-item-line-height: $dx-accordion-group-line-height !default;
$dx-accordion-group-item-line-height-sm: $dx-accordion-group-line-height-sm !default;
$dx-accordion-group-item-line-height-lg: $dx-accordion-group-line-height-lg !default;
$dx-accordion-group-item-spacing: $dx-navigation-item-spacing !default;
$dx-accordion-group-item-spacing-sm: $dx-navigation-item-spacing-sm !default;
$dx-accordion-group-item-spacing-lg: $dx-navigation-item-spacing-lg !default;
$dx-accordion-item-content-indent: $dx-size !default;
$dx-accordion-item-content-indent-sm: $dx-size-sm !default;
$dx-accordion-item-content-indent-lg: $dx-size-lg !default;
$dx-accordion-item-content-padding-x: $dx-panel-header-padding-x !default; // 12px
$dx-accordion-item-content-padding-x-sm: $dx-panel-header-padding-x-sm !default; // 8px
$dx-accordion-item-content-padding-x-lg: $dx-panel-header-padding-x-lg !default; // 16px

$dx-accordion-item-hover-color: inherit !default;
$dx-accordion-item-hover-bg: unset !default;
$dx-accordion-item-selection-bg: unset !default;
$dx-accordion-item-selection-color: inherit !default;
$dx-accordion-item-selected-base-bg: currentcolor !default;
$dx-accordion-item-selected-bg-alpha: $dx-state-selected-bg-alpha !default;

$dx-accordion-item-btn-bg: transparent !default;
$dx-accordion-item-btn-color: unset !default;
$dx-accordion-item-btn-hover-bg: unset !default;
$dx-accordion-item-btn-hover-color: inherit !default;

// TreeView
$dx-treeview-spacing-x: 0.25rem !default;
$dx-treeview-spacing-y: 0 !default;
$dx-treeview-font-size: $dx-font-size !default;
$dx-treeview-font-size-sm: $dx-font-size-sm !default;
$dx-treeview-font-size-lg: $dx-font-size-lg !default;
$dx-treeview-font-weight: 400 !default;

$dx-treeview-btn-bg: transparent !default;
$dx-treeview-btn-color: unset !default;
$dx-treeview-btn-hover-bg: unset !default;
$dx-treeview-btn-hover-base-bg: currentcolor !default;
$dx-treeview-btn-hover-color: inherit !default;
$dx-treeview-btn-hover-bg-alpha: $dx-state-hover-bg-alpha !default;

$dx-treeview-image-spacing: $dx-image-spacing !default;
$dx-treeview-image-spacing-sm: $dx-image-spacing-sm !default;
$dx-treeview-image-spacing-lg: $dx-image-spacing-lg !default;

$dx-treeview-root-items-container-spacing-x: 0.25rem !default;
$dx-treeview-root-items-container-spacing-y: 0.125rem !default;

$dx-treeview-navigation-filter-bottom-spacing: 0.75rem !default;
$dx-treeview-navigation-filter-bottom-spacing-sm: 0.5rem !default;
$dx-treeview-navigation-filter-bottom-spacing-lg: 1rem !default;

$dx-treeview-check-all-bottom-spacing: 0.25rem !default;
$dx-treeview-check-all-left-spacing: $dx-size !default;
$dx-treeview-check-all-left-spacing-sm: $dx-size-sm !default;
$dx-treeview-check-all-left-spacing-lg: $dx-size-lg !default;
$dx-treeview-check-all-border-color: unset !default;
$dx-treeview-check-all-border-bottom-color: $dx-border-color !default;
$dx-treeview-check-all-border-bottom-width: $dx-border-width !default;
$dx-treeview-check-all-border-bottom-style: solid !default;
$dx-treeview-check-all-focused-shadow-color: transparent !default;

$dx-treeview-item-text-wrap: normal !default;
$dx-treeview-item-hover-color: inherit !default;
$dx-treeview-item-hover-bg: unset !default;
$dx-treeview-item-selection-bg: unset !default;
$dx-treeview-item-selection-color: $dx-white !default;
$dx-treeview-item-spacing-y: 0.375rem !default; // values match with image-spacing -> add common variable for spacing
$dx-treeview-item-spacing-y-sm: 0.25rem !default;
$dx-treeview-item-spacing-y-lg: 0.5rem !default;
$dx-treeview-item-checkbox-margin: $dx-checkbox-check-element-margin-y !default;
$dx-treeview-item-checkbox-margin-sm: $dx-checkbox-check-element-margin-y-sm !default;
$dx-treeview-item-checkbox-margin-lg: $dx-checkbox-check-element-margin-y-lg !default;
$dx-treeview-checkbox-checked-bg: unset !default;
$dx-treeview-checkbox-checked-hover-bg: unset !default;
$dx-treeview-checkbox-disabled-bg: unset !default;
$dx-treeview-item-checkbox-checked-bg: $dx-primary !default;
$dx-treeview-item-checked-checkbox-opacity: 80% !default;
$dx-treeview-item-checked-disabled-checkbox-opacity: 40% !default;
$dx-treeview-item-checkbox-checked-color: $dx-checkbox-check-element-checked-color !default;
$dx-treeview-item-checkbox-checked-hover-bg: $dx-checkbox-check-element-checked-bg !default;
$dx-treeview-item-checkbox-disabled-bg: $dx-checkbox-base-color !default;
$dx-treeview-item-checkbox-disabled-color: $dx-checkbox-check-element-disabled-color !default;
$dx-treeview-item-checkbox-unchecked-bg: $dx-checkbox-check-element-unchecked-bg !default;
$dx-treeview-item-checkbox-unchecked-hover-bg: $dx-checkbox-check-element-unchecked-hover-bg !default;
$dx-treeview-item-checkbox-unchecked-hover-border-color: $dx-checkbox-check-element-unchecked-hover-border-color !default;
$dx-treeview-item-checkbox-unchecked-border-width: $dx-checkbox-check-element-unchecked-border-width !default;

$dx-treeview-item-content-container-border-radius: 0.25rem !default;
$dx-treeview-item-content-container-border-width: 0.125rem !default;
$dx-treeview-item-content-container-focus-shadow-color: $dx-primary !default;
$dx-treeview-item-content-indent: math.div($dx-size, 2) !default;
$dx-treeview-item-content-indent-sm: math.div($dx-size-sm, 2) !default;
$dx-treeview-item-content-indent-lg: math.div($dx-size-lg, 2) !default;
$dx-treeview-item-selected-base-bg: $dx-primary !default;
$dx-treeview-item-selected-bg-alpha: $dx-state-hover-opacity !default;
$dx-treeview-item-selected-focused-bg-alpha: 20% !default;
$dx-treeview-item-selected-focused-color: unset !default;

$dx-treeview-empty-data-padding-x: 1rem !default;
$dx-treeview-empty-data-padding-y: 1rem !default;
$dx-treeview-empty-data-padding-x-sm: 0.75rem !default;
$dx-treeview-empty-data-padding-y-sm: 0.75rem !default;
$dx-treeview-empty-data-padding-x-lg: 1.5rem !default;
$dx-treeview-empty-data-padding-y-lg: 1.5rem !default;

$dx-treeview-item-focus-outline-color: $dx-focus !default;
$dx-treeview-item-focus-outline-width: $dx-focus-outline-width !default;
$dx-treeview-item-focus-outline-offset: -2px !default;

$dx-treeview-checkbox-readonly-bg: $dx-bg !default;
$dx-treeview-checkbox-readonly-border-color: unset !default;
$dx-treeview-checkbox-readonly-border-width: 2px !default;

// BadgeText
$dx-navigation-badge-spacing: $dx-image-spacing !default;
$dx-navigation-badge-spacing-sm: $dx-image-spacing-sm !default;
$dx-navigation-badge-spacing-lg: $dx-image-spacing-lg !default;
$dx-navigation-badge-font-size: $dx-font-size !default;
$dx-navigation-badge-font-size-sm: $dx-font-size-sm !default;
$dx-navigation-badge-font-size-lg: $dx-font-size-lg !default;

// redo after fix sizing variables
$dx-navigation-badge-line-height: $dx-navigation-item-line-height !default;
$dx-navigation-badge-line-height-sm: $dx-navigation-item-line-height-sm !default;
$dx-navigation-badge-line-height-lg: $dx-navigation-item-line-height-lg !default;

$dx-navigation-badge-font-weight: 500 !default;
$dx-navigation-badge-color: $dx-white !default;
$dx-navigation-badge-selected-color: $dx-gray-900 !default;
$dx-navigation-badge-border-radius: 0.25rem !default;
$dx-navigation-badge-text-spacing-x: $dx-image-spacing !default;
$dx-navigation-badge-text-spacing-x-sm: $dx-image-spacing-sm !default;
$dx-navigation-badge-text-spacing-x-lg: $dx-image-spacing-lg !default;
$dx-navigation-badge-secondary-bg: $dx-gray-600 !default;
$dx-navigation-badge-secondary-selected-bg: $dx-white !default;

// Wait indicator
$dx-wait-indicator-color: $dx-primary !default;
$dx-wait-indicator-no-area-color: #fff !default;
$dx-wait-indicator-area-background-color: $dx-bg !default;
$dx-wait-indicator-size-sm: 1rem !default;
$dx-wait-indicator-size: 1.25rem !default;
$dx-wait-indicator-size-lg: 1.5rem !default;
$dx-wait-indicator-flip-animation-rotation-y: dxbl-indicator-animation-flip-rotation-y;
$dx-wait-indicator-flip-animation-rotation-x: dxbl-indicator-animation-flip-rotation-x;

// Loading panel
$dx-loading-panel-width: 100%;
$dx-loading-panel-height: 100%;
$dx-loading-panel-shading-color: rgba(0 0 0 / 60%) !default;
$dx-loading-panel-underlay-bg: #fff !default;
$dx-loading-panel-overlay-bg: #fff !default;
$dx-loading-panel-overlay-opacity: 0 !default;

$dx-loading-panel-indicator-size-sm: $dx-loading-indicator-size-sm;
$dx-loading-panel-indicator-size: $dx-loading-indicator-size;
$dx-loading-panel-indicator-size-lg: $dx-loading-indicator-size-lg;

$dx-loading-panel-indicator-area-width: unset !default;
$dx-loading-panel-indicator-area-height: unset !default;
$dx-loading-panel-indicator-area-padding-x-sm: 1rem !default;
$dx-loading-panel-indicator-area-padding-x: 1.25rem !default;
$dx-loading-panel-indicator-area-padding-x-lg: 1.5rem !default;
$dx-loading-panel-indicator-area-padding-y: 1rem !default;
$dx-loading-panel-indicator-margin: 1rem !default;

$dx-loading-panel-message-size-sm: 0.75rem !default;
$dx-loading-panel-message-size: 0.875rem !default;
$dx-loading-panel-message-size-lg: 1rem !default;
$dx-loading-panel-message-space-sm: 0.5rem !default;
$dx-loading-panel-message-space: 0.75rem !default;
$dx-loading-panel-message-space-lg: 1rem !default;

// ContextMenu
$dx-context-menu-font-family: $dx-font-family !default;
$dx-context-menu-color: unset !default;
$dx-context-menu-bg: $dx-bg !default;
$dx-context-menu-border-radius: 0.25rem !default;
$dx-context-menu-border-width: $dx-border-width !default;
$dx-context-menu-border-color: $dx-border-color !default;
$dx-context-menu-border-style: $dx-border-style !default;
$dx-context-menu-item-popout-icon-opacity: $dx-image-color-alpha !default;
$dx-context-menu-disabled-bg: unset !default;
$dx-context-menu-disabled-color: $dx-color !default;
$dx-context-menu-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-context-menu-item-hover-color: inherit !default;
$dx-context-menu-item-hover-bg: unset !default;
$dx-context-menu-item-focus-outline-color: $dx-focus !default;
$dx-context-menu-item-focus-outline-width: $dx-focus-outline-width !default;
$dx-context-menu-padding-x: 0 !default;
$dx-context-menu-padding-y: 0 !default;

$dx-context-menu-item-active-base-bg: $dx-primary !default;
$dx-context-menu-item-active-bg-alpha: $dx-state-hover-opacity !default;
$dx-context-menu-item-active-bg: unset !default;
$dx-context-menu-item-active-color: $dx-white !default;
$dx-context-menu-separator-width: $dx-border-width !default;
$dx-context-menu-separator-color: $dx-border-color !default;
$dx-context-menu-separator-style: $dx-border-style !default;

$dx-context-menu-font-size: $dx-font-size !default;
$dx-context-menu-font-size-sm: $dx-font-size-sm !default;
$dx-context-menu-font-size-lg: $dx-font-size-lg !default;
$dx-context-menu-line-height: $dx-line-height !default;
$dx-context-menu-line-height-sm: $dx-line-height-sm !default;
$dx-context-menu-line-height-lg: $dx-line-height-lg !default;
$dx-context-menu-min-width: $dx-font-size * $dx-dropdown-min-width-ratio !default;
$dx-context-menu-min-width-sm: $dx-font-size-sm * $dx-dropdown-min-width-ratio !default;
$dx-context-menu-min-width-lg: $dx-font-size-lg * $dx-dropdown-min-width-ratio !default;

$dx-context-menu-image-spacing: $dx-navigation-image-spacing !default;
$dx-context-menu-image-spacing-sm: $dx-navigation-image-spacing-sm !default;
$dx-context-menu-image-spacing-lg: $dx-navigation-image-spacing-lg !default;
$dx-context-menu-item-padding-x: $dx-navigation-image-spacing !default;
$dx-context-menu-item-padding-x-sm: $dx-navigation-image-spacing-sm !default;
$dx-context-menu-item-padding-x-lg: $dx-navigation-image-spacing-lg !default;
$dx-context-menu-item-padding-y: $dx-navigation-item-padding-y !default;
$dx-context-menu-item-padding-y-sm: $dx-navigation-item-padding-y-sm !default;
$dx-context-menu-item-padding-y-lg: $dx-navigation-item-padding-y-lg !default;
$dx-context-menu-item-text-padding-x: $dx-navigation-image-spacing + $dx-image-size !default;
$dx-context-menu-item-text-padding-x-sm: $dx-navigation-image-spacing-sm + $dx-image-size-sm !default;
$dx-context-menu-item-text-padding-x-lg: $dx-navigation-image-spacing-lg + $dx-image-size-lg !default;

// Menu
$dx-menu-font-family: $dx-font-family !default;
$dx-menu-item-font-size: 1rem !default;
$dx-menu-item-font-size-sm: 0.875rem !default;
$dx-menu-item-font-size-lg: 1.25rem !default;
$dx-menu-item-line-height: 1.25 !default;
$dx-menu-item-line-height-sm: 1.143 !default;
$dx-menu-item-line-height-lg: 1.2 !default;
$dx-menu-color: unset !default;
$dx-menu-bg: $dx-bg !default;
$dx-menu-item-hover-color: inherit !default;
$dx-menu-item-hover-bg: unset !default;
$dx-menu-separator-width: $dx-border-width !default;
$dx-menu-separator-color: $dx-border-color !default;
$dx-menu-separator-style: $dx-border-style !default;
$dx-menu-top-left-border-radius: $dx-border-radius !default;
$dx-menu-top-right-border-radius: $dx-border-radius !default;
$dx-menu-bottom-left-border-radius: $dx-border-radius !default;
$dx-menu-bottom-right-border-radius: $dx-border-radius !default;
$dx-menu-border-width: 0 !default;
$dx-menu-border-color: unset !default;
$dx-menu-border-style: unset !default;
$dx-menu-item-selected-bg: unset !default;
$dx-menu-item-selected-color: unset !default;
$dx-menu-item-selected-hover-bg: unset !default;
$dx-menu-item-selected-hover-color: unset !default;
$dx-menu-item-disabled-bg: unset !default;
$dx-menu-item-disabled-color: $dx-color !default;
$dx-menu-item-disabled-opacity: $dx-state-disabled-opacity !default;
$dx-menu-item-image-size: $dx-image-size !default;
$dx-menu-item-image-size-sm: $dx-image-size-sm !default;
$dx-menu-item-image-size-lg: $dx-image-size-lg !default;
$dx-menu-item-focus-outline-color: $dx-focus !default;
$dx-menu-item-focus-outline-width: $dx-focus-outline-width !default;
$dx-menu-item-focus-outline-offset: 1px !default;

$dx-menu-item-text-size: $dx-menu-item-font-size * $dx-menu-item-line-height !default; // 20px
$dx-menu-item-text-size-sm: $dx-menu-item-font-size-sm * $dx-menu-item-line-height-sm !default; // 16px
$dx-menu-item-text-size-lg: $dx-menu-item-font-size-lg * $dx-menu-item-line-height-lg !default; // 24px
$dx-menu-item-padding-x: 1rem !default;
$dx-menu-item-padding-x-sm: 0.75rem !default;
$dx-menu-item-padding-x-lg: 1.25rem !default;
$dx-menu-item-padding-y: math.div($dx-panel-header-size - $dx-menu-item-text-size, 2) !default;
$dx-menu-item-padding-y-sm: math.div($dx-panel-header-size-sm - $dx-menu-item-text-size-sm, 2) !default;
$dx-menu-item-padding-y-lg: math.div($dx-panel-header-size-lg - $dx-menu-item-text-size-lg, 2) !default;
$dx-menu-item-image-spacing: 0.75rem !default;
$dx-menu-item-image-spacing-sm: 0.625rem !default;
$dx-menu-item-image-spacing-lg: 0.875rem !default;

$dx-menu-title-font-weight: 500 !default;
$dx-menu-title-font-size: 1.25rem !default;
$dx-menu-title-font-size-sm: 1rem !default;
$dx-menu-title-font-size-lg: 1.5rem !default;
$dx-menu-title-line-height: 1 !default;
$dx-menu-title-padding-x: $dx-menu-item-padding-x !default;
$dx-menu-title-padding-x-sm: $dx-menu-item-padding-x-sm !default;
$dx-menu-title-padding-x-lg: $dx-menu-item-padding-x-lg !default;
$dx-menu-title-padding-y: $dx-menu-item-padding-y !default;
$dx-menu-title-padding-y-sm: $dx-menu-item-padding-y-sm !default;
$dx-menu-title-padding-y-lg: $dx-menu-item-padding-y-lg !default;

$dx-menu-hamburger-btn-padding-x: $dx-menu-item-padding-x !default;
$dx-menu-hamburger-btn-padding-x-sm: $dx-menu-item-padding-x-sm !default;
$dx-menu-hamburger-btn-padding-x-lg: $dx-menu-item-padding-x-lg !default;
$dx-menu-hamburger-btn-padding-y: math.div($dx-panel-header-size - $dx-image-size, 2) !default;
$dx-menu-hamburger-btn-padding-y-sm: math.div($dx-panel-header-size-sm - $dx-image-size-sm, 2) !default;
$dx-menu-hamburger-btn-padding-y-lg: math.div($dx-panel-header-size-lg - $dx-image-size-lg, 2) !default;

$dx-menu-vertical-item-font-size: $dx-font-size !default;
$dx-menu-vertical-item-font-size-sm: $dx-font-size-sm !default;
$dx-menu-vertical-item-font-size-lg: $dx-font-size-lg !default;
$dx-menu-vertical-item-line-height: $dx-line-height !default;
$dx-menu-vertical-item-line-height-sm: $dx-line-height-sm !default;
$dx-menu-vertical-item-line-height-lg: $dx-line-height-lg !default;
$dx-menu-vertical-item-image-spacing: $dx-navigation-image-spacing !default;
$dx-menu-vertical-item-image-spacing-sm: $dx-navigation-image-spacing-sm !default;
$dx-menu-vertical-item-image-spacing-lg: $dx-navigation-image-spacing-lg !default;
$dx-menu-vertical-item-padding-x: $dx-navigation-image-spacing !default;
$dx-menu-vertical-item-padding-x-sm: $dx-navigation-image-spacing-sm !default;
$dx-menu-vertical-item-padding-x-lg: $dx-navigation-image-spacing-lg !default;
$dx-menu-vertical-item-padding-y: $dx-navigation-item-padding-y !default;
$dx-menu-vertical-item-padding-y-sm: $dx-navigation-item-padding-y-sm !default;
$dx-menu-vertical-item-padding-y-lg: $dx-navigation-item-padding-y-lg !default;

$dx-menu-dropdown-item-font-size: $dx-font-size !default;
$dx-menu-dropdown-item-font-size-sm: $dx-font-size-sm !default;
$dx-menu-dropdown-item-font-size-lg: $dx-font-size-lg !default;
$dx-menu-dropdown-item-line-height: $dx-line-height !default;
$dx-menu-dropdown-item-line-height-sm: $dx-line-height-sm !default;
$dx-menu-dropdown-item-line-height-lg: $dx-line-height-lg !default;
$dx-menu-dropdown-item-image-spacing: $dx-navigation-image-spacing !default;
$dx-menu-dropdown-item-image-spacing-sm: $dx-navigation-image-spacing-sm !default;
$dx-menu-dropdown-item-image-spacing-lg: $dx-navigation-image-spacing-lg !default;
$dx-menu-dropdown-item-padding-x: $dx-navigation-image-spacing !default;
$dx-menu-dropdown-item-padding-x-sm: $dx-navigation-image-spacing-sm !default;
$dx-menu-dropdown-item-padding-x-lg: $dx-navigation-image-spacing-lg !default;
$dx-menu-dropdown-item-padding-y: $dx-navigation-item-padding-y !default;
$dx-menu-dropdown-item-padding-y-sm: $dx-navigation-item-padding-y-sm !default;
$dx-menu-dropdown-item-padding-y-lg: $dx-navigation-item-padding-y-lg !default;
$dx-menu-dropdown-padding-x: 0 !default;
$dx-menu-dropdown-padding-y: 0 !default;
$dx-menu-dropdown-min-width: $dx-font-size * $dx-dropdown-min-width-ratio !default;
$dx-menu-dropdown-min-width-sm: $dx-font-size-sm * $dx-dropdown-min-width-ratio !default;
$dx-menu-dropdown-min-width-lg: $dx-font-size-lg * $dx-dropdown-min-width-ratio !default;
$dx-menu-dropdown-border-radius: 0.25rem !default;
$dx-menu-dropdown-border-width: $dx-border-width !default;
$dx-menu-dropdown-border-color: $dx-border-color !default;
$dx-menu-dropdown-border-style: $dx-border-style !default;
$dx-menu-dropdown-item-focus-outline-color: $dx-focus !default;
$dx-menu-dropdown-item-focus-outline-width: $dx-focus-outline-width !default;

// Charts
$dx-chart-legend-bg: $dx-white !default;
$dx-chart-legend-border: $dx-border-width solid $dx-border-color !default;
$dx-chart-legend-border-radius: $dx-border-radius !default;
$dx-chart-legend-box-shadow: none !default;
$dx-chart-focus-outline-color: $dx-primary !default;
$dx-chart-focus-outline-width: $dx-focus-shadow-size !default;
$dx-chart-focus-outline-style: solid !default;
$dx-chart-axis-title-font-color: #767676 !default;
$dx-chart-constant-line-color: #000 !default;
$dx-chart-axis-line-color: #767676 !default;
$dx-chart-point-hover-color: $dx-bg !default;
$dx-chart-error-bar-color: #000 !default;
$dx-chart-annotation-color: $dx-bg !default;
$dx-chart-annotation-text-color: #333 !default;

// RichEdit
$dx-rich-edit-bg: $dx-white !default;
$dx-rich-edit-view-bg: $dx-gray-100 !default;
$dx-rich-edit-border-width: $dx-border-width !default;
$dx-rich-edit-border-color: $dx-border-color !default;
$dx-rich-edit-border-radius: $dx-border-radius !default;
$dx-rich-edit-quick-search-border-radius: $dx-panel-border-radius !default;

// Scheduler
$dx-scheduler-font-family: $dx-font-family !default;
$dx-scheduler-font-size: $dx-font-size !default;
$dx-scheduler-border-color: $dx-border-color !default;
$dx-scheduler-border-style: $dx-border-style !default;
$dx-scheduler-border-width: $dx-border-width !default;
$dx-scheduler-background-color: $dx-bg !default;
$dx-scheduler-border-radius: $dx-border-radius !default;
$dx-scheduler-appointment-paddings-size: 0.15rem;
$dx-scheduler-flyout-content-padding-x: $dx-panel-content-padding-x !default;
$dx-scheduler-flyout-content-padding-y: $dx-panel-content-padding-y !default;
$dx-scheduler-focus-frame-color: $dx-primary !default;
$dx-scheduler-focus-frame-size: $dx-scheduler-appointment-paddings-size !default;
$dx-scheduler-toolbar-fix-height: calc(2em + 2px) !default;
$dx-scheduler-disabled-color: $dx-light !default;

// Drawer
$dx-drawer-animation-duration: 300ms !default;

$dx-drawer-content-shading-bg: $dx-popup-back-bg !default;
$dx-drawer-content-shading-opacity: $dx-popup-back-opacity !default;

$dx-drawer-separator-border-color: $dx-border-color !default;
$dx-drawer-separator-border-style: $dx-border-style !default;
$dx-drawer-separator-border-width: $dx-border-width !default;

$dx-drawer-panel-bg: $dx-bg !default;
$dx-drawer-panel-color: $dx-color !default;
$dx-drawer-panel-box-shadow-left: 0.625rem 0 0.9375rem -0.1875rem rgba(0 0 0 / 10%), 0.25rem 0 0.375rem -0.25rem rgba(0 0 0 / 10%) !default;
$dx-drawer-panel-box-shadow-right: -0.625rem 0 0.9375rem -0.1875rem rgba(0 0 0 / 10%), -0.25rem 0 0.375rem -0.25rem rgba(0 0 0 / 10%) !default;
$dx-drawer-panel-font-family: $dx-font-family !default;
$dx-drawer-panel-font-size: $dx-font-size !default;
$dx-drawer-panel-font-size-sm: $dx-font-size-sm !default;
$dx-drawer-panel-font-size-lg: $dx-font-size-lg !default;
$dx-drawer-panel-line-height: $dx-line-height !default;
$dx-drawer-panel-line-height-sm: $dx-line-height-sm !default;
$dx-drawer-panel-line-height-lg: $dx-line-height-lg !default;
$dx-drawer-panel-padding-x: 0.625rem !default; // 10px
$dx-drawer-panel-padding-y: 0.625rem !default; // 10px
$dx-drawer-panel-padding-x-sm: 0.5rem !default; // 8px
$dx-drawer-panel-padding-y-sm: 0.5rem !default; // 8px
$dx-drawer-panel-padding-x-lg: 0.75rem !default; // 12px
$dx-drawer-panel-padding-y-lg: 0.75rem !default; // 12px
$dx-drawer-panel-width: 15% !default;
$dx-drawer-panel-width-mini: 3rem !default; // 48px
$dx-drawer-panel-width-mini-sm: 2.5rem !default; // 40px
$dx-drawer-panel-width-mini-lg: 3.5rem !default; // 56px

$dx-drawer-panel-header-bg: unset !default;
$dx-drawer-panel-header-color: inherit !default;
$dx-drawer-panel-header-font-size: $dx-panel-header-font-size !default;
$dx-drawer-panel-header-font-size-sm: $dx-panel-header-font-size-sm !default;
$dx-drawer-panel-header-font-size-lg: $dx-panel-header-font-size-lg !default;
$dx-drawer-panel-header-font-weight: 500 !default;
$dx-drawer-panel-header-line-height: $dx-panel-header-line-height !default;
$dx-drawer-panel-header-line-height-sm: $dx-panel-header-line-height-sm !default;
$dx-drawer-panel-header-line-height-lg: $dx-panel-header-line-height-lg !default;
$dx-drawer-panel-header-min-height: calc(3.125rem + $dx-drawer-separator-border-width) !default;
$dx-drawer-panel-header-min-height-sm: calc(2.625rem + $dx-drawer-separator-border-width) !default;
$dx-drawer-panel-header-min-height-lg: calc(3.625rem + $dx-drawer-separator-border-width) !default;

$dx-drawer-panel-footer-bg: unset !default;
$dx-drawer-panel-footer-color: inherit !default;
$dx-drawer-panel-footer-justify-content: flex-end !default;
$dx-drawer-panel-footer-min-height: $dx-drawer-panel-header-min-height !default;
$dx-drawer-panel-footer-min-height-sm: $dx-drawer-panel-header-min-height-sm !default;
$dx-drawer-panel-footer-min-height-lg: $dx-drawer-panel-header-min-height-lg !default;

// Toast
$dx-toast-border-radius: 0.25rem !default;
$dx-toast-border-width: 0 !default;
$dx-toast-box-shadow: 0 10px 15px -3px rgba(0 0 0 / 10%), 0 4px 6px -4px rgba(0 0 0 / 10%) !default;
$dx-toast-close-btn-padding-x: 0.25rem !default;
$dx-toast-header-padding: 0.25rem 0 0 0 !default;
$dx-toast-accent-ling-width: 0.25rem !default;
$dx-toast-margin-top: 0.675rem !default;

$dx-toast-header-font-size: 1rem !default;
$dx-toast-header-font-size-sm: 0.875rem !default;
$dx-toast-header-font-size-lg: 1.25rem !default;
$dx-toast-text-line-height: 1.25rem !default;
$dx-toast-text-line-height-sm: 1rem !default;
$dx-toast-text-line-height-lg: 1.5rem !default;
$dx-toast-text-font-size: 0.875rem !default;
$dx-toast-text-font-size-sm: 0.75rem !default;
$dx-toast-text-font-size-lg: 1rem !default;
$dx-toast-header-font-weight: 500 !default;
$dx-toast-text-font-weight: 400 !default;

$dx-toast-bg-primary: $dx-primary;
$dx-toast-bg-info: $dx-info;
$dx-toast-bg-success: $dx-success;
$dx-toast-bg-warning: $dx-warning;
$dx-toast-bg-danger: $dx-danger;
$dx-toast-bg-opacity: 30 !default;

$dx-toast-font-color-dark: #495057 !default;
$dx-toast-font-color-light: #fff !default;
$dx-toast-background-color-white: $dx-white !default;
$dx-toast-background-color-dark: $dx-gray-900 !default;

$dx-toast-header-gap: 0.375rem !default;
$dx-toast-header-gap-sm: 0.25rem !default;
$dx-toast-header-gap-lg: 0.5rem !default;
$dx-toast-body-padding: 0.625rem 0.625rem 0.625rem 0.875rem !default;
$dx-toast-body-padding-sm: 0.5rem 0.5rem 0.5rem 0.75rem !default;
$dx-toast-body-padding-lg: 0.75rem 0.75rem 0.75rem 1rem !default;
$dx-toast-template-margin-bottom: 0.25rem !default;
$dx-toast-icon-top-padding: 0.25rem !default; // 0.375rem !default; - should leave this? 0.25rem fits better
$dx-toast-icon-top-padding-sm: 0.25rem !default;
$dx-toast-icon-top-padding-lg: 0.5rem !default;
$dx-toast-content-gap: 0.625rem !default;
$dx-toast-content-gap-sm: 0.5rem !default;
$dx-toast-content-gap-lg: 0.75rem !default;
$dx-toast-text-content-top-padding: 0.25rem !default;
$dx-toast-text-content-gap: 0.375rem !default;
$dx-toast-text-content-gap-sm: 0.25rem !default;
$dx-toast-text-content-gap-lg: 0.5rem !default;
$dx-toast-template-gap: 0.625rem !default;
$dx-toast-template-gap-sm: 0.5rem !default;
$dx-toast-template-gap-lg: 0.875rem !default;
$dx-toast-bound-margin: 0.625rem !default;
$dx-toast-bound-padding: 0.625rem !default;

$dx-toast-close-hover-bg-light: #e9ecef !default;
$dx-toast-close-hover-bg-gray: #ced4da !default;
$dx-toast-close-hover-bg-dark: #343a40 !default;
$dx-toast-close-btn-color-light: $dx-white;
$dx-toast-close-btn-color-dark: #212529 !default;

// Progress bar
$dx-progress-bar-thickness: 0.25rem !default;
$dx-progress-bar-thickness-sm: 0.125rem !default;
$dx-progress-bar-thickness-lg: 0.5rem !default;
$dx-progress-bar-border-radius: 0.125rem !default;
$dx-progress-bar-border-radius-sm: 0.0625rem !default;
$dx-progress-bar-border-radius-lg: 0.25rem !default;
$dx-progress-bar-color: $dx-primary;
$dx-progress-bar-success-color: $dx-success;
$dx-progress-bar-error-color: $dx-danger;
$dx-progress-bar-pause-color: $dx-warning;
$dx-progress-bar-track-color: $dx-progress-bar-color;
$dx-progress-bar-track-opacity: 0.3 !default;
$dx-progress-bar-label-space: 0.75em !default;
$dx-progress-bar-label-space-sm: 0.5rem !default;
$dx-progress-bar-label-space-lg: 1rem !default;
$dx-progress-bar-label-gap: 0.375rem !default;
$dx-progress-bar-label-gap-sm: 0.25rem !default;
$dx-progress-bar-label-gap-lg: 0.5rem !default;
$dx-progress-bar-label-line-height: 1.25rem !default;
$dx-progress-bar-label-line-height-sm: 1rem !default;
$dx-progress-bar-label-line-height-lg: 1.5rem !default;
$dx-progress-bar-label-font-size: 0.875rem !default;
$dx-progress-bar-label-font-size-sm: 0.75rem !default;
$dx-progress-bar-label-font-size-lg: 1rem !default;
$dx-progress-bar-indeterminate-animation-length: 2s !default;

// Client components common
$dx-title-font-color: #232323 !default;
$dx-legend-item-font-color: #767676 !default;

// Map
$dx-map-marker-tooltip-color: $dx-color !default;

// Sankey
$dx-sankey-label-font-color: #232323 !default;

// HtmlEditor
$dx-html-editor-block-font-color: rgba(51 51 51 / 80%) !default;

$dx-html-editor-border-width: $dx-border-width !default;
$dx-html-editor-border-style: $dx-border-style !default;
$dx-html-editor-border-color: $dx-gray-200 !default;
$dx-html-editor-border-radius: $dx-border-radius !default;

$dx-html-editor-invalid-color: #d73f3f !default;
$dx-html-editor-max-indent: 9 !default;
$dx-html-editor-list-style: decimal lower-alpha lower-roman !default;

$dx-html-editor-invalid-message-font-size: 0.85rem !default;
$dx-html-editor-invalid-message-padding-y: 0.5rem !default;
$dx-html-editor-invalid-message-padding-x: 1rem !default;

$dx-html-editor-resize-frame-border-style: dashed !default;
$dx-html-editor-resizable-handle-size: 0.3rem !default;

$dx-html-editor-content-padding-y: 0.75rem !default;
$dx-html-editor-content-padding-x: 1rem !default;

$dx-html-editor-list-inline-start: 1.5rem !default;
$dx-html-editor-list-inline-end: 0.3rem !default;
$dx-html-editor-list-width: 1.2rem !default;

$dx-html-editor-blockquote-padding-inline-start: 12px !default;
$dx-html-editor-blockquote-border-inline-start-width: 4px !default;
$dx-html-editor-blockquote-border-inline-start-style: solid !default;
$dx-html-editor-blockquote-margin-y: 0.5rem !default;
$dx-html-editor-blockquote-margin-x: 0 !default;

$dx-html-editor-code-block-background-color: rgba(191 191 191 / 15%) !default;
$dx-html-editor-code-block-padding: 0.5rem !default;

$dx-html-editor-placeholder-color: $dx-gray-900 !default;
$dx-html-editor-placeholder-offset: 15px !default;
$dx-html-editor-placeholder-opacity: 0.5 !default;

$dx-html-editor-table-padding-y: 0.125rem !default;
$dx-html-editor-table-padding-x: 0.313rem !default;
$dx-html-editor-table-resize-frame: 0.313rem !default;

$dx-html-editor-variable-padding-y: 0.25rem !default;
$dx-html-editor-variable-padding-x: 0.375rem !default;
$dx-html-editor-variable-border-radius: 0.5rem !default;
$dx-html-editor-variable-opacity: 0.1 !default;

$dx-html-editor-mentions-list-item-border-width: 2px !default;
$dx-html-editor-mentions-list-item-border-style: solid !default;
$dx-html-editor-mentions-list-item-padding-y: 0.5rem !default;
$dx-html-editor-mentions-list-item-padding-x: 0.5rem !default;
$dx-html-editor-mentions-list-background-color: rgba($dx-black, 0.5) !default;
$dx-html-editor-mentions-scrollbar-width: 8px !default;
$dx-html-editor-mentions-scrollbar-color: rgba(191 191 191 / 70%) !default;

$dx-html-editor-dialog-label-margin-bottom: 0.25rem !default;
$dx-html-editor-dialog-row-gap: 0.5rem !default;
$dx-html-editor-dialog-column-gap: 0.5rem !default;
$dx-html-editor-dialog-aspect-ratio-button-size: 28px !default;
$dx-html-editor-dialog-footer-margin-bottom: 0.75rem !default;
$dx-html-editor-dialog-footer-border-top: 0 !default;

$dx-html-editor-primary-color: $dx-primary !default;
$dx-html-editor-white-color: $dx-white !default;

$dx-html-editor-drop-zone-border-radius: 0.3rem !default;
$dx-html-editor-drop-zone-border-width: 2px !default;
$dx-html-editor-drop-zone-border-style: dashed !default;
$dx-html-editor-drop-zone-border-color: $dx-html-editor-border-color !default;
$dx-html-editor-drop-zone-background-color: $dx-light !default;
$dx-html-editor-drag-zone-drag-over-border-color: $dx-secondary !default;

// PivotGrid
$dx-pivot-grid-color: $dx-color !default;
$dx-pivot-grid-bg: $dx-bg !default;
$dx-pivot-grid-border-color: $dx-border-color !default;
$dx-pivot-grid-border-style: $dx-border-style !default;
$dx-pivot-grid-border-width: $dx-border-width !default;
$dx-pivot-grid-border-radius: $dx-border-radius !default;
$dx-pivot-grid-border-radius-sm: $dx-border-radius-sm !default;
$dx-pivot-grid-border-radius-lg: $dx-border-radius-lg !default;
$dx-pivot-grid-font-family: $dx-font-family !default;
$dx-pivot-grid-font-size: $dx-font-size !default;
$dx-pivot-grid-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-grid-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-grid-line-height: $dx-line-height !default;
$dx-pivot-grid-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-grid-line-height-lg: $dx-line-height-lg !default;
$dx-pivot-grid-min-width: $dx-grid-min-width !default;

$dx-pivot-grid-field-bg: unset !default;
$dx-pivot-grid-field-color: inherit !default;
$dx-pivot-grid-field-font-size: $dx-font-size !default;
$dx-pivot-grid-field-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-grid-field-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-grid-field-font-weight: 600 !default;
$dx-pivot-grid-field-line-height: $dx-line-height !default;
$dx-pivot-grid-field-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-grid-field-line-height-lg: $dx-line-height-lg !default;

$dx-pivot-grid-header-bg: unset !default;
$dx-pivot-grid-header-color: inherit !default;
$dx-pivot-grid-header-font-size: $dx-font-size !default;
$dx-pivot-grid-header-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-grid-header-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-grid-header-font-weight: inherit !default;
$dx-pivot-grid-header-line-height: $dx-line-height !default;
$dx-pivot-grid-header-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-grid-header-line-height-lg: $dx-line-height-lg !default;

$dx-pivot-grid-total-bg: color-mix(in srgb, $dx-panel-base-bg $dx-panel-footer-bg-alpha * 2, transparent) !default;
$dx-pivot-grid-total-color: inherit !default;
$dx-pivot-grid-total-font-size: $dx-font-size !default;
$dx-pivot-grid-total-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-grid-total-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-grid-total-font-weight: inherit !default;
$dx-pivot-grid-total-line-height: $dx-line-height !default;
$dx-pivot-grid-total-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-grid-total-line-height-lg: $dx-line-height-lg !default;

// Pivot Table
$dx-pivot-table-default-height: 400px !default;
$dx-pivot-table-color: $dx-color !default;
$dx-pivot-table-bg: $dx-bg !default;
$dx-pivot-table-border-color: $dx-border-color !default;
$dx-pivot-table-border-style: $dx-border-style !default;
$dx-pivot-table-border-width: $dx-border-width !default;
$dx-pivot-table-border-radius: $dx-border-radius !default;
$dx-pivot-table-border-radius-sm: $dx-border-radius-sm !default;
$dx-pivot-table-border-radius-lg: $dx-border-radius-lg !default;
$dx-pivot-table-cell-padding-x-sm: 0.375em !default;
$dx-pivot-table-cell-padding-y-sm: 0.375em !default;
$dx-pivot-table-cell-padding-x: 0.5em !default;
$dx-pivot-table-cell-padding-y: 0.5em !default;
$dx-pivot-table-cell-padding-x-lg: 0.625em !default;
$dx-pivot-table-cell-padding-y-lg: 0.625em !default;
$dx-pivot-table-fixed-column-border-color: $dx-gray-600 !default;
$dx-pivot-table-font-family: $dx-font-family !default;
$dx-pivot-table-font-size: $dx-font-size !default;
$dx-pivot-table-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-table-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-table-line-height: $dx-line-height !default;
$dx-pivot-table-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-table-line-height-lg: $dx-line-height-lg !default;
$dx-pivot-table-min-width: $dx-grid-min-width !default;
$dx-pivot-table-header-field-bg: unset !default;
$dx-pivot-table-header-field-color: inherit !default;
$dx-pivot-table-header-field-font-size: $dx-font-size !default;
$dx-pivot-table-header-field-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-table-header-field-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-table-header-field-font-weight: 600 !default;
$dx-pivot-table-header-field-line-height: $dx-line-height !default;
$dx-pivot-table-header-field-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-table-header-field-line-height-lg: $dx-line-height-lg !default;
$dx-pivot-table-header-field-icon-min-width: $dx-image-size !default;
$dx-pivot-table-header-field-icon-min-width-sm: $dx-image-size-sm !default;
$dx-pivot-table-header-field-icon-min-width-lg: $dx-image-size-lg !default;
$dx-pivot-table-row-field-font-size: $dx-font-size !default;
$dx-pivot-table-row-field-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-table-row-field-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-table-row-field-font-weight: inherit !default;
$dx-pivot-table-row-field-line-height: $dx-line-height !default;
$dx-pivot-table-row-field-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-table-row-field-line-height-lg: $dx-line-height-lg !default;
$dx-pivot-table-total-bg: unset !default;
$dx-pivot-table-total-color: inherit !default;
$dx-pivot-table-total-font-size: $dx-font-size !default;
$dx-pivot-table-total-font-size-sm: $dx-font-size-sm !default;
$dx-pivot-table-total-font-size-lg: $dx-font-size-lg !default;
$dx-pivot-table-total-font-weight: inherit !default;
$dx-pivot-table-total-line-height: $dx-line-height !default;
$dx-pivot-table-total-line-height-sm: $dx-line-height-sm !default;
$dx-pivot-table-total-line-height-lg: $dx-line-height-lg !default;

// Upload
$dx-upload-padding-top: 1.2rem;
$dx-upload-button-container-height: 3rem;
$dx-upload-load-state-font-size: 0.75rem;
$dx-upload-load-state-color: $dx-gray-600 !default;
$dx-upload-image-spacing: $dx-image-spacing !default;
$dx-upload-file-size-color: $dx-gray-500 !default;
$dx-upload-file-size-padding-left: 0.75rem;
$dx-upload-file-icon-padding: 12px 8px 12px 10px;
$dx-upload-file-btn-padding: 0.5rem;
$dx-upload-file-font-weight: 400 !default;
$dx-upload-file-height: 40px;
$dx-upload-file-list-view-padding-top: 1.375rem;
$dx-upload-file-margin-bottom: 0.85rem;
$dx-upload-max-file-warning-border-radius: 0.5rem;
$dx-upload-max-file-warning-icon-margin: 0.625rem 0 0;
$dx-upload-max-file-warning-message-margin: 0.5rem;

// ChatUI
$dx-chatui-messagelist-bg: $dx-gray-100 !default;
$dx-chatui-messagelist-gap: 0.75rem !default;
$dx-chatui-messagelist-padding: $dx-chatui-messagelist-gap * 2 !default;
$dx-chatui-message-padding: $dx-chatui-messagelist-gap !default;
$dx-chatui-message-color: $dx-color !default;
$dx-chatui-message-gap: 0.5rem !default;
$dx-chatui-message-font-size: 0.875rem !default;
$dx-chatui-message-font-size-sm: 0.75rem !default;
$dx-chatui-message-font-size-lg: 1rem !default;
$dx-chatui-message-border-radius: 0.25rem !default;
$dx-chatui-message-assistant-bg: $dx-bg !default;
$dx-chatui-message-user-bg: $dx-gen-purple-100 !default;
$dx-chatui-message-user-color: $dx-color !default;
$dx-chatui-message-error-bg: $dx-gen-red-100 !default;
$dx-chatui-submitarea-gap: 0.5rem !default;
$dx-chatui-submitarea-padding: 0.75rem !default;
$dx-chatui-submitarea-bg: $dx-bg !default;
$dx-chatui-empty-icon-bg: $dx-primary !default;

// MessageBox
$dx-message-box-border-radius: 0.25rem !default;
$dx-message-box-width: 37.5rem !default;
$dx-message-box-width-sm: 25rem !default;
$dx-message-box-width-lg: 50rem !default;
$dx-message-box-background-light: $dx-white !default;
$dx-message-box-background-dark: $dx-dark !default;
$dx-message-box-font-color-light: $dx-white !default;
$dx-message-box-font-color-dark: $dx-gray-700 !default;

$dx-message-box-modal-content-padding: 0.625rem 0.875rem 0.625rem 0.875rem !default;
$dx-message-box-modal-content-padding-sm: 0.5rem 0.75rem 0.5rem 0.75rem !default;
$dx-message-box-modal-content-padding-lg: 0.75rem 1rem 0.75rem 1rem !default;
$dx-message-box-modal-content-gap: 0.625rem !default;
$dx-message-box-modal-content-gap-sm: 0.5rem !default;
$dx-message-box-modal-content-gap-lg: 0.75rem !default;

$dx-message-box-header-icon-color-primary: $dx-primary;
$dx-message-box-header-icon-color-success: $dx-success;
$dx-message-box-header-icon-color-warning: $dx-warning;
$dx-message-box-header-icon-color-danger: $dx-danger;
$dx-message-box-header-close-button-color-light: $dx-gray-100;
$dx-message-box-header-close-button-color-dark: $dx-gray-700;
$dx-message-box-header-padding: 0.375rem 0.625rem 0.375rem 0.625rem !default;
$dx-message-box-header-padding-sm: 0.25rem 0.5rem 0.25rem 0.5rem !default;
$dx-message-box-header-padding-lg: 0.5rem 0.75rem 0.5rem 0.75rem !default;
$dx-message-box-header-gap: 0.625rem !default;
$dx-message-box-header-gap-sm: 0.5rem !default;
$dx-message-box-header-gap-lg: 0.75rem !default;
$dx-message-box-header-font-size: 1rem !default;
$dx-message-box-header-font-size-sm: 0.875rem !default;
$dx-message-box-header-font-size-lg: 1.25rem !default;
$dx-message-box-header-font-weight: 500 !default;
$dx-message-box-header-line-height: 1.25rem !default;
$dx-message-box-header-line-height-sm: 1rem !default;
$dx-message-box-header-line-height-lg: 1.5rem !default;
$dx-message-box-header-icon-width: 1rem !default;
$dx-message-box-header-icon-height: 1rem !default;
$dx-message-box-header-close-button-width: 1.625 !default;
$dx-message-box-header-close-button-height: 1.625rem !default;
$dx-message-box-header-close-button-padding: 0.25rem !default;
$dx-message-box-header-close-icon-width: 0.875rem !default;
$dx-message-box-header-close-icon-height: 0.875rem !default;

$dx-message-box-body-padding: 0.625rem !default;
$dx-message-box-body-padding-sm: 0.5rem !default;
$dx-message-box-body-padding-lg: 0.75rem !default;
$dx-message-box-body-text-font-size: 0.875rem !default;
$dx-message-box-body-text-font-size-sm: 0.75rem !default;
$dx-message-box-body-text-font-size-lg: 1rem !default;
$dx-message-box-body-text-line-height: 1.25rem !default;
$dx-message-box-body-text-line-height-sm: 1rem !default;
$dx-message-box-body-text-line-height-lg: 1.5rem !default;
$dx-message-box-body-text-font-weight: 400 !default;

$dx-message-box-footer-padding: 0.625rem !default;
$dx-message-box-footer-padding-sm: 0.5rem !default;
$dx-message-box-footer-padding-lg: 0.75rem !default;
$dx-message-box-footer-gap: 0.625rem !default;
$dx-message-box-footer-gap-sm: 0.5rem !default;
$dx-message-box-footer-gap-lg: 0.75rem !default;
$dx-message-box-footer-secondary-button-color-dark: #495057 !default;
$dx-message-box-footer-secondary-button-color-light: #f8f9fa !default;
$dx-message-box-footer-secondary-button-hover-color-dark: $dx-gray-200 !default;
$dx-message-box-footer-secondary-button-hover-color-light: $dx-gray-800 !default;

// Carousel
$dx-carousel-background-color: $dx-light !default;
$dx-carousel-button-border-radius: 0.25rem !default;
$dx-carousel-button-border-width: 0 !default;
$dx-carousel-button-background: $dx-bg !default;
$dx-carousel-button-background-opacity: 0.8 !default;
$dx-carousel-button-background-hover: $dx-gray-100 !default;
$dx-carousel-button-background-opacity-hover: 1 !default;
$dx-carousel-button-background-disabled: $dx-gray-200 !default;
$dx-carousel-button-background-opacity-disabled: 0.2 !default;
$dx-carousel-button-icon-color: $dx-black !default;
$dx-carousel-button-icon-color-hover: $dx-primary !default;
$dx-carousel-button-icon-color-disabled: $dx-gray-500 !default;

$dx-carousel-button-padding-lg: 0.5rem !default;
$dx-carousel-button-padding-sm: 0.25rem !default;
$dx-carousel-button-padding: 0.375rem !default;
$dx-carousel-button-icon-size-lg: 1.5rem !default;
$dx-carousel-button-icon-size-sm: 1rem !default;
$dx-carousel-button-icon-size: 1.25rem !default;

$dx-carousel-pages-padding-y: 0.125rem !default;
$dx-carousel-pages-padding-x: 0.375rem !default;
$dx-carousel-pages-padding-y-sm: 0.125rem !default;
$dx-carousel-pages-padding-x-sm: 0.25rem !default;
$dx-carousel-pages-padding-y-lg: 0.25rem !default;
$dx-carousel-pages-padding-x-lg: 0.5rem !default;

$dx-carousel-pages-background-color: $dx-bg !default;
$dx-carousel-pages-background-opacity: 0.8 !default;
$dx-carousel-pages-background-border-radius: 0.5rem !default;
$dx-carousel-page-size: 0.5rem !default;
$dx-carousel-page-size-lg: 0.625rem !default;
$dx-carousel-page-size-sm: 0.375rem !default;
$dx-carousel-page-background-color: $dx-black !default;
$dx-carousel-page-margin: 0.375rem !default;
$dx-carousel-page-opacity: 0.65 !default;
$dx-carousel-page-hover-opacity: 0.75 !default;
$dx-carousel-page-hover-opacity-active: 1 !default;
$dx-carousel-page-active-background-color: $dx-primary !default;

$dx-carousel-overlay-color: $dx-bg !default;
$dx-carousel-overlay-opacity: 0.8 !default;
$dx-carousel-focus-outline-width: $dx-focus-outline-width !default;
$dx-carousel-focus-outline-color: $dx-focus !default;

$dx-carousel-margin: 1.5rem !default;
$dx-carousel-margin-sm: 1rem !default;
$dx-carousel-margin-lg: 2rem !default;

// RangeSelector
$dx-range-selector-scale-tick-color: #767676 !default;
$dx-range-selector-scale-label-font-color: $dx-gray-900 !default;
$dx-range-selector-selected-range-color: $dx-primary !default;
$dx-range-selector-slider-marker-color: $dx-primary !default;
$dx-range-selector-slider-marker-font-color: $dx-light !default;
$dx-range-selector-slider-handle-color: $dx-primary !default;
$dx-range-selector-shutter-color: $dx-light !default;
$dx-range-selector-container-background-color: $dx-bg !default;

// Splitter
$dx-splitter-bg: $dx-bg !default;
$dx-splitter-color: $dx-color !default;
$dx-splitter-font-family: $dx-font-family !default;
$dx-splitter-font-size: $dx-font-size !default;
$dx-splitter-font-size-sm: $dx-font-size-sm !default;
$dx-splitter-font-size-lg: $dx-font-size-lg !default;
$dx-splitter-line-height: $dx-line-height !default;
$dx-splitter-line-height-sm: $dx-line-height-sm !default;
$dx-splitter-line-height-lg: $dx-line-height-lg !default;

$dx-splitter-button-color: $dx-color !default;
$dx-splitter-button-hover-color: $dx-splitter-button-color !default;
$dx-splitter-button-focused-color: $dx-state-primary-color !default;
$dx-splitter-button-active-color: $dx-splitter-button-focused-color !default;
$dx-splitter-button-padding-cross: 0.25rem !default;
$dx-splitter-button-padding-cross-sm: 0.25rem !default;
$dx-splitter-button-padding-cross-lg: 0.375rem !default;
$dx-splitter-button-padding-main: 0.25rem !default;
$dx-splitter-button-padding-main-sm: 0.25rem !default;
$dx-splitter-button-padding-main-lg: 0.375rem !default;

$dx-splitter-button-image-size: $dx-image-size !default;
$dx-splitter-button-image-size-sm: $dx-image-size-sm !default;
$dx-splitter-button-image-size-lg: $dx-image-size-lg !default;

$dx-splitter-pane-padding-x: 0.75rem !default;
$dx-splitter-pane-padding-x-sm: 0.5rem !default;
$dx-splitter-pane-padding-x-lg: 1rem !default;
$dx-splitter-pane-padding-y: 0.75rem !default;
$dx-splitter-pane-padding-y-sm: 0.5rem !default;
$dx-splitter-pane-padding-y-lg: 1rem !default;

$dx-splitter-resize-handle-border-radius: 0 !default;
$dx-splitter-resize-handle-color: $dx-splitter-button-color !default;
$dx-splitter-resize-handle-hover-color: $dx-splitter-button-hover-color !default;
$dx-splitter-resize-handle-focused-color: $dx-splitter-button-focused-color !default;
$dx-splitter-resize-handle-active-color: $dx-splitter-button-active-color !default;
$dx-splitter-resize-handle-padding-cross: 0.25rem !default;
$dx-splitter-resize-handle-padding-cross-sm: 0.25rem !default;
$dx-splitter-resize-handle-padding-cross-lg: 0.375rem !default;
$dx-splitter-resize-handle-padding-main: 0.25rem !default;
$dx-splitter-resize-handle-padding-main-sm: 0.25rem !default;
$dx-splitter-resize-handle-padding-main-lg: 0.375rem !default;
$dx-splitter-resize-handle-size-cross: 0.25rem !default;
$dx-splitter-resize-handle-size-cross-sm: 0.125rem !default;
$dx-splitter-resize-handle-size-cross-lg: 0.25rem !default;
$dx-splitter-resize-handle-size-main: 2rem !default;
$dx-splitter-resize-handle-size-main-sm: 1.5rem !default;
$dx-splitter-resize-handle-size-main-lg: 2.5rem !default;

$dx-splitter-separator-bg: color-mix(in srgb, $dx-bg 80%, $dx-border-color) !default;
$dx-splitter-separator-hover-bg: color-mix(in srgb, $dx-splitter-separator-bg $dx-state-hover-opacity, $dx-border-color) !default;
$dx-splitter-separator-focused-bg: $dx-state-primary-bg !default;
$dx-splitter-separator-active-bg: $dx-splitter-separator-focused-bg !default;
$dx-splitter-separator-border-radius: 0 !default;
$dx-splitter-separator-hover-border-radius: 0 !default;
$dx-splitter-separator-focused-border-radius: 0 !default;
$dx-splitter-separator-active-border-radius: 0 !default;
$dx-splitter-separator-gap: 0.125rem !default;
$dx-splitter-separator-gap-sm: 0 !default;
$dx-splitter-separator-gap-lg: 0.125rem !default;
$dx-splitter-separator-size: 0.625rem !default;
$dx-splitter-separator-size-sm: 0.5rem !default;
$dx-splitter-separator-size-lg: 0.75rem !default;
$dx-splitter-separator-static-size: 0.375rem !default;
$dx-splitter-separator-static-size-sm: 0.25rem !default;
$dx-splitter-separator-static-size-lg: 0.5rem !default;
