@import "mixins/common";
@import "mixins/color-palette";

.dxbl-color-palette {
    --dxbl-color-palette-text-color: #{$dx-color-palette-text-color};
    --dxbl-color-palette-font-family: #{$dx-color-palette-font-family};
    --dxbl-color-palette-font-size: #{$dx-color-palette-font-size};
    --dxbl-color-palette-line-height: #{$dx-color-palette-line-height};
    --dxbl-color-palette-bg: #{$dx-color-palette-bg};
    --dxbl-color-palette-outline-focused: #{$dx-color-palette-outline-focused};
    --dxbl-color-palette-padding: #{$dx-color-palette-padding};
    --dxbl-color-palette-border: #{$dx-color-palette-border};
    --dxbl-color-palette-border-radius: #{$dx-color-palette-border-radius};
    --dxbl-color-palette-header-height: #{$dx-color-palette-header-height};
    --dxbl-color-palette-header-padding: #{$dx-color-palette-header-padding};
    --dxbl-color-palette-separator: #{$dx-color-palette-separator};
    --dxbl-color-palette-table-margin: #{$dx-color-palette-table-margin};
    --dxbl-color-palette-table-spacing: #{$dx-color-palette-table-spacing};
    --dxbl-color-palette-no-color-padding: #{$dx-color-palette-no-color-padding};
    --dxbl-color-palette-no-color-hover-bg: #{$dx-color-palette-no-color-hover-bg};
    --dxbl-color-palette-no-color-hover-color: #{$dx-color-palette-no-color-hover-color};
    --dxbl-color-palette-no-color-spacing: #{$dx-color-palette-no-color-spacing};
    --dxbl-color-palette-no-color-disabled-opacity: #{$dx-color-palette-no-color-disabled-opacity};
    --dxbl-color-palette-no-color-outline-offset-focused: #{$dx-color-palette-no-color-outline-offset-focused};
    --dxbl-color-palette-no-color-separator-height: #{$dx-color-palette-no-color-separator-height};
    --dxbl-color-palette-color-tile-size: #{$dx-color-palette-color-tile-size};
    --dxbl-color-palette-color-tile-outline: #{$dx-color-palette-color-tile-outline};
    --dxbl-color-palette-color-tile-outline-size-state: #{$dx-color-palette-color-tile-outline-size-state};
    --dxbl-color-palette-color-tile-outline-offset-focused: #{$dx-color-palette-color-tile-outline-offset-focused};
    --dxbl-color-palette-color-tile-border-radius: #{$dx-color-palette-color-tile-border-radius};
    --dxbl-color-palette-color-tile-hover-border: #{$dx-color-palette-color-tile-hover-border};
    --dxbl-color-palette-color-tile-outline-selected-color: #{$dx-color-palette-color-tile-outline-selected-color};
    --dxbl-color-palette-color-tile-outline-disabled-color: #{$dx-color-palette-color-tile-outline-disabled-color};

    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    color: var(--dxbl-color-palette-text-color);
    font-family: var(--dxbl-color-palette-font-family);
    font-size: var(--dxbl-color-palette-font-size);
    line-height: var(--dxbl-color-palette-line-height);
    background: var(--dxbl-color-palette-bg);
    padding: var(--dxbl-color-palette-padding);
    border: var(--dxbl-color-palette-border);
    border-radius: var(--dxbl-color-palette-border-radius);
    position: relative;
    z-index: 0;

    > table {
        width: fit-content;

        > thead {
            border-bottom: var(--dxbl-color-palette-separator);

            > tr > th {
                font-size: var(--dxbl-color-palette-font-size);
                font-weight: normal;
                padding: var(--dxbl-color-palette-header-padding);
            }
        }

        > tbody {
            width: fit-content;

            > tr {
                > td {
                    padding-right: var(--dxbl-color-palette-table-spacing);
                    padding-bottom: var(--dxbl-color-palette-table-spacing);

                    &:first-child {
                        padding-left: var(--dxbl-color-palette-table-spacing);
                    }

                    > div {
                        @include color-palette-color-tile-style();

                        width: fit-content;

                        &:focus-visible {
                            outline: none;
                        }
                    }
                }

                &:first-child > td {
                    padding-top: var(--dxbl-color-palette-table-spacing);
                }
            }
        }
    }

    > div.dxbl-color-palette-no-color-separator {
        width: 100%;
        height: var(--dxbl-color-palette-no-color-separator-height);
        border-top: var(--dxbl-color-palette-separator);
    }

    > div.dxbl-color-palette-no-color-container {
        display: flex;
        flex-direction: row;
        padding: var(--dxbl-color-palette-no-color-padding);

        &:focus-visible {
            outline: none;
        }

        > div > div {
            @include color-palette-color-tile-style-base();
        }

        > span {
            margin-left: var(--dxbl-color-palette-no-color-spacing);
            line-height: var(--dxbl-color-palette-color-tile-size);
            user-select: none;
        }
    }

    &:not([data-dx-focus-hidden]) {
        > table > tbody > tr > td > div:focus-visible {
            outline: var(--dxbl-color-palette-outline-focused);
            outline-offset: var(--dxbl-color-palette-color-tile-outline-offset-focused);
            border-radius: var(--dxbl-color-palette-color-tile-border-radius);

            > div {
                outline-width: var(--dxbl-color-palette-color-tile-outline-size-state);
            }
        }

        > div.dxbl-color-palette-no-color-container:focus-visible {
            outline: var(--dxbl-color-palette-outline-focused);
            outline-offset: var(--dxbl-color-palette-no-color-outline-offset-focused);
        }
    }

    @include readonly-state() {
        > table > tbody > tr > td > div > div {
            cursor: default;
        }

        > div.dxbl-color-palette-no-color-container {
            cursor: default;
        }
    }

    @include not-disabled-state() {
        > div.dxbl-color-palette-no-color-container:hover {
            @include state-hover-style("--dxbl-color-palette-no-color-hover-bg", "--dxbl-color-palette-no-color-hover-color");
        }
    }

    @include disabled-state() {
        > table > tbody > tr > td > div {
            > div {
                cursor: not-allowed;
            }

            &:not(.dxbl-color-palette-selected-tile) > div:hover {
                border: none;
                outline: var(--dxbl-color-palette-color-tile-outline);
            }

            &.dxbl-color-palette-selected-tile > div {
                outline-color: var(--dxbl-color-palette-color-tile-outline-disabled-color);
            }
        }

        > div.dxbl-color-palette-no-color-container {
            cursor: not-allowed;

            > span {
                opacity: var(--dxbl-color-palette-no-color-disabled-opacity);
            }
        }
    }

    &.dxbl-sm {
        --dxbl-color-palette-font-size: #{$dx-color-palette-font-size-sm};
        --dxbl-color-palette-line-height: #{$dx-color-palette-line-height-sm};
        --dxbl-color-palette-padding: #{$dx-color-palette-padding-sm};
        --dxbl-color-palette-header-height: #{$dx-color-palette-header-height-sm};
        --dxbl-color-palette-header-padding: #{$dx-color-palette-header-padding-sm};
        --dxbl-color-palette-table-margin: #{$dx-color-palette-table-margin-sm};
        --dxbl-color-palette-table-spacing: #{$dx-color-palette-table-spacing-sm};
        --dxbl-color-palette-no-color-padding: #{$dx-color-palette-no-color-padding-sm};
        --dxbl-color-palette-no-color-spacing: #{$dx-color-palette-no-color-spacing-sm};
        --dxbl-color-palette-color-tile-size: #{$dx-color-palette-color-tile-size-sm};
    }

    &.dxbl-lg {
        --dxbl-color-palette-font-size: #{$dx-color-palette-font-size-lg};
        --dxbl-color-palette-line-height: #{$dx-color-palette-line-height-lg};
        --dxbl-color-palette-padding: #{$dx-color-palette-padding-lg};
        --dxbl-color-palette-header-height: #{$dx-color-palette-header-height-lg};
        --dxbl-color-palette-header-padding: #{$dx-color-palette-header-padding-lg};
        --dxbl-color-palette-table-margin: #{$dx-color-palette-table-margin-lg};
        --dxbl-color-palette-table-spacing: #{$dx-color-palette-table-spacing-lg};
        --dxbl-color-palette-no-color-padding: #{$dx-color-palette-no-color-padding-lg};
        --dxbl-color-palette-no-color-spacing: #{$dx-color-palette-no-color-spacing-lg};
        --dxbl-color-palette-color-tile-size: #{$dx-color-palette-color-tile-size-lg};
    }
}
