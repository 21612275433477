.dxbl-drawer {
    --dxbl-drawer-animation-duration: #{$dx-drawer-animation-duration};
    --dxbl-drawer-content-shading-bg: #{$dx-drawer-content-shading-bg};
    --dxbl-drawer-content-shading-opacity: #{$dx-drawer-content-shading-opacity};
    --dxbl-drawer-panel-bg: #{$dx-drawer-panel-bg};
    --dxbl-drawer-panel-color: #{$dx-drawer-panel-color};
    --dxbl-drawer-panel-box-shadow-left: #{$dx-drawer-panel-box-shadow-left};
    --dxbl-drawer-panel-box-shadow-right: #{$dx-drawer-panel-box-shadow-right};
    --dxbl-drawer-panel-font-family: #{$dx-drawer-panel-font-family};
    --dxbl-drawer-panel-font-size: #{$dx-drawer-panel-font-size};
    --dxbl-drawer-panel-line-height: #{$dx-drawer-panel-line-height};
    --dxbl-drawer-panel-padding-x: #{$dx-drawer-panel-padding-x};
    --dxbl-drawer-panel-padding-y: #{$dx-drawer-panel-padding-y};
    --dxbl-drawer-panel-width: #{$dx-drawer-panel-width};
    --dxbl-drawer-panel-width-mini: #{$dx-drawer-panel-width-mini};
    --dxbl-drawer-panel-header-bg: #{$dx-drawer-panel-header-bg};
    --dxbl-drawer-panel-header-color: #{$dx-drawer-panel-header-color};
    --dxbl-drawer-panel-header-font-size: #{$dx-drawer-panel-header-font-size};
    --dxbl-drawer-panel-header-font-weight: #{$dx-drawer-panel-header-font-weight};
    --dxbl-drawer-panel-header-line-height: #{$dx-drawer-panel-header-line-height};
    --dxbl-drawer-panel-header-min-height: #{$dx-drawer-panel-header-min-height};
    --dxbl-drawer-panel-header-padding-x: var(--dxbl-drawer-panel-padding-x);
    --dxbl-drawer-panel-header-padding-y: var(--dxbl-drawer-panel-padding-y);
    --dxbl-drawer-panel-body-padding-x: var(--dxbl-drawer-panel-padding-x);
    --dxbl-drawer-panel-body-padding-y: var(--dxbl-drawer-panel-padding-y);
    --dxbl-drawer-panel-footer-bg: #{$dx-drawer-panel-footer-bg};
    --dxbl-drawer-panel-footer-color: #{$dx-drawer-panel-footer-color};
    --dxbl-drawer-panel-footer-justify-content: #{$dx-drawer-panel-footer-justify-content};
    --dxbl-drawer-panel-footer-min-height: #{$dx-drawer-panel-footer-min-height};
    --dxbl-drawer-panel-footer-padding-x: var(--dxbl-drawer-panel-padding-x);
    --dxbl-drawer-panel-footer-padding-y: var(--dxbl-drawer-panel-padding-y);
    --dxbl-drawer-separator-border-color: #{$dx-drawer-separator-border-color};
    --dxbl-drawer-separator-border-style: #{$dx-drawer-separator-border-style};
    --dxbl-drawer-separator-border-width: #{$dx-drawer-separator-border-width};

    &.dxbl-sm {
        --dxbl-drawer-panel-font-size: #{$dx-drawer-panel-font-size-sm};
        --dxbl-drawer-panel-line-height: #{$dx-drawer-panel-line-height-sm};
        --dxbl-drawer-panel-padding-x: #{$dx-drawer-panel-padding-x-sm};
        --dxbl-drawer-panel-padding-y: #{$dx-drawer-panel-padding-y-sm};
        --dxbl-drawer-panel-width-mini: #{$dx-drawer-panel-width-mini-sm};
        --dxbl-drawer-panel-header-font-size: #{$dx-drawer-panel-header-font-size-sm};
        --dxbl-drawer-panel-header-line-height: #{$dx-drawer-panel-header-line-height-sm};
        --dxbl-drawer-panel-header-min-height: #{$dx-drawer-panel-header-min-height-sm};
        --dxbl-drawer-panel-footer-min-height: #{$dx-drawer-panel-footer-min-height-sm};
    }

    &.dxbl-lg {
        --dxbl-drawer-panel-font-size: #{$dx-drawer-panel-font-size-lg};
        --dxbl-drawer-panel-line-height: #{$dx-drawer-panel-line-height-lg};
        --dxbl-drawer-panel-padding-x: #{$dx-drawer-panel-padding-x-lg};
        --dxbl-drawer-panel-padding-y: #{$dx-drawer-panel-padding-y-lg};
        --dxbl-drawer-panel-width-mini: #{$dx-drawer-panel-width-mini-lg};
        --dxbl-drawer-panel-header-font-size: #{$dx-drawer-panel-header-font-size-lg};
        --dxbl-drawer-panel-header-line-height: #{$dx-drawer-panel-header-line-height-lg};
        --dxbl-drawer-panel-header-min-height: #{$dx-drawer-panel-header-min-height-lg};
        --dxbl-drawer-panel-footer-min-height: #{$dx-drawer-panel-footer-min-height-lg};
    }

    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    > .dxbl-drawer-panel {
        background-color: var(--dxbl-drawer-panel-bg);
        bottom: 0;
        color: var(--dxbl-drawer-panel-color);
        display: flex;
        flex-direction: column;
        font-family: var(--dxbl-drawer-panel-font-family);
        font-size: var(--dxbl-drawer-panel-font-size);
        line-height: var(--dxbl-drawer-panel-line-height);
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: var(--dxbl-drawer-animation-duration) ease-in-out;
        transition-property: visibility, width;
        visibility: visible;
        width: var(--dxbl-drawer-panel-width);

        @at-root #{selector-append(".dxbl-drawer-closed", &)} {
            visibility: hidden;
        }

        @at-root #{selector-append(".dxbl-drawer-left", &)} {
            left: 0;
        }

        @at-root #{selector-append(".dxbl-drawer-right", &)} {
            right: 0;
        }

        .dxbl-drawer-header,
        .dxbl-drawer-body,
        .dxbl-drawer-footer {
            display: flex;
            overflow: hidden;
        }

        .dxbl-drawer-header {
            align-items: center;
            border-bottom: var(--dxbl-drawer-separator-border-width) var(--dxbl-drawer-separator-border-style) var(--dxbl-drawer-separator-border-color);
            background-color: var(--dxbl-drawer-panel-header-bg);
            color: var(--dxbl-drawer-panel-header-color);
            flex-shrink: 0;
            font-size: var(--dxbl-drawer-panel-header-font-size);
            font-weight: var(--dxbl-drawer-panel-header-font-weight);
            justify-content: space-between;
            line-height: var(--dxbl-drawer-panel-header-line-height);
            min-height: var(--dxbl-drawer-panel-header-min-height);
            padding: var(--dxbl-drawer-panel-header-padding-y) var(--dxbl-drawer-panel-header-padding-x);
        }

        > .dxbl-drawer-body {
            align-items: flex-start;
            flex: 1 1 auto;
            flex-direction: column;
            padding: var(--dxbl-drawer-panel-body-padding-y) var(--dxbl-drawer-panel-body-padding-x);

            @at-root #{selector-append(".dxbl-drawer-open", &)} {
                @at-root #{
                selector-append(".dxbl-drawer-left", &),
                selector-append(".dxbl-drawer-right", &)} {
                    overflow-y: auto;
                }
            }
        }

        .dxbl-drawer-footer {
            align-items: center;
            background-color: var(--dxbl-drawer-panel-footer-bg);
            border-top: var(--dxbl-drawer-separator-border-width) var(--dxbl-drawer-separator-border-style) var(--dxbl-drawer-separator-border-color);
            color: var(--dxbl-drawer-panel-footer-color);
            flex-shrink: 0;
            justify-content: var(--dxbl-drawer-panel-footer-justify-content);
            margin-top: auto;
            min-height: var(--dxbl-drawer-panel-footer-min-height);
            padding: var(--dxbl-drawer-panel-footer-padding-y) var(--dxbl-drawer-panel-footer-padding-x);
        }
    }

    > .dxbl-drawer-shading {
        background-color: var(--dxbl-drawer-content-shading-bg);
        height: 100%;
        opacity: 0;
        position: absolute;
        transition: ease var(--dxbl-drawer-animation-duration);
        transition-property: opacity, visibility;
        visibility: hidden;
        width: 100%;
        z-index: 100;

        @at-root #{selector-append(".dxbl-drawer-open", &)} {
            opacity: var(--dxbl-drawer-content-shading-opacity);
            visibility: visible;
        }
    }

    .dxbl-drawer-content {
        height: 100%;
        overflow: hidden;
    }

    &.dxbl-drawer-shrink {
        display: flex;
        flex-flow: row nowrap;

        > .dxbl-drawer-panel {
            flex: 0 0 auto;
            position: relative;

            @at-root #{selector-append(".dxbl-drawer-right", &)} {
                order: 1;
            }

            @at-root #{
            selector-append(".dxbl-drawer-mini", &),
            selector-append(".dxbl-drawer-open", &)} {
                @at-root #{selector-append(".dxbl-drawer-left", &)} {
                    border-right: var(--dxbl-drawer-separator-border-width) var(--dxbl-drawer-separator-border-style) var(--dxbl-drawer-separator-border-color);
                }

                @at-root #{selector-append(".dxbl-drawer-right", &)} {
                    border-left: var(--dxbl-drawer-separator-border-width) var(--dxbl-drawer-separator-border-style) var(--dxbl-drawer-separator-border-color);
                }
            }
        }

        > .dxbl-drawer-content {
            flex: 1 1 auto;
        }
    }

    &.dxbl-drawer-overlap > .dxbl-drawer-panel {
        z-index: 100;

        @at-root #{selector-append(".dxbl-drawer-open", &)} {
            @at-root #{selector-append(".dxbl-drawer-left", &)} {
                box-shadow: var(--dxbl-drawer-panel-box-shadow-left);
            }

            @at-root #{selector-append(".dxbl-drawer-right", &)} {
                box-shadow: var(--dxbl-drawer-panel-box-shadow-right);
            }
        }
    }

    &.dxbl-drawer-mini > .dxbl-drawer-panel {
        width: var(--dxbl-drawer-panel-width-mini);
    }
}
