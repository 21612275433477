@import "mixins/button";
@import "mixins/text-edit";
@import "mixins/toolbar";

.dxbl-btn-toolbar,
.dxbl-btn-toolbar > .dxbl-adaptive-container-content {
    --dxbl-btn-toolbar-spacing: #{$dx-btn-toolbar-spacing};
    --dxbl-btn-toolbar-item-spacing: #{$dx-btn-toolbar-item-spacing};
    --dxbl-btn-toolbar-separator-height: calc(100% - 2 * #{$dx-border-width});
    --dxbl-btn-toolbar-separator-width: #{$dx-border-width};
    --dxbl-btn-toolbar-separator-margin-y: #{$dx-border-width};
    --dxbl-btn-toolbar-separator-color: #{$dx-border-color};
    --dxbl-btn-split-toolbar-dorder-radius: #{$dx-border-radius};

    &.dxbl-virtual-toolbar > .dxbl-btn-group.dxbl-toolbar-group.dxbl-toolbar-hidden-item:not(:first-child) {
        margin-left: var(--dxbl-btn-toolbar-spacing) !important; // stylelint-disable-line declaration-no-important
    }

    & > :not(:first-child) {
        margin-left: var(--dxbl-btn-toolbar-spacing);
    }

    &,
    & > .dxbl-btn-group,
    & > .dxbl-btn-group > .dxbl-btn-group {
        &.dxbl-toolbar-btn-ellipsis,
        & > .dxbl-toolbar-item {
            &,
            & > .dxbl-btn.dxbl-btn-secondary,
            & > .dxbl-btn-split {
                & > .dxbl-btn {
                    &.dxbl-btn-secondary {
                        @include button-secondary-tool-style();
                        @include button-secondary-tool-state-styles();
                    }

                    &.dxbl-btn-outline-secondary {
                        @include button-secondary-outline-tool-style();
                        @include button-secondary-outline-tool-state-styles();
                    }

                    &.dxbl-btn-text-secondary {
                        @include button-secondary-text-tool-style();
                        @include button-secondary-text-tool-state-styles();
                    }
                }
            }
        }
    }

    &,
    & > .dxbl-toolbar-item {
        & > .dxbl-btn,
        & > .dxbl-btn-split > .dxbl-btn {
            @include button-tool-paddings();
        }

        & > .dxbl-btn.dxbl-sm,
        & > .dxbl-btn-split > .dxbl-btn.dxbl-sm {
            @include button-tool-paddings-sm();
        }

        & > .dxbl-btn.dxbl-lg,
        & > .dxbl-btn-split > .dxbl-btn.dxbl-lg {
            @include button-tool-paddings-lg();
        }
    }

    & > .dxbl-btn-toolbar-separator {
        @include btn-toolbar-separator();
    }

    &.dxbl-btn-plain-toolbar {
        & > .dxbl-btn-group > .dxbl-toolbar-item,
        & > .dxbl-btn-group > .dxbl-btn-group > .dxbl-toolbar-item {
            @include btn-plain-toolbar-edit-item();

            &:not(:first-child) > .dxbl-btn:not(.dxbl-btn-first),
            &:not(:first-child) > .dxbl-btn-split:not(.dxbl-btn-first) > .dxbl-btn:first-child {
                margin-left: var(--dxbl-btn-toolbar-item-spacing);
            }

            &:not(:first-child) > .dxbl-text-edit {
                margin-left: var(--dxbl-btn-toolbar-item-spacing);
            }
        }

        & > .dxbl-btn-group:not(.dxbl-btn-group-first),
        & > .dxbl-btn-group > .dxbl-btn-group:not(.dxbl-btn-group-first) {
            @include btn-plain-toolbar-group-separator();
        }

        &,
        & > .dxbl-btn-group,
        & > .dxbl-btn-group > .dxbl-btn-group {
            & > .dxbl-toolbar-item > .dxbl-btn-split {
                @include btn-plain-toolbar-split();
            }
        }
    }

    &:not(.dxbl-btn-plain-toolbar) {
        & > .dxbl-btn-split,
        & > .dxbl-btn-group > .dxbl-toolbar-item > .dxbl-btn-split,
        & > .dxbl-btn-group > .dxbl-btn-split {
            & > .dxbl-btn:not(:first-child) {
                border-bottom-left-radius: 0 !important; // stylelint-disable-line declaration-no-important
                border-top-left-radius: 0 !important; // stylelint-disable-line declaration-no-important
            }

            & > .dxbl-btn:not(:last-child) {
                border-bottom-right-radius: 0 !important; // stylelint-disable-line declaration-no-important
                border-top-right-radius: 0 !important; // stylelint-disable-line declaration-no-important
                border-right-color: transparent !important; // stylelint-disable-line declaration-no-important
            }
        }

        & > .dxbl-btn-group,
        & > .dxbl-btn-group > .dxbl-toolbar-item,
        & > .dxbl-btn-group > .dxbl-btn-group,
        & > .dxbl-btn-group > .dxbl-btn-group > .dxbl-toolbar-item {
            & > .dxbl-btn:not(:last-child):not(.dxbl-btn-last),
            & > .dxbl-btn-split:not(:last-child) {
                border-right-color: transparent;
            }

            & > .dxbl-btn-split:not(:last-of-type):not(.dxbl-btn-last) > .dxbl-btn:last-child {
                border-right-color: transparent;
            }
        }

        & > .dxbl-btn-group,
        & > .dxbl-btn-group > .dxbl-toolbar-item {
            & > .dxbl-text-edit:not(:last-child) {
                margin-right: -$dx-btn-border-width;
            }
        }

        & > .dxbl-btn-group,
        & > .dxbl-btn-group > .dxbl-btn-group {
            & > .dxbl-toolbar-item:not(:first-child) {
                & > .dxbl-btn:not(.dxbl-btn-first),
                & > .dxbl-btn-split > .dxbl-btn:first-child,
                & > .dxbl-text-edit,
                & > .dxbl-text-edit > .dxbl-btn-group-left > .dxbl-btn:first-child,
                &.dxbl-btn-group-right > .dxbl-btn {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }
            }

            & > .dxbl-toolbar-item:not(:last-child) {
                & > .dxbl-btn:not(.dxbl-btn-last),
                & > .dxbl-btn-split:not(.dxbl-btn-last) > .dxbl-btn:last-child,
                & > .dxbl-text-edit,
                & > .dxbl-text-edit > .dxbl-btn-group-right > .dxbl-btn:last-child,
                &.dxbl-btn-group-left > .dxbl-btn {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }

                & > .dxbl-btn:not(.dxbl-btn-last),
                & > .dxbl-btn-split:not(.dxbl-btn-last) {
                    margin-right: -$dx-btn-border-width;
                }
            }
        }
    }

    .dxbl-sm &,
    &.dxbl-sm {
        --dxbl-btn-toolbar-spacing: #{$dx-btn-toolbar-spacing-sm};
        --dxbl-btn-toolbar-item-spacing: #{$dx-btn-toolbar-item-spacing-sm};
        --dxbl-tmpl-toolbar-item-border-radius: #{$dx-border-radius-sm};
    }

    .dxbl-lg &,
    &.dxbl-lg {
        --dxbl-btn-toolbar-spacing: #{$dx-btn-toolbar-spacing-lg};
        --dxbl-btn-toolbar-item-spacing: #{$dx-btn-toolbar-item-spacing-lg};
        --dxbl-tmpl-toolbar-item-border-radius: #{$dx-border-radius-lg};
    }
}
