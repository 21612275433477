@import "message-box.color";

.dxbl-message-box {
    $render-modes: (
        "primary",
        "info",
        "success",
        "warning",
        "danger"
    );

    $theme-modes: (
        "auto",
        "light",
        "dark"
    );

    --dxbl-popup-border-style: none;
    --dxbl-popup-border-radius: var(--dxbl-message-box-border-radius);
    --dxbl-popup-bg: var(--dxbl-message-box-background-color);
    --dxbl-popup-max-width: var(--dxbl-message-box-width);
    --dxbl-message-box-border-radius: #{$dx-message-box-border-radius};
    --dxbl-message-box-width: #{$dx-message-box-width};
    --dxbl-message-box-modal-content-padding: #{$dx-message-box-modal-content-padding};
    --dxbl-message-box-modal-content-gap: #{$dx-message-box-modal-content-gap};
    --dxbl-message-box-header-padding: #{$dx-message-box-header-padding};
    --dxbl-message-box-header-gap: #{$dx-message-box-header-gap};
    --dxbl-message-box-header-font-size: #{$dx-message-box-header-font-size};
    --dxbl-message-box-header-line-height: #{$dx-message-box-header-line-height};
    --dxbl-message-box-body-padding: #{$dx-message-box-body-padding};
    --dxbl-message-box-body-text-font-size: #{$dx-message-box-body-text-font-size};
    --dxbl-message-box-body-text-line-height: #{$dx-message-box-body-text-line-height};
    --dxbl-message-box-footer-padding: #{$dx-message-box-footer-padding};
    --dxbl-message-box-footer-gap: #{$dx-message-box-footer-gap};

    @mixin combine-theme-render($theme-value, $render-value) {
        &.dxbl-message-box-theme-#{$theme-value}.dxbl-message-box-render-#{$render-value} {
            --dxbl-message-box-background-color: #{getMessageBoxBackground($theme-value, $render-value)};
            --dxbl-message-box-header-font-color: #{getMessageBoxTextColor($theme-value, $render-value)};
            --dxbl-message-box-body-text-font-color: var(--dxbl-message-box-header-font-color);
            --dxbl-message-box-header-icon-color: #{getMessageBoxIconColor($theme-value, $render-value)};
            --dxbl-message-box-header-close-icon-color: #{getMessageBoxCloseIconColor($theme-value, $render-value)};
            --dxbl-message-box-footer-primary-button-color: var(--dxbl-message-box-header-icon-color);
            --dxbl-message-box-footer-secondary-button-color: #{getMessageBoxSecondaryButtonColor($theme-value, $render-value)};
            --dxbl-message-box-footer-secondary-button-hover-color: #{getMessageBoxSecondaryButtonHoverColor($theme-value, $render-value)};
        }
    }

    @each $theme-mode in $theme-modes {
        @each $render-value in $render-modes {
            @include combine-theme-render($theme-mode, $render-value);
        }
    }

    &.dxbl-sm {
        --dxbl-message-box-width: #{$dx-message-box-width-sm};
        --dxbl-message-box-modal-content-padding: #{$dx-message-box-modal-content-padding-sm};
        --dxbl-message-box-modal-content-gap: #{$dx-message-box-modal-content-gap-sm};
        --dxbl-message-box-header-padding: #{$dx-message-box-header-padding-sm};
        --dxbl-message-box-header-gap: #{$dx-message-box-header-gap-sm};
        --dxbl-message-box-header-font-size: #{$dx-message-box-header-font-size-sm};
        --dxbl-message-box-header-line-height: #{$dx-message-box-header-line-height-sm};
        --dxbl-message-box-body-padding: #{$dx-message-box-body-padding-sm};
        --dxbl-message-box-body-text-font-size: #{$dx-message-box-body-text-font-size-sm};
        --dxbl-message-box-body-text-line-height: #{$dx-message-box-body-text-line-height-sm};
        --dxbl-message-box-footer-padding: #{$dx-message-box-footer-padding-sm};
        --dxbl-message-box-footer-gap: #{$dx-message-box-footer-gap-sm};
    }

    &.dxbl-lg {
        --dxbl-message-box-width: #{$dx-message-box-width-lg};
        --dxbl-message-box-modal-content-padding: #{$dx-message-box-modal-content-padding-lg};
        --dxbl-message-box-modal-content-gap: #{$dx-message-box-modal-content-gap-lg};
        --dxbl-message-box-header-padding: #{$dx-message-box-header-padding-lg};
        --dxbl-message-box-header-gap: #{$dx-message-box-header-gap-lg};
        --dxbl-message-box-header-font-size: #{$dx-message-box-header-font-size-lg};
        --dxbl-message-box-header-line-height: #{$dx-message-box-header-line-height-lg};
        --dxbl-message-box-body-padding: #{$dx-message-box-body-padding-lg};
        --dxbl-message-box-body-text-font-size: #{$dx-message-box-body-text-font-size-lg};
        --dxbl-message-box-body-text-line-height: #{$dx-message-box-body-text-line-height-lg};
        --dxbl-message-box-footer-padding: #{$dx-message-box-footer-padding-lg};
        --dxbl-message-box-footer-gap: #{$dx-message-box-footer-gap-lg};
    }


    border-radius: var(--dxbl-message-box-border-radius);

    > .dxbl-modal-content {
        padding: var(--dxbl-message-box-modal-content-padding);
        gap: var(--dxbl-message-box-modal-content-gap);

        > .dxbl-popup-header {
            > .dxbl-message-box-header {
                --dxbl-message-box-header-font-weight: #{$dx-message-box-header-font-weight};

                display: flex;
                align-items: center;
                padding: var(--dxbl-message-box-header-padding);
                gap: var(--dxbl-message-box-header-gap);
                font-size: var(--dxbl-message-box-header-font-size);
                font-weight: var(--dxbl-message-box-header-font-weight);
                color: var(--dxbl-message-box-header-font-color);
                line-height: var(--dxbl-message-box-header-line-height);

                > .dxbl-message-box-header-icon {
                    --dxbl-message-box-header-icon-width: #{$dx-message-box-header-icon-width};
                    --dxbl-message-box-header-icon-height: #{$dx-message-box-header-icon-height};

                    width: var(--dxbl-message-box-header-icon-width);
                    height: var(--dxbl-message-box-header-icon-height);
                    fill: var(--dxbl-message-box-header-icon-color);
                }

                > .dxbl-message-box-header-close-button {
                    --dxbl-message-box-header-close-button-width: #{$dx-message-box-header-close-button-width};
                    --dxbl-message-box-header-close-button-height: #{$dx-message-box-header-close-button-height};
                    --dxbl-message-box-header-close-button-padding: #{$dx-message-box-header-close-button-padding};

                    margin-left: auto;
                    width: var(--dxbl-message-box-header-close-button-width);
                    height: var(--dxbl-message-box-header-close-button-height);
                    padding: var(--dxbl-message-box-header-close-button-padding);

                    .dxbl-message-box-header-close-icon {
                        --dxbl-message-box-header-close-icon-width: #{$dx-message-box-header-close-icon-width};
                        --dxbl-message-box-header-close-icon-height: #{$dx-message-box-header-close-icon-height};

                        width: var(--dxbl-message-box-header-close-icon-width);
                        height: var(--dxbl-message-box-header-close-icon-height);
                        fill: var(--dxbl-message-box-header-close-icon-color);
                    }
                }
            }
        }

        > .dxbl-message-box-body {
            --dxbl-message-box-body-text-font-weight: #{$dx-message-box-body-text-font-weight};

            padding: var(--dxbl-message-box-body-padding);
            font-size: var(--dxbl-message-box-body-text-font-size);
            line-height: var(--dxbl-message-box-body-text-line-height);
            font-weight: var(--dxbl-message-box-body-text-font-weight);
            color: var(--dxbl-message-box-body-text-font-color);
        }

        > .dxbl-message-box-footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: auto;
            padding: var(--dxbl-message-box-footer-padding);
            gap: var(--dxbl-message-box-footer-gap);

            > .dxbl-message-box-footer-primary-button {
                --dxbl-btn-bg: var(--dxbl-message-box-footer-primary-button-color);
                --dxbl-btn-border-color: var(--dxbl-message-box-footer-primary-button-color);
                --dxbl-btn-focus-bg: var(--dxbl-message-box-footer-primary-button-color);
                --dxbl-btn-focus-border-bg: var(--dxbl-message-box-footer-primary-button-color);
                --dxbl-btn-focus-outline-color: var(--dxbl-message-box-footer-primary-button-color);
            }

            > .dxbl-message-box-footer-secondary-button {
                --dxbl-btn-color: var(--dxbl-message-box-footer-secondary-button-color);
                --dxbl-btn-border-color: var(--dxbl-message-box-footer-secondary-button-color);
                --dxbl-btn-hover-color: var(--dxbl-message-box-footer-secondary-button-color);
                --dxbl-btn-hover-bg: var(--dxbl-message-box-footer-secondary-button-hover-color);
                --dxbl-btn-hover-border-color: var(--dxbl-message-box-footer-secondary-button-hover-color);
            }
        }
    }
}
