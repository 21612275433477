.dxbl-chart-legend-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dxbl-chart-legend {
    opacity: 1;

    &.dxbl-chart-legend-horizontal {
        .dxbl-chart-legend-item:not(:last-child) {
            margin-right: 1.25rem;
        }
    }

    &.dxbl-chart-legend-vertical {
        .dxbl-chart-legend-item:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    .dxbl-chart-title {
        padding: 0 0 0.5em;
        font-size: 1.2em;
        display: block;
    }

    padding: 0.5em 1em;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: flex-start;

    &.dxbl-chart-legend-align-center {
        align-items: center;
    }

    &.dxbl-chart-legend-horizontal.dxbl-chart-legend-align-right {
        align-items: flex-end;
    }

    .dxbl-chart-legend-items {
        display: flex;
        flex-flow: row wrap;
    }
}

.dxbl-chart-legend-icon {
    height: 1rem;
    width: 1rem;
    flex: 0 0 1rem;
    margin-right: 0.25rem;
    position: relative;
    overflow: hidden;

    ~ * {
        flex: 1 1 auto;
    }
}

.dxbl-chart-legend-text {
    white-space: nowrap;
}

.dxbl-chart-with-legend-container {
    align-self: stretch;
    flex: 1 1 auto;
    background-color: inherit;
}

.dxbl-chart-legend-outside {
    display: flex;
    align-items: center;

    .dxbl-chart-legend {
        &.dxbl-chart-legend-horizontal,
        &.dxbl-chart-legend-vertical.dxbl-chart-legend-align-center {
            &.dxbl-chart-legend-valign-top {
                margin-bottom: 0.5rem;
            }
        }

        &.dxbl-chart-legend-vertical {
            &.dxbl-chart-legend-align-left {
                margin-right: 0.5rem;
            }

            &.dxbl-chart-legend-align-right {
                margin-left: 0.5rem;
            }
        }

        &.dxbl-chart-legend-vertical.dxbl-chart-legend-align-center.dxbl-chart-legend-valign-bottom,
        &.dxbl-chart-legend-horizontal.dxbl-chart-legend-valign-bottom {
            margin-top: 0.5rem;
        }

        &.dxbl-chart-legend-horizontal.dxbl-chart-legend-align-left,
        &.dxbl-chart-legend-vertical.dxbl-chart-legend-valign-top:not(.dxbl-chart-legend-align-center) {
            align-self: flex-start;
        }

        &.dxbl-chart-legend-horizontal.dxbl-chart-legend-align-right,
        &.dxbl-chart-legend-vertical.dxbl-chart-legend-valign-bottom:not(.dxbl-chart-legend-align-center) {
            align-self: flex-end;
        }
    }
}

.dxbl-chart-legend-inside {
    position: relative;

    .dxbl-chart-legend {
        position: absolute;
        background-color: $dx-chart-legend-bg;
        border: $dx-chart-legend-border;
        border-radius: $dx-chart-legend-border-radius;
        box-shadow: $dx-chart-legend-box-shadow;
        word-wrap: break-word;

        &.dxbl-chart-legend-align-left {
            left: 0;
        }

        &.dxbl-chart-legend-align-right {
            right: 0;
        }

        &.dxbl-chart-legend-valign-top {
            top: 0;
        }

        &.dxbl-chart-legend-valign-bottom {
            bottom: 0;
        }

        &.dxbl-chart-legend-align-center {
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}

.dxbl-chart-legend-horizontal {
    .dxbl-chart-legend-items {
        flex-direction: row;
    }
}

.dxbl-chart-legend-vertical {
    .dxbl-chart-legend-items {
        flex-direction: column;
    }
}
