.dxbl-chatui-root {
    --dx-chatui-messagelist-bg: #{$dx-chatui-messagelist-bg};
    --dx-chatui-messagelist-gap: #{$dx-chatui-messagelist-gap};
    --dx-chatui-messagelist-padding: #{$dx-chatui-messagelist-padding};
    --dx-chatui-message-padding: #{$dx-chatui-message-padding};
    --dx-chatui-message-color: #{$dx-chatui-message-color};
    --dx-chatui-message-font-size: #{$dx-chatui-message-font-size};
    --dx-chatui-message-gap: #{$dx-chatui-message-gap};
    --dx-chatui-message-border-radius: #{$dx-chatui-message-border-radius};
    --dx-chatui-message-assistant-bg: #{$dx-chatui-message-assistant-bg};
    --dx-chatui-message-user-bg: #{$dx-chatui-message-user-bg};
    --dx-chatui-message-user-color: #{$dx-chatui-message-user-color};
    --dx-chatui-message-error-bg: #{$dx-chatui-message-error-bg};
    --dx-chatui-submitarea-gap: #{$dx-chatui-submitarea-gap};
    --dx-chatui-submitarea-padding: #{$dx-chatui-submitarea-padding};
    --dx-chatui-submitarea-bg: #{$dx-chatui-submitarea-bg};
    --dx-chatui-empty-icon-color: #{$dx-chatui-empty-icon-bg};

    .dxbl-chatui {
        display: flex;
        flex-direction: column;
        height: 100%;

        .dxbl-chatui-scrollviewer {
            height: 100%;
        }

        .dxbl-chatui-messages {
            display: flex;
            flex-direction: column;
            gap: var(--dx-chatui-messagelist-gap);
            padding: var(--dx-chatui-messagelist-padding);
            background-color: var(--dx-chatui-messagelist-bg);
            min-height: 100%;

            .dxbl-chatui-message-container {
                display: flex;
                flex-direction: column;
            }

            .dxbl-chatui-message {
                display: flex;
                flex-direction: column;
                padding: var(--dx-chatui-message-padding);
                color: var(--dx-chatui-message-color);
                font-size: var(--dx-chatui-message-font-size);
                border-radius: var(--dx-chatui-message-border-radius);
                gap: var(--dx-chatui-message-gap);
                min-width: 80%;
                align-self: flex-end;
                word-wrap: break-word;
                max-width: 100%;

                &.dxbl-chatui-message-user {
                    background-color: var(--dx-chatui-message-user-bg);
                    color: var(--dx-chatui-message-user-color);
                }

                &.dxbl-chatui-message-assistant {
                    background-color: var(--dx-chatui-message-assistant-bg);
                    min-width: 100%;
                }

                &.dxbl-chatui-message-error {
                    background-color: var(--dx-chatui-message-error-bg);
                    min-width: 100%;
                }

                .dxbl-chat-message-button {
                    width: 30px;
                    height: 30px;
                    padding: 0;
                }
            }
        }

        .dxbl-chatui-submitarea {
            display: flex;
            gap: var(--dx-chatui-submitarea-gap);
            padding: var(--dx-chatui-submitarea-padding);
            background-color: var(--dx-chatui-submitarea-bg);

            .dxbl-chatui-input {
                width: 100%;
            }
        }
    }

    &.dxbl-sm {
        --dx-chatui-message-font-size: #{$dx-chatui-message-font-size-sm};
    }

    &.dxbl-lg {
        --dx-chatui-message-font-size: #{$dx-chatui-message-font-size-lg};
    }

    &.dxbl-chatui-emptystate {
        .dxbl-chatui .dxbl-chatui-messages {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4px;

            .dxbl-chatui-empty-icon {
                width: 40px;
                height: 28px;
                color: var(--dx-chatui-empty-icon-color);
            }

            .dxbl-chatui-empty-text {
                gap: 2px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .dxbl-chatui-empty-text-title {
                    font-weight: 500;
                }

                .dxbl-chatui-empty-text-caption {
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
}
