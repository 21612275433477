@import "grid";

.dxbl-grid {
    .dxbl-grid-table {
        &.dxbl-grid-table-content-fit-calc {
            & > tbody > tr > td {
                .dxbl-grid-tree-node-expand-controls-container {
                    float: unset;
                }
            }
        }
    }
}

.dxbl-grid-tree-node-expand-button {
    --dxbl-btn-bg: var(--dxbl-grid-expand-btn-bg);
    --dxbl-btn-color: var(--dxbl-grid-expand-btn-color);
    --dxbl-btn-padding-x: var(--dxbl-grid-expand-btn-padding-x);
    --dxbl-btn-padding-y: var(--dxbl-grid-expand-btn-padding-y);

    padding-left: var(--dxbl-grid-expand-btn-padding-x);
    padding-right: var(--dxbl-grid-expand-btn-padding-x);
    margin: calc(-1 * var(--dxbl-grid-expand-btn-padding-y)) calc(-1 * var(--dxbl-grid-expand-btn-padding-x));

    & > .dxbl-image {
        margin: 0;
    }

    @include embedded-image-color("--dxbl-grid-expand-btn-color", null, "--dxbl-grid-expand-btn-hover-color");

    @include hover-state() {
        @include state-hover-style("--dxbl-grid-expand-btn-hover-bg", "--dxbl-grid-expand-btn-hover-color");
    }

    @include grid-inplace-button-style($dx-round-border-radius);
}

.dxbl-grid-tree-node-expand-button-placeholder {
    min-width: 16px;
    width: 16px;
    display: inline-block;
}

.dxbl-grid-tree-node-expand-controls-cell {
    white-space: nowrap;
}

.dxbl-grid-tree-node-expand-controls-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-right: calc(var(--dxbl-grid-expand-btn-padding-x) * 1.5);
    float: left;
}

.dxbl-grid-tree-node-indents-container {
    white-space: nowrap;
    display: inline-block;
}

.dxbl-grid-tree-node-indent {
    min-width: 16px;
    width: 16px;
    display: inline-block;
}

.dxbl-grid-tree-node-empty-indent {
    display: inline-block;
}
