@import "mixins/common";

.dxbl-radio-group {
    --dxbl-radio-group-radio-padding-top: #{$dx-radio-group-radio-padding-top};
    --dxbl-radio-group-radio-padding-left: #{$dx-radio-group-radio-padding-left};
    --dxbl-radio-group-font-size: #{$dx-radio-group-font-size};
    --dxbl-radio-group-no-data-text-opacity: #{$dx-radio-group-no-data-text-opacity};

    display: flex;
    font-size: var(--dxbl-radio-group-font-size);

    &.dxbl-radio-group-vertical {
        flex-flow: column wrap;

        & > .dxbl-checkbox-radio {
            width: 100%;
        }

        & > .dxbl-checkbox-radio ~ .dxbl-checkbox-radio {
            padding-top: var(--dxbl-radio-group-radio-padding-top);
        }
    }

    &.dxbl-radio-group-horizontal {
        flex-flow: row wrap;

        & > .dxbl-checkbox-radio ~ .dxbl-checkbox-radio {
            padding-left: var(--dxbl-radio-group-radio-padding-left);
        }
    }

    & > .dxbl-radio-group-no-data-text {
        opacity: var(--dxbl-radio-group-no-data-text-opacity);
    }

    &.dxbl-sm {
        --dxbl-radio-group-radio-padding-top: #{$dx-radio-group-radio-padding-top-sm};
        --dxbl-radio-group-radio-padding-left: #{$dx-radio-group-radio-padding-left-sm};
        --dxbl-radio-group-font-size: #{$dx-radio-group-font-size-sm};
    }

    &.dxbl-lg {
        --dxbl-radio-group-radio-padding-top: #{$dx-radio-group-radio-padding-top-lg};
        --dxbl-radio-group-radio-padding-left: #{$dx-radio-group-radio-padding-left-lg};
        --dxbl-radio-group-font-size: #{$dx-radio-group-font-size-lg};
    }
}
