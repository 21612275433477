.dxbl-ribbon {
    --dxbl-ribbon-border-color: #{$dx-border-color};
    --dxbl-ribbon-tab-context-bg: #{$dx-ribbon-context-tab-bg};
    --dxbl-ribbon-color-picker-width: #{$dx-ribbon-color-picker-width};
    --dxbl-ribbon-color-picker-height: #{$dx-ribbon-color-picker-height};
    --dxbl-ribbon-color-picker-border-radius: #{$dx-color-palette-color-tile-border-radius};
    --dxbl-ribbon-color-picker-outline: #{$dx-color-palette-color-tile-outline};
    --dxbl-ribbon-padding: #{$dx-ribbon-padding};
    --dxbl-ribbon-item-margin-left: #{$dx-ribbon-item-margin-left};
    --dxbl-ribbon-border-bottom-width: #{$dx-office-ribbon-border-bottom-width};
    --dxbl-toolbar-combobox-width: unset;
    --dxbl-toolbar-spin-width: unset;

    border-width: var(--dxbl-ribbon-border-bottom-width);
    border-style: solid;
    border-color: var(--dxbl-ribbon-border-color);


    .dxbl-ribbon-context-tab:not(.dxbl-active):not(:hover) {
        background-color: var(--dxbl-ribbon-tab-context-bg);
    }

    .dxbl-btn-toolbar {
        .dxbl-btn-group {
            &.dxbl-toolbar-layout-block-group {
                .dxbl-toolbar-btn-ellipsis {
                    & > .dxbl-btn > .dxbl-toolbar-adaptive-item-text {
                        display: none;
                    }
                }
            }

            &:not(.dxbl-btn-group-first) > .dxbl-toolbar-group-separator {
                height: var(--dxbl-btn-toolbar-separator-height);
                width: var(--dxbl-btn-toolbar-separator-width);
                margin: var(--dxbl-btn-toolbar-separator-margin-y) 0;
                background-color: var(--dxbl-btn-toolbar-separator-color);
                margin-right: var(--dxbl-btn-toolbar-spacing);
            }
        }
    }

    // TODO: for future implementation
    .dxbl-toolbar.dxbl-ribbon-multiline {
        .dxbl-btn-toolbar {
            .dxbl-toolbar-item {
                .dxbl-toolbar-layout-block-item.dxbl-toolbar-btn.dxbl-toolbar-item {
                    flex-direction: column;

                    .dxbl-image::before {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .dxbl-adaptive-container {
        display: flex;
        overflow: hidden;
        padding: var(--dxbl-ribbon-paading);

        .dxbl-adaptive-container-content {
            display: flex;

            .dxbl-toolbar-group-separator {
                height: var(--dxbl-btn-toolbar-separator-height);
                width: var(--dxbl-btn-toolbar-separator-width);
                margin: var(--dxbl-btn-toolbar-separator-margin-y) 0;
                background-color: var(--dxbl-btn-toolbar-separator-color);
                margin-right: var(--dxbl-btn-toolbar-spacing);

                &.dxbl-ribbon-general-separator.dxbl-toolbar-hidden-item {
                    display: none;
                }
            }

            .dxbl-adaptive-group {
                display: flex;

                &:first-child {
                    --dxbl-btn-toolbar-separator-width: 0;
                }

                .dxbl-ribbon-item {
                    & > .dxbl-toolbar-item-tmpl {
                        height: 100%;

                        .dxbl-toolbar-combobox {
                            width: var(--dxbl-toolbar-combobox-width);
                            height: 100%;
                        }

                        .dxbl-toolbar-spin-edit {
                            width: var(--dxbl-toolbar-spin-width);
                            height: 100%;
                        }
                    }
                }

                & + .dxbl-adaptive-group {
                    --dxbl-btn-toolbar-separator-width: 1px;
                }

                .dxbl-adaptive-item {
                    &.dxbl-adaptive-item-state-default {
                        .dxbl-btn.dxbl-toolbar-layout-block-item.dxbl-toolbar-btn.dxbl-toolbar-item {
                            margin-right: unset !important; // stylelint-disable-line declaration-no-important
                        }
                    }

                    & > .dxbl-btn-split-dropdown {
                        padding-left: calc(var(--dxbl-btn-padding-x) / 2) !important; // stylelint-disable-line declaration-no-important
                        padding-right: calc(var(--dxbl-btn-padding-x) / 2) !important; // stylelint-disable-line declaration-no-important
                    }

                    .dxbl-toolbar-color-palette-item {
                        & > .dxbl-btn {
                            --dxbl-btn-padding-x: var(--dxbl-ribbon-padding);
                        }

                        & > .dxbl-btn-split-dropdown {
                            padding-left: calc(var(--dxbl-btn-padding-x) / 2) !important; // stylelint-disable-line declaration-no-important
                            padding-right: calc(var(--dxbl-btn-padding-x) / 2) !important; // stylelint-disable-line declaration-no-important
                        }
                    }

                    .dxbl-toolbar-layout-block-item {
                        margin-right: unset !important; // stylelint-disable-line declaration-no-important
                    }
                }
            }
        }

        .dxbl-ribbon-general-separator {
            &.dxbl-toolbar-item-hidden {
                --dxbl-btn-toolbar-separator-margin-y: 0;

                display: none;
            }
        }

        .dxbl-toolbar-btn-ellipsis:not(.dxbl-toolbar-btn-ellipsis-general) {
            display: none !important; // stylelint-disable-line declaration-no-important

            &.dxbl-adaptive-ellipsis-state-default {
                display: block !important; // stylelint-disable-line declaration-no-important
            }

            .dxbl-toolbar-adaptive-item-preview-image {
                display: none;
            }
        }

        .dxbl-toolbar-btn-ellipsis-general {
            margin-left: 0;
        }

        .dxbl-adaptive-group {
            .dxbl-adaptive-item {
                &.dxbl-adaptive-item-no-text,
                &.dxbl-adaptive-item-state-no-text {
                    .dxbl-ribbon-item {
                        &,
                        &.dxbl-btn-split > .dxbl-btn {
                            > .dxbl-image + span {
                                display: none;
                            }
                        }
                    }
                }

                &.dxbl-toolbar-item-collapsed,
                &.dxbl-adaptive-item-collapsed,
                &.dxbl-adaptive-item-state-collapsed {
                    display: none;
                }
            }

            .dxbl-toolbar-btn-ellipsis {
                & > .dxbl-btn {
                    height: 100%;
                }

                .dxbl-toolbar-icon-more,
                .dxbl-toolbar-adaptive-item-preview-image {
                    margin-left: var(--dxbl-btn-image-spacing);
                }

                &.dxbl-adaptive-ellipsis-state-default {
                    display: block;
                }

                &.dxbl-adaptive-ellipsis-group-text {
                    .dxbl-toolbar-adaptive-item-text {
                        display: block;
                    }
                }

                &.dxbl-adaptive-ellipsis-group-no-text {
                    .dxbl-toolbar-adaptive-item-text {
                        display: none;
                    }
                }
            }

            .dxbl-adaptive-group-icon {
                display: none;
            }

            &.dxbl-adaptive-state-full-seq,
            &.dxbl-adaptive-state-no-text-seq {
                .dxbl-ellipsis-button {
                    display: block;
                }

                .dxbl-toolbar-btn-ellipsis {
                    display: flex !important; // stylelint-disable-line declaration-no-important
                    visibility: visible !important; // stylelint-disable-line declaration-no-important
                }
            }

            &.dxbl-adaptive-state-no-text,
            &.dxbl-adaptive-state-no-text-seq {
                .dxbl-ribbon-item > .dxbl-image + span {
                    display: none;
                }
            }

            &.dxbl-adaptive-group-state-default {
                .dxbl-toolbar-btn-ellipsis-dropdown-mark {
                    display: none;
                }
            }

            &.dxbl-adaptive-group-state-text {
                .dxbl-toolbar-btn-ellipsis {
                    display: flex !important; // stylelint-disable-line declaration-no-important

                    .dxbl-toolbar-adaptive-item-text {
                        display: block !important; // stylelint-disable-line declaration-no-important
                    }

                    .dxbl-toolbar-adaptive-item-preview-image {
                        display: block;
                    }

                    .dxbl-toolbar-icon-more {
                        display: none;
                    }

                    .dxbl-toolbar-btn-ellipsis-dropdown-mark {
                        display: block;
                    }
                }

                .dxbl-adaptive-item:not(.dxbl-toolbar-btn-ellipsis),
                dxbl-toolbar-item:not(.dxbl-toolbar-btn-ellipsis) {
                    display: none;
                }
            }

            &.dxbl-adaptive-group-state-no-text {
                .dxbl-toolbar-btn-ellipsis {
                    display: flex !important; // stylelint-disable-line declaration-no-important

                    .dxbl-toolbar-adaptive-item-text {
                        display: none !important; // stylelint-disable-line declaration-no-important
                    }

                    .dxbl-toolbar-adaptive-item-preview-image {
                        display: block;
                    }

                    .dxbl-toolbar-icon-more {
                        display: none;
                    }

                    .dxbl-toolbar-btn-ellipsis-dropdown-mark {
                        display: block;
                    }
                }

                .dxbl-adaptive-item:not(.dxbl-toolbar-btn-ellipsis),
                dxbl-toolbar-item:not(.dxbl-toolbar-btn-ellipsis) {
                    display: none;
                }
            }

            &.dxbl-adaptive-state-grouping {
                .dxbl-adaptive-group-icon {
                    display: flex;
                }

                .dxbl-toolbar-btn-ellipsis {
                    display: flex !important; // stylelint-disable-line declaration-no-important

                    .dxbl-toolbar-adaptive-item-text {
                        display: block !important; // stylelint-disable-line declaration-no-important
                    }

                    .dxbl-toolbar-adaptive-item-preview-image {
                        display: block;
                    }
                }

                .dxbl-adaptive-item,
                dxbl-toolbar-item {
                    display: none;
                }
            }

            &.dxbl-adaptive-state-grouping-no-text {
                .dxbl-adaptive-group-icon {
                    display: flex;
                }

                .dxbl-toolbar-btn-ellipsis {
                    display: flex !important; // stylelint-disable-line declaration-no-important

                    .dxbl-toolbar-adaptive-item-text {
                        display: none !important; // stylelint-disable-line declaration-no-important
                    }

                    .dxbl-toolbar-adaptive-item-preview-image {
                        display: block;
                    }
                }

                .dxbl-adaptive-item,
                dxbl-toolbar-item {
                    display: none;
                }
            }
        }

        .dxbl-toolbar-item-collapsed,
        .dxbl-adaptive-item-collapsed {
            display: none;
        }
    }

    .dxbl-ribbon-color-picker-image-container,
    .dxbl-ribbon-color-picker-image-source {
        width: var(--dxbl-ribbon-color-picker-width);
        height: var(--dxbl-ribbon-color-picker-height);
        border-radius: var(--dxbl-ribbon-color-picker-border-radius);
        outline: var(--dxbl-ribbon-color-picker-outline);
    }

    .dxbl-ribbon-color-picker-image-container {
        border: 1px solid currentcolor;
        background-color: var(--colorpicker-back-color);
    }

    .dxbl-ribbon-color-picker-image-source {
        color: var(--colorpicker-back-color);
    }
}

.dxbl-itemlist-dropdown .dxbl-dropdown-dialog {
    .dxbl-ribbon-item {
        .dxbl-text-edit {
            margin: 0.25rem 0.375rem 0.25rem 0.75rem;
        }
    }
}

.dxbl-toolbar-dropdown .dxbl-toolbar-dropdown-item-list {
    --dxbl-ribbon-color-picker-border-radius: #{$dx-color-palette-color-tile-border-radius};
    --dxbl-ribbon-color-picker-outline: #{$dx-color-palette-color-tile-outline};

    .dxbl-toolbar-group-dropdown-name {
        padding-left: calc(var(--dxbl-toolbar-dropdown-btn-padding-x) * 2);
        font-weight: 600; // TODO: Check with the designer
    }

    .dxbl-toolbar-dropdown-item.dxbl-toolbar-combobox .dxbl-toolbar-edit-combobox {
        width: 100%;

        .dxbl-text-edit {
            margin-right: 0;
        }
    }

    .dxbl-ribbon-color-picker-image-container,
    .dxbl-ribbon-color-picker-image-source {
        border-radius: var(--dxbl-ribbon-color-picker-border-radius);
        outline: var(--dxbl-ribbon-color-picker-outline);
    }

    .dxbl-ribbon-color-picker-image-source.dxbl-ribbon-color-picker-image-container {
        background-color: unset;
        color: var(--back-color);
    }
}
