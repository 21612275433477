@import "tabs.positions";
@import "mixins/common";
@import "mixins/tabs";

.dxbl-tabs {
    --dxbl-tabs-color: #{$dx-tabs-color};
    --dxbl-tabs-bg: #{$dx-tabs-bg};
    --dxbl-tabs-font-family: #{$dx-tabs-font-family};
    --dxbl-tabs-font-size: #{$dx-tabs-font-size};
    --dxbl-tabs-line-height: #{$dx-tabs-line-height};
    --dxbl-tabs-padding-y: #{$dx-tabs-padding-y};
    --dxbl-tabs-padding-top: var(--dxbl-tabs-padding-y);
    --dxbl-tabs-padding-bottom: var(--dxbl-tabs-padding-y);
    --dxbl-tabs-padding-x: #{$dx-tabs-padding-x};
    --dxbl-tabs-padding-left: var(--dxbl-tabs-padding-x);
    --dxbl-tabs-padding-right: var(--dxbl-tabs-padding-x);
    --dxbl-tabs-separator-border-color: #{$dx-tabs-separator-border-color};
    --dxbl-tabs-separator-border-style: #{$dx-tabs-separator-border-style};
    --dxbl-tabs-separator-border-width: #{$dx-tabs-separator-border-width};
    --dxbl-tabs-tab-padding-x: #{$dx-tabs-tab-padding-x};
    --dxbl-tabs-tab-padding-y: #{$dx-tabs-tab-padding-y};
    --dxbl-tabs-tab-border-style: #{$dx-tabs-tab-border-style};
    --dxbl-tabs-tab-border-color: #{$dx-tabs-tab-border-color};
    --dxbl-tabs-tab-border-width: #{$dx-tabs-tab-border-width};
    --dxbl-tabs-tab-selected-bg: #{$dx-tabs-tab-selected-bg};
    --dxbl-tabs-tab-selected-color: #{$dx-tabs-tab-selected-color};
    --dxbl-tabs-tab-hover-color: #{$dx-tabs-tab-hover-color};
    --dxbl-tabs-tab-hover-bg: #{$dx-tabs-tab-hover-bg};
    --dxbl-tabs-tab-width: #{$dx-tabs-horizontal-tab-width};
    --dxbl-tabs-tab-btn-bg: #{$dx-tabs-tab-btn-bg};
    --dxbl-tabs-tab-btn-color: #{$dx-tabs-tab-btn-color};
    --dxbl-tabs-image-spacing: #{$dx-tabs-image-spacing};
    --dxbl-tabs-scroll-btn-color: #{$dx-tabs-scroll-btn-color};
    --dxbl-tabs-scroll-btn-hover-color: #{$dx-tabs-scroll-btn-hover-color};
    --dxbl-tabs-scroll-btn-disabled-color: #{$dx-tabs-scroll-btn-disabled-color};
    --dxbl-tabs-tab-focus-outline-width: #{$dx-tabs-tab-focus-outline-width};
    --dxbl-tabs-tab-focus-outline-color: #{$dx-tabs-tab-focus-outline-color};
    --dxbl-tabs-close-btn-color: #{$dx-tabs-close-btn-color};
    --dxbl-tabs-close-btn-hover-bg: #{$dx-tabs-close-btn-hover-bg};
    --dxbl-tabs-close-btn-hover-color: #{$dx-tabs-close-btn-hover-color};
    --dxbl-tabs-button-spacing: #{$dx-tabs-btn-spacing};
    --dxbl-tabs-padding-right-close-btn: calc(var(--dxbl-tabs-tab-padding-x) - 0.375rem);
    --dxbl-tabs-tab-drag-bg-color: #{$dx-tabs-tab-drag-bg-color};
    --dxbl-tabs-close-btn-width: #{$dx-tabs-close-btn-width};
    --dxbl-tabs-close-btn-height: #{$dx-tabs-close-btn-height};
    --dxbl-tabs-tab-drag-shadow: #{$dx-tabs-tab-drag-shadow};
    --dxbl-tabs-tab-drop-indicator-color: #{$dx-tabs-tab-drop-indicator-color};

    /* Content */
    --dxbl-tabs-content-color: #{$dx-tabs-content-color};
    --dxbl-tabs-content-font-size: #{$dx-tabs-content-font-size};
    --dxbl-tabs-content-line-height: #{$dx-tabs-content-line-height};

    display: block;

    @include tabs-positions();

    > .dxbl-tabs-tablist:not([data-dx-focus-hidden]) {
        .dxbl-tabs-item:focus-visible {
            outline: var(--dxbl-tabs-tab-focus-outline-width) solid var(--dxbl-tabs-tab-focus-outline-color);
            outline-offset: calc(var(--dxbl-tabs-tab-focus-outline-width) * -1);
        }
    }

    > .dxbl-tabs-tablist[data-dx-focus-hidden] {
        .dxbl-tabs-item:focus-visible {
            box-shadow: none;
            position: relative;
            outline: unset;
        }
    }

    > .dxbl-tabs-tablist {
        color: var(--dxbl-tabs-color);
        font-family: var(--dxbl-tabs-font-family);
        font-size: var(--dxbl-tabs-font-size);
        line-height: var(--dxbl-tabs-line-height);
        background-color: var(--dxbl-tabs-bg);
        display: block;
        align-items: center;
        padding: var(--dxbl-tabs-padding-top) var(--dxbl-tabs-padding-right) var(--dxbl-tabs-padding-bottom) var(--dxbl-tabs-padding-left);

        > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > ul {
            margin-bottom: 0;
        }

        &,
        & > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content {
            > ul {
                display: flex;
                padding-left: 0;
                white-space: nowrap;
                flex-wrap: wrap;

                > li {
                    list-style: none;

                    > .dxbl-tabs-item {
                        z-index: 0;
                        color: inherit;
                        display: flex;

                        &.dxbl-dragging {
                            background-color: var(--dxbl-tabs-tab-drag-bg-color);
                            box-shadow: var(--dxbl-tabs-tab-drag-shadow);
                            pointer-events: none;
                            z-index: 20000;
                        }

                        > .dxbl-text {
                            --dxbl-text-padding-x: 0;

                            border-left-width: 0;
                            border-right-width: 0;
                            width: 100%;
                            flex: 1 1 auto;

                            .dxbl-tabs-text-overflow {
                                width: 100%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        > .dxbl-tabs-close-button {
                            --dxbl-btn-bg: var(--dxbl-tabs-tab-btn-bg);
                            --dxbl-btn-box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
                            --dxbl-btn-padding-x: 0;
                            --dxbl-btn-padding-y: 0;
                            --dxbl-btn-border-radius: 50%;
                            --dxbl-btn-border-width: 0;
                            --dxbl-btn-hover-bg: var(--dxbl-tabs-close-btn-hover-bg);
                            --dxbl-btn-hover-image-color: var(--dxbl-tabs-close-btn-hover-color);
                            --dxbl-btn-image-color: var(--dxbl-tabs-close-btn-color);
                            --dxbl-image-width: 1rem;
                            --dxbl-image-height: 1rem;

                            width: var(--dxbl-tabs-close-btn-width);
                            height: var(--dxbl-tabs-close-btn-height);
                            margin-left: var(--dxbl-tabs-button-spacing);
                            flex-shrink: 0;

                            @include button-focus-state() {
                                @include button-inner-focus-style(var(--dxbl-btn-border-radius));
                            }

                            @at-root #{selector-append(".dxbl-dragging", &)} {
                                --dxbl-btn-hover-bg: var(--dxbl-btn-bg);
                                --dxbl-btn-hover-image-color: var(--dxbl-btn-image-color);
                            }
                        }

                        @include not-active-state() {
                            @include not-disabled-state() {
                                cursor: pointer;
                            }
                        }

                        @include active-state() {
                            cursor: default;
                        }

                        &:not(.dxbl-tabs-tmpl) {
                            border-style: var(--dxbl-tabs-tab-border-style);
                            border-color: #{$dx-tabs-tab-inactive-border-color};
                            border-width: var(--dxbl-tabs-tab-border-width);
                            display: flex;
                            align-items: center;
                            padding: var(--dxbl-tabs-tab-padding-y) var(--dxbl-tabs-tab-padding-x);
                            text-decoration: none;
                            max-width: var(--dxbl-tabs-tab-width);

                            &:has(.dxbl-tabs-close-button) {
                                padding-right: var(--dxbl-tabs-padding-right-close-btn);
                            }

                            @include not-active-state() {
                                @include not-disabled-state() {
                                    @at-root #{selector-append(":not(.dxbl-dragging)", &)} {
                                        @include hover-state() {
                                            border-color: var(--dxbl-tabs-tab-border-color);

                                            @include state-hover-style("--dxbl-tabs-tab-hover-bg", "--dxbl-tabs-tab-hover-color", "--dxbl-tabs-tab-border-width");
                                        }
                                    }
                                }
                            }

                            @include active-state() {
                                color: var(--dxbl-tabs-tab-selected-color);
                                background-color: var(--dxbl-tabs-tab-selected-bg);
                                border-color: var(--dxbl-tabs-tab-border-color) var(--dxbl-tabs-tab-border-color) var(--dxbl-tabs-tab-border-color) var(--dxbl-tabs-tab-border-color);

                                @include tab-selected-element($dx-tabs-tab-selected-element-bg, $dx-tabs-tab-selected-element-height);

                                @include hover-state() {
                                    border-color: var(--dxbl-tabs-tab-border-color) var(--dxbl-tabs-tab-border-color) var(--dxbl-tabs-tab-border-color) var(--dxbl-tabs-tab-border-color);
                                }

                                &.dxbl-dragging {
                                    background-color: var(--dxbl-tabs-tab-drag-bg-color);
                                }
                            }

                            > .dxbl-image {
                                --dxbl-image-margin-y: #{$dx-tabs-image-margin-y};

                                display: inline-block;
                                align-self: center;

                                &:not(:last-child) {
                                    margin-right: var(--dxbl-tabs-image-spacing);
                                }
                            }
                        }

                        @include embedded-image-color();
                        @include embedded-text-opacity();

                        @include disabled-state() {
                            cursor: default;
                        }
                    }
                }
            }

            > .dxbl-drop-target-indicator {
                box-shadow: inset 2px 2px 0 var(--dxbl-tabs-tab-drop-indicator-color), inset -2px -2px 0 var(--dxbl-tabs-tab-drop-indicator-color);
                height: 2px;
                pointer-events: none;
                position: fixed;
                transition: box-shadow 0.25s;
                width: 2px;
                z-index: 3;
            }
        }

        &.dxbl-tabs-tablist-scrollable {
            display: flex;

            > .dxbl-btn {
                display: none;
            }

            &.dxbl-tabs-tablist-has-overflow > .dxbl-btn {
                --dxbl-btn-color: var(--dxbl-tabs-btn-color);

                display: inline-flex;

                @include embedded-image-color("--dxbl-tabs-scroll-btn-color", "--dxbl-tabs-scroll-btn-disabled-color", "--dxbl-tabs-scroll-btn-hover-color", null, null, true);
            }

            > .dxbl-scroll-viewer {
                > .dxbl-scroll-viewer-hor-scroll-bar,
                > .dxbl-scroll-viewer-vert-scroll-bar {
                    @include active-state() {
                        display: none;
                    }
                }

                > .dxbl-scroll-viewer-content > ul {
                    flex-wrap: nowrap;
                }
            }
        }

        > .dxbl-btn {
            --dxbl-btn-border-width: 0;

            @include hover-state() {
                --dxbl-btn-hover-color: var(--dxbl-btn-color);
                --dxbl-btn-hover-bg: transparent;
                --dxbl-btn-hover-background: none;
            }

            @include button-focus-state() {
                @include button-inner-focus-style(var(--dxbl-btn-border-radius));
            }
        }
    }

    .dxbl-tabs-content-panel {
        color: var(--dxbl-tabs-content-color);
        font-size: var(--dxbl-tabs-content-font-size);
        line-height: var(--dxbl-tabs-content-line-height);
        display: flex;
        flex-grow: 1;

        &:not(.dxbl-tabs-render-default) > .dxbl-tabs-content:not([data-dx-tab-loaded]):not(.dxbl-active),
        &.dxbl-loaded:not(.dxbl-tabs-render-default) > .dxbl-tabs-content:not([data-dx-tab-loaded]) {
            position: absolute;
            visibility: hidden;
            left: -10000px;
        }

        .dxbl-tabs-content {
            flex: 1 0 100%;
            width: 100%;
        }
    }

    &.dxbl-sm {
        --dxbl-tabs-image-spacing: #{$dx-tabs-image-spacing-sm};
        --dxbl-tabs-tab-padding-x: #{$dx-tabs-tab-padding-x-sm};
        --dxbl-tabs-tab-padding-y: #{$dx-tabs-tab-padding-y-sm};
        --dxbl-tabs-font-size: #{$dx-tabs-font-size-sm};
        --dxbl-tabs-line-height: #{$dx-tabs-line-height-sm};
        --dxbl-tabs-button-spacing: #{$dx-tabs-btn-spacing-sm};
        --dxbl-tabs-close-btn-width: #{$dx-tabs-close-btn-width-sm};
        --dxbl-tabs-close-btn-height: #{$dx-tabs-close-btn-height-sm};

        /* Content */
        --dxbl-tabs-content-font-size: #{$dx-tabs-content-font-size-sm};
        --dxbl-tabs-content-line-height: #{$dx-tabs-content-line-height-sm};

        > .dxbl-tabs-tablist > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > ul,
        > .dxbl-tabs-tablist > ul {
            > li > .dxbl-tabs-item:not(.dxbl-tabs-tmpl) {
                > .dxbl-image {
                    --dxbl-image-margin-y: #{$dx-tabs-image-margin-y-sm};
                }
            }
        }
    }

    &.dxbl-lg {
        --dxbl-tabs-image-spacing: #{$dx-tabs-image-spacing-lg};
        --dxbl-tabs-tab-padding-x: #{$dx-tabs-tab-padding-x-lg};
        --dxbl-tabs-tab-padding-y: #{$dx-tabs-tab-padding-y-lg};
        --dxbl-tabs-font-size: #{$dx-tabs-font-size-lg};
        --dxbl-tabs-line-height: #{$dx-tabs-line-height-lg};
        --dxbl-tabs-button-spacing: #{$dx-tabs-btn-spacing-lg};
        --dxbl-tabs-close-btn-width: #{$dx-tabs-close-btn-width-lg};
        --dxbl-tabs-close-btn-height: #{$dx-tabs-close-btn-height-lg};

        /* Content */
        --dxbl-tabs-content-font-size: #{$dx-tabs-content-font-size-lg};
        --dxbl-tabs-content-line-height: #{$dx-tabs-content-line-height-lg};

        > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > ul,
        > ul {
            > li > .dxbl-tabs-item:not(.dxbl-tabs-tmpl) {
                > .dxbl-image {
                    --dxbl-image-margin-y: #{$dx-tabs-image-margin-y-lg};
                }
            }
        }
    }
}
