@import "mixins/common";
@import "mixins/button";
@import "mixins/text-edit";


.dxbl-btn-dropdown-popup {
    --dxbl-btn-dropdown-padding-x: #{$dx-btn-dropdown-padding-x};
    --dxbl-btn-dropdown-padding-y: #{$dx-btn-dropdown-padding-y};
    --dxbl-btn-dropdown-modal-padding-x: #{$dx-btn-dropdown-modal-padding-x};
    --dxbl-btn-dropdown-modal-padding-y: #{$dx-btn-dropdown-modal-padding-y};
    --dxbl-btn-dropdown-min-width: #{$dx-btn-dropdown-min-width};
    --dxbl-btn-dropdown-btn-bg: #{$dx-btn-dropdown-btn-bg};
    --dxbl-btn-dropdown-btn-color: #{$dx-btn-dropdown-btn-color};
    --dxbl-btn-dropdown-btn-border-color: #{$dx-btn-dropdown-btn-border-color};
    --dxbl-btn-dropdown-btn-border-style: #{$dx-btn-dropdown-btn-border-style};
    --dxbl-btn-dropdown-btn-border-width: #{$dx-btn-dropdown-btn-border-width};
    --dxbl-btn-dropdown-btn-hover-bg: #{$dx-btn-dropdown-btn-hover-bg};
    --dxbl-btn-dropdown-btn-hover-color: #{$dx-btn-dropdown-btn-hover-color};
    --dxbl-btn-dropdown-btn-active-bg: #{$dx-btn-dropdown-btn-active-bg};
    --dxbl-btn-dropdown-btn-active-color: #{$dx-btn-dropdown-btn-active-color};
    --dxbl-btn-dropdown-btn-active-hover-bg: #{$dx-btn-dropdown-btn-active-hover-bg};
    --dxbl-btn-dropdown-btn-active-hover-color: #{$dx-btn-dropdown-btn-active-hover-color};
    --dxbl-btn-dropdown-btn-disabled-bg: #{$dx-btn-dropdown-btn-disabled-bg};
    --dxbl-btn-dropdown-btn-disabled-color: #{$dx-btn-dropdown-btn-disabled-color};
    --dxbl-btn-dropdown-btn-padding-x: #{$dx-btn-dropdown-btn-padding-x};
    --dxbl-btn-dropdown-btn-padding-y: #{$dx-btn-dropdown-btn-padding-y};
    --dxbl-btn-dropdown-btn-focus-outline-color: #{$dx-btn-dropdown-btn-focus-outline-color};
    --dxbl-btn-dropdown-btn-focus-outline-width: #{$dx-btn-dropdown-btn-focus-outline-width};

    .dxbl-btn-dropdown-item-separator {
        height: 0;
        margin: 0;
        overflow: hidden;
        border-top: var(--dxbl-dropdown-border-width) var(--dxbl-dropdown-border-style) var(--dxbl-dropdown-border-color);
    }

    .dxbl-btn-dropdown-list-item {
        display: flex;

        .dxbl-dropdown:not([data-dx-focus-hidden]) &,
        .dxbl-modal:not([data-dx-focus-hidden]) & {
            & > .dxbl-btn-dropdown-item {
                & > .dxbl-btn {
                    &:focus-visible {
                        --dxbl-btn-focus-outline-color: var(--dxbl-btn-dropdown-btn-focus-outline-color);
                        --dxbl-btn-focus-outline-size: var(--dxbl-btn-dropdown-btn-focus-outline-width);
                        --dxbl-btn-focus-outline-offset: calc(var(--dxbl-btn-dropdown-btn-focus-outline-width) * -1);
                    }
                }
            }
        }

        .dxbl-btn-dropdown-item {
            display: flex;
            width: 100%;

            & > .dxbl-btn:first-child {
                flex-grow: 1;
                justify-content: flex-start;
            }

            & > .dxbl-btn {
                position: relative;
                text-decoration: none;
                text-align: inherit;
                white-space: nowrap;
                display: flex;
                padding: var(--dxbl-btn-dropdown-btn-padding-y) var(--dxbl-btn-dropdown-btn-padding-x) var(--dxbl-btn-dropdown-btn-padding-y) calc(var(--dxbl-btn-dropdown-btn-padding-x) * #{$dx-btn-padding-x-ratio}); // stylelint-disable-line max-line-length

                --dxbl-btn-bg: var(--dxbl-btn-dropdown-btn-bg);
                --dxbl-btn-color: var(--dxbl-btn-dropdown-btn-color);
                --dxbl-btn-border-color: var(--dxbl-btn-dropdown-btn-border-color);
                --dxbl-btn-border-style: var(--dxbl-btn-dropdown-btn-border-style);
                --dxbl-btn-border-width: var(--dxbl-btn-dropdown-btn-border-width);
                --dxbl-btn-hover-bg: var(--dxbl-btn-dropdown-btn-hover-bg);
                --dxbl-btn-hover-background: transparent;
                --dxbl-btn-hover-color: var(--dxbl-btn-dropdown-btn-hover-color);
                --dxbl-btn-hover-border-color: var(--dxbl-btn-dropdown-btn-border-color);
                --dxbl-btn-active-bg: var(--dxbl-btn-dropdown-btn-active-bg);
                --dxbl-btn-active-background: transparent;
                --dxbl-btn-active-color: var(--dxbl-btn-dropdown-btn-active-color);
                --dxbl-btn-active-border-color: var(--dxbl-btn-dropdown-btn-border-color);
                --dxbl-btn-active-hover-bg: var(--dxbl-btn-dropdown-btn-active-hover-bg);
                --dxbl-btn-active-hover-color: var(--dxbl-btn-dropdown-btn-active-hover-color);
                --dxbl-btn-disabled-bg: var(--dxbl-btn-dropdown-btn-disabled-bg);
                --dxbl-btn-disabled-background: transparent;
                --dxbl-btn-disabled-color: var(--dxbl-btn-dropdown-btn-disabled-color);
                --dxbl-btn-disabled-border-color: var(--dxbl-btn-dropdown-btn-border-color);
                --dxbl-btn-focus-shadow-color: transparent;
                --dxbl-btn-box-shadow: none;

                @include embedded-image-color(
                        "--dxbl-btn-image-color",
                        "--dxbl-btn-disabled-image-color",
                        "--dxbl-btn-hover-image-color",
                        "--dxbl-btn-active-image-color",
                        "--dxbl-btn-active-hover-image-color"
                );
                @include embedded-text-opacity();

                @include button-focus-state() {
                    z-index: 1;

                    @include button-inner-focus-style(var(--dxbl-btn-border-radius));
                }

                @include hover-state() {
                    @include state-hover-style("--dxbl-btn-hover-bg", "--dxbl-btn-hover-color", "--dxbl-btn-border-width");
                }

                @include active-state() {
                    @include state-selected-style("--dxbl-btn-active-bg", "--dxbl-btn-active-color", "--dxbl-btn-border-width");

                    @include hover-state() {
                        @include state-selected-hover-style("--dxbl-btn-active-hover-bg", "--dxbl-btn-active-hover-color", "--dxbl-btn-border-width");
                    }
                }

                & > span:not(.dxbl-image):not(:last-child) {
                    margin-right: var(--dxbl-btn-dropdown-btn-padding-x);
                }

                & > .dxbl-btn-dropdown-toggle {
                    margin-left: auto;
                }
            }
        }
    }

    &.dxbl-dropdown-dialog {
        .dxbl-btn-dropdown-list-item {
            .dxbl-btn-dropdown-item {
                &.dxbl-btn {
                    --dxbl-btn-border-radius: 0;
                }
            }
        }

        .dxbl-dropdown-body {
            --dxbl-dropdown-body-padding-x: 0;
            --dxbl-dropdown-body-padding-y: 0;
            --dxbl-dropdown-min-width: 0;

            & > .dxbl-btn-dropdown-list {
                --dxbl-dropdown-body-padding-x: var(--dxbl-btn-dropdown-padding-x);
                --dxbl-dropdown-body-padding-y: var(--dxbl-btn-dropdown-padding-y);
                --dxbl-dropdown-min-width: var(--dxbl-btn-dropdown-min-width);
            }
        }
    }

    .dxbl-modal-body {
        --dxbl-popup-body-padding-x: var(--dxbl-btn-dropdown-modal-padding-x);
        --dxbl-popup-body-padding-y: var(--dxbl-btn-dropdown-modal-padding-y);
    }

    & > .dxbl-modal-content > .dxbl-modal-body,
    & > .dxbl-dropdown-body {
        & > .dxbl-btn-dropdown-list {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
        }
    }

    &.dxbl-popup.dxbl-btn-dropdown-modal {
        max-height: 75%;

        &.dxbl-sidepanel-position-bottom {
            height: 100%;
            position: fixed;
            top: 50%;
            max-height: 50%;

            & > .dxbl-modal-content {
                --dxbl-bottom-sheet-border-radius: #{$dx-dropdown-bottom-sheet-border-radius};

                height: 100%;
                border-radius: var(--dxbl-bottom-sheet-border-radius) var(--dxbl-bottom-sheet-border-radius) 0 0;

                .dxbl-modal-header {
                    border-radius: inherit;
                }
            }
        }
    }

    .dxbl-sm & {
        --dxbl-btn-dropdown-padding-x: #{$dx-btn-dropdown-padding-x-sm};
        --dxbl-btn-dropdown-padding-y: #{$dx-btn-dropdown-padding-y-sm};
        --dxbl-btn-dropdown-btn-padding-x: #{$dx-btn-dropdown-btn-padding-x-sm};
        --dxbl-btn-dropdown-btn-padding-y: #{$dx-btn-dropdown-btn-padding-y-sm};
        --dxbl-btn-dropdown-min-width: #{$dx-btn-dropdown-min-width-sm};
    }

    .dxbl-lg & {
        --dxbl-btn-dropdown-padding-x: #{$dx-btn-dropdown-padding-x-lg};
        --dxbl-btn-dropdown-padding-y: #{$dx-btn-dropdown-padding-y-lg};
        --dxbl-btn-dropdown-btn-padding-x: #{$dx-btn-dropdown-btn-padding-x-lg};
        --dxbl-btn-dropdown-btn-padding-y: #{$dx-btn-dropdown-btn-padding-y-lg};
        --dxbl-btn-dropdown-min-width: #{$dx-btn-dropdown-min-width-lg};
    }
}
