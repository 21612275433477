@import "common";

@mixin color-palette-color-tile-style() {
    > div {
        @include color-palette-color-tile-style-base();

        cursor: pointer;

        &:hover {
            border: var(--dxbl-color-palette-color-tile-hover-border);
            outline-width: var(--dxbl-color-palette-color-tile-outline-size-state);
        }
    }

    &.dxbl-color-palette-selected-tile > div {
        outline-color: var(--dxbl-color-palette-color-tile-outline-selected-color);
        outline-width: var(--dxbl-color-palette-color-tile-outline-size-state);
        border: var(--dxbl-color-palette-color-tile-hover-border);
    }
}

@mixin color-palette-color-tile-style-base() {
    width: var(--dxbl-color-palette-color-tile-size);
    height: var(--dxbl-color-palette-color-tile-size);
    outline: var(--dxbl-color-palette-color-tile-outline);
    border-radius: var(--dxbl-color-palette-color-tile-border-radius);
}
