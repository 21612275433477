dxbl-map {
    --dxbl-map-marker-tooltip-color: #{$dx-map-marker-tooltip-color};

    display: flex;
    position: relative;

    .dxbl-widget-container > div {
        height: 100%;
    }

    // stylelint-disable selector-class-pattern
    .dx-map-marker-tooltip {
        margin: 10px;
        color: var(--dxbl-map-marker-tooltip-color);
    }

    // stylelint-enable selector-class-pattern
}
