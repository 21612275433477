.dxbl-pivotgrid {
    --dxbl-pivot-color: #{$dx-pivot-grid-color};
    --dxbl-pivot-bg: #{$dx-pivot-grid-bg};
    --dxbl-pivot-border-color: #{$dx-pivot-grid-border-color};
    --dxbl-pivot-border-style: #{$dx-pivot-grid-border-style};
    --dxbl-pivot-border-width: #{$dx-pivot-grid-border-width};
    --dxbl-pivot-border-radius: #{$dx-pivot-grid-border-radius};
    --dxbl-pivot-font-family: #{$dx-pivot-grid-font-family};
    --dxbl-pivot-font-size: #{$dx-pivot-grid-font-size};
    --dxbl-pivot-line-height: #{$dx-pivot-grid-line-height};
    --dxbl-pivot-min-width: #{$dx-pivot-grid-min-width};
    --dxbl-pivot-field-bg: #{$dx-pivot-grid-field-bg};
    --dxbl-pivot-field-color: #{$dx-pivot-grid-field-color};
    --dxbl-pivot-field-font-size: #{$dx-pivot-grid-field-font-size};
    --dxbl-pivot-field-font-weight: #{$dx-pivot-grid-field-font-weight};
    --dxbl-pivot-field-line-height: #{$dx-pivot-grid-field-line-height};
    --dxbl-pivot-header-bg: #{$dx-pivot-grid-header-bg};
    --dxbl-pivot-header-color: #{$dx-pivot-grid-header-color};
    --dxbl-pivot-header-font-size: #{$dx-pivot-grid-header-font-size};
    --dxbl-pivot-header-font-weight: #{$dx-pivot-grid-header-font-weight};
    --dxbl-pivot-header-line-height: #{$dx-pivot-grid-header-line-height};
    --dxbl-pivot-total-bg: #{$dx-pivot-grid-total-bg};
    --dxbl-pivot-total-color: #{$dx-pivot-grid-total-color};
    --dxbl-pivot-total-font-size: #{$dx-pivot-grid-total-font-size};
    --dxbl-pivot-total-font-weight: #{$dx-pivot-grid-total-font-weight};
    --dxbl-pivot-total-line-height: #{$dx-pivot-grid-total-line-height};

    &.dxbl-sm {
        --dxbl-pivot-border-radius: #{$dx-pivot-grid-border-radius-sm};
        --dxbl-pivot-font-size: #{$dx-pivot-grid-font-size-sm};
        --dxbl-pivot-line-height: #{$dx-pivot-grid-line-height-sm};
        --dxbl-pivot-field-font-size: #{$dx-pivot-grid-field-font-size-sm};
        --dxbl-pivot-field-line-height: #{$dx-pivot-grid-field-line-height-sm};
        --dxbl-pivot-header-font-size: #{$dx-pivot-grid-header-font-size-sm};
        --dxbl-pivot-header-line-height: #{$dx-pivot-grid-header-line-height-sm};
        --dxbl-pivot-total-font-size: #{$dx-pivot-grid-header-font-size-sm};
        --dxbl-pivot-total-line-height: #{$dx-pivot-grid-header-line-height-sm};
    }

    &.dxbl-lg {
        --dxbl-pivot-border-radius: #{$dx-pivot-grid-border-radius-lg};
        --dxbl-pivot-font-size: #{$dx-pivot-grid-font-size-lg};
        --dxbl-pivot-line-height: #{$dx-pivot-grid-line-height-lg};
        --dxbl-pivot-field-font-size: #{$dx-pivot-grid-field-font-size-lg};
        --dxbl-pivot-field-line-height: #{$dx-pivot-grid-field-line-height-lg};
        --dxbl-pivot-header-font-size: #{$dx-pivot-grid-header-font-size-lg};
        --dxbl-pivot-header-line-height: #{$dx-pivot-grid-header-line-height-lg};
        --dxbl-pivot-total-font-size: #{$dx-pivot-grid-header-font-size-lg};
        --dxbl-pivot-total-line-height: #{$dx-pivot-grid-header-line-height-lg};
    }

    background-color: var(--dxbl-pivot-bg);
    border-color: var(--dxbl-pivot-border-color);
    border-style: var(--dxbl-pivot-border-style);
    border-width: 0;
    border-top-width: var(--dxbl-pivot-border-width);
    border-left-width: var(--dxbl-pivot-border-width);
    border-radius: var(--dxbl-pivot-border-radius);
    color: var(--dxbl-pivot-color);
    display: flex;
    flex-direction: column;
    font-family: var(--dxbl-pivot-font-family);
    font-size: var(--dxbl-pivot-font-size);
    line-height: var(--dxbl-pivot-line-height);
    min-width: var(--dxbl-pivot-min-width);
    overflow: auto;
    text-align: left;
    width: 100%;

    & > table {
        border-collapse: separate;
        border-color: var(--dxbl-pivot-border-color);
        border-style: var(--dxbl-pivot-border-style);
        border-width: 0;
        border-spacing: 0;
        height: fit-content;
        width: 100%;

        & > thead,
        & > tbody,
        & > tfoot {
            border-color: inherit;
            border-style: inherit;
            border-width: 0;

            & > tr {
                border-color: inherit;
                border-style: inherit;
                border-width: 0;

                & > th {
                    &.dxbl-pivot-header,
                    &.dxbl-pivot-field-cell {
                        .dxbl-btn {
                            display: inline-block;
                            color: inherit;

                            & > span {
                                vertical-align: middle;
                            }
                        }
                    }

                    &.dxbl-pivot-header {
                        background-color: var(--dxbl-pivot-header-bg);
                        color: var(--dxbl-pivot-header-color);
                        font-size: var(--dxbl-pivot-header-font-size);
                        font-weight: var(--dxbl-pivot-header-font-weight);
                        line-height: var(--dxbl-pivot-header-line-height);

                        .dxbl-btn {
                            height: 100%;
                            pointer-events: auto;
                            text-align: left;
                            text-decoration: none;
                            width: 100%;
                        }
                    }

                    &.dxbl-pivot-header-expandable {
                        padding: 0;
                    }

                    &.dxbl-pivot-field-cell {
                        .dxbl-btn {
                            background-color: var(--dxbl-pivot-field-bg);
                            border-color: inherit;
                            border-style: inherit;
                            border-width: var(--dxbl-pivot-border-width);
                            border-radius: 0;
                            color: var(--dxbl-pivot-field-color);
                            font-size: var(--dxbl-pivot-field-font-size);
                            font-weight: var(--dxbl-pivot-field-font-weight);
                            line-height: var(--dxbl-pivot-field-line-height);

                            span:not(.dxbl-image) {
                                text-decoration: none;
                            }

                            &:not(.dxbl-disabled):not(:disabled):hover {
                                border-color: inherit;
                                border-style: inherit;
                                border-width: var(--dxbl-pivot-border-width);
                                border-radius: 0;
                            }

                            &.dxbl-pivot-field-cell-inactive {
                                cursor: default;
                                color: inherit;
                                text-decoration: none;
                            }
                        }
                    }
                }

                & > td {
                    &.dxbl-pivot-data {
                        text-align: right;
                    }
                }

                & > td,
                & > th {
                    font-family: var(--dxbl-pivot-font-family);
                    font-size: var(--dxbl-pivot-font-size);
                    line-height: var(--dxbl-pivot-line-height);
                    border-color: inherit;
                    border-style: inherit;
                    border-width: 0;
                    border-bottom-width: var(--dxbl-pivot-border-width);
                    border-right-width: var(--dxbl-pivot-border-width);
                    font-weight: inherit;
                    height: 100%;
                    padding: 0.5em 1em;
                    position: relative;
                    vertical-align: top;
                    white-space: nowrap;

                    &.dxbl-pivot-total {
                        background-color: var(--dxbl-pivot-total-bg);
                        color: var(--dxbl-pivot-total-color);
                        font-size: var(--dxbl-pivot-total-font-size);
                        font-weight: var(--dxbl-pivot-total-font-weight);
                        line-height: var(--dxbl-pivot-total-line-height);
                    }
                }
            }
        }

        & > thead > tr > th {
            &.dxbl-pivot-area-data {
                border-width: 0;
            }

            &.dxbl-pivot-area-row,
            &.dxbl-pivot-area-data {
                padding-left: 0.5em;
            }

            &.dxbl-pivot-area-column {
                padding-left: 0;
            }

            &.dxbl-pivot-area-row {
                vertical-align: bottom;
            }
        }

        & > tfoot > tr > td {
            padding-left: 0.5em;
        }
    }
}
