.dxbl-ai-popup {
    --dxbl-ai-popup-warning-back: #{$dx-gen-amber-100};

    min-width: 34rem;
    min-height: 20rem;

    .dxbl-ai-popup-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 100%;

        .dxbl-ai-popup-error-content {
            height: 100%;
            min-height: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
        }

        .dxbl-ai-popup-memo {
            height: 100%;
            min-height: 8rem;
        }

        .dxbl-ai-popup-warning {
            background-color: var(--dxbl-ai-popup-warning-back);
            padding: 8px;
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 8px;

            .dxbl-ai-popup-warning-bottom {
                display: flex;
                gap: 8px;
                align-items: center;
                margin-top: auto;

                .dxbl-ai-popup-warning-proceed-btn {
                    gap: 8px;
                    display: flex;
                }
            }
        }
    }

    .dxbl-ai-popup-footer {
        width: 100%;
        display: flex;
        gap: 6px;

        .dxbl-ai-popup-btn-end {
            margin-left: auto;
        }
    }
}
