dxbl-range-selector {
    --dxbl-range-selector-title-font-color: #{$dx-title-font-color};
    --dxbl-range-selector-subtitle-font-color: #{$dx-title-font-color};
    --dxbl-range-selector-scale-tick-color: #{$dx-range-selector-scale-tick-color};
    --dxbl-range-selector-selected-range-color: #{$dx-range-selector-selected-range-color};
    --dxbl-range-selector-slider-marker-color: #{$dx-range-selector-slider-marker-color};
    --dxbl-range-selector-slider-marker-font-color: #{$dx-range-selector-slider-marker-font-color};
    --dxbl-range-selector-slider-handle-color: #{$dx-range-selector-slider-handle-color};
    --dxbl-range-selector-shutter-color: #{$dx-range-selector-shutter-color};
    --dxbl-range-selector-container-background-color: #{$dx-range-selector-container-background-color};
    --dxbl-range-selector-scale-label-font-color: #{$dx-range-selector-scale-label-font-color};

    display: flex;
    position: relative;

    .dxbl-widget-container {
        width: 100%;
        height: 100%;
    }
}
